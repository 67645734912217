import React, { Component } from 'react';
import { Crud_Catalogos } from '../Crud_Catalogos';
import './excel.css';
import { ExportCSV } from './ExportCSV.js'
var currentUser= JSON.parse(sessionStorage.getItem("currentUser"));
class Exportar extends Component
{
  constructor(props) {
    super(props);
    this.state = {
                catalogo:this.props.catalogo                ||"",
                  filtro:this.props.filtro                  ||"",
                informes:this.props.informes                ||"",
        perfilesInformes:this.props.perfilesInformes        ||"",
									  data:[],
                actuales:this.props.actuales                ||[],

  //Variables
                  accion:this.props.accion,
                fileName:this.props.fileName                ||"",
    };
    //this.cita=0;
    this.data= [];
    //this.actuales=[];
  }
  componentDidUpdate(){

  }
  componentDidMount(){
    this.setState({ fileName: this.props.catalogo });
    switch (this.props.catalogo) {
      //case "unidadEnsamble":
      //  { this.cargaPerfilesInformes(currentUser[0].perfil.id) };
      //  { this.cargaHabilitados("navieraMovimientos") };//this.props.catalogo) }; //Todos
      //  break;
      case "navieraMovimientos":
        break;
      case "terrestreMovimientos":
        this.cargaPerfilesInformes(currentUser[0].perfil.id);
        this.cargaHabilitados("terrestreMovimientos");//this.props.catalogo) }; //Todos
        this.cargaCatalogo(this.props.catalogo);
        break;
      default:
        //{ this.cargaCatalogo(this.props.catalogo) };
        break;
    }
  }

  componentWillMount(){

  }

  componentDidUpdate=()=>{
    if( this.props.data && this.props.data !== this.data ){
      this.data= this.props.data;
      var dataTemp= [];
      /*
      this.data.map((item, index)=>{
        dataTemp.push(item);
        Object.keys(dataTemp).forEach(
          key => {

          });
        
      }) 
      */ 

      /**
       * Creo que valida
       */
      //var dataTemp= this.stete.actuales;
      //var dataTemp= this.data;
      
      this.data.map((filas, index) =>
        Object.keys(filas).forEach(
          key => {
            if(!dataTemp[index]){ dataTemp[index]={} }
            if(key !== "movimientoGps" && key !== "esFull" ){ dataTemp[index][key]= []; }
            if(filas[key] !== null){

              if ( typeof filas[key] === 'object') {
                //console.log(filas[key]+"=>> "+ key+"("+typeof filas[key]+")")
                switch (key) {
                  case "persona":
                    dataTemp[index][key]= "" + filas[key].nombre + " " + filas[key].aPaterno + " " + filas[key].aMaterno;
                    break;
                  case "entregas":
                      filas[key].map((e, indice)=>{
                        dataTemp[index]["idEntrega-"+(indice+1)]= e.idEntrega;
                        dataTemp[index]["Destino-"+(indice+1)]= e.destino;
                        dataTemp[index]["Dirección-"+(indice+1)]= e.direccion;
                        dataTemp[index]["Dirección-"+(indice+1)]= e.direccion;
                        dataTemp[index]["Fecha arribo-"+(indice+1)]= e.fechaArribo;
                        dataTemp[index]["Fecha Salida-"+(indice+1)]= e.fechaSalida;
                        return e;
                      });
                    break;
                  case "movimientoGps":
                    if(filas[key].length > 0){
                      filas[key].map((gps, indice)=>{
                                                      if(gps.gps.id !== 1){
                                                        dataTemp[index]["gps-"+(indice+1)]= gps.gps.nombre
                                                      }else{
                                                        if( filas['esFull']  &&  filas['esFull'] !== "" ){
                                                          for( let item of this.data ){
                                                            if( item.id === parseInt(filas.esFull, 10) ){
                                                              item[key].map((gps, indice)=> dataTemp[index]["gps-"+(indice+1)]= gps.gps.nombre);
                                                            }
                                                          }
                                                        }
                                                      } return gps;
                                                    });
                    }else{
                      if( filas['esFull']  &&  filas['esFull'] !== "" ){
                        for( let item of this.data ){
                          if( item.id === parseInt(filas.esFull, 10) ){
                            item[key].map((gps, indice)=> dataTemp[index]["gps-"+(indice+1)]= gps.gps.nombre);
                          }
                        }
                      }
                    }
                    break;
                  default:
                      if ((filas[key].nombre!=="")&&(filas[key].nombre!==undefined)) {
                        dataTemp[index][key]=filas[key].nombre;
                      }else{
                        if ((filas[key].id!=="")&&(filas[key].id!==undefined)) {
                          dataTemp[index][key]=filas[key].id;
                        }else{
                          dataTemp[index][key]="Vari(@)s ";
                        }
                      }break;
                }
              }else{
                switch (key) {
                  case "full":
                    break;
                  case "esFull":
                    break;
                
                  default: dataTemp[index][key]=filas[key];
                    break;
                }
              }
            }else{
              if( key !== "esFull" ){
                dataTemp[index][key]="";
              }              
            }            
          })
      );
      this.setState({ data: dataTemp});
    }
  }

  cargaPerfilesInformes(filtro) {
  //Crud_Catalogos("funcionalidades",         "", "get", "","","" , "", "", [], "", "", "/bloque/movimiento" ).then((returnVal) => {
    Crud_Catalogos("perfilesInformes", "", "get", "",[],"" , "", "", [], "", "", "/perfil/"+filtro).then((regresaValores) => {
    this.setState({ perfilesInformes:regresaValores });
    this.cargaInformes(this.state.perfilesInformes[0].idInformes);
    }).catch(err => {
      if( err.response ){
         console.log(err);
      }else{
        console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");
      }
    });
  }
  cargaInformes(id) {
  //Crud_Catalogos("funcionalidades",         "", "get", "","","" , "", "", [], "", "", "/bloque/movimiento" ).then((returnVal) => {
    Crud_Catalogos("informes", "", "get", "",[],"" , "", "", [], "", "", "/"+id).then((regresaValores) => {
    this.setState({ informes:regresaValores });
    this.cargaInformesFuncionalidades(this.state.informes.id);
    }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
  }
  cargaInformesFuncionalidades(filtro) {
  //Crud_Catalogos("funcionalidades",         "", "get", "","","" , "", "", [], "", "", "/bloque/movimiento" ).then((returnVal) => {
    //if (filtro > 0){
      Crud_Catalogos("informesFuncionalidades", "", "get", "",[],"" , "", "", [], "", "", "/informe/"+filtro).then((regresaValores) => {
      this.setState({ informesFuncionalidades:regresaValores });
      //console.log("63 this.state.informesFuncionalidades");
      //console.log(this.state.informesFuncionalidades);
      //tmpI = regresaValores;
      this.cargaCatalogo(this.props.catalogo);
      }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo+" verifique el Nombre del Catalogo");}  });
    //}
  }

  //Inicio Carga Habilitados
  cargaHabilitados(filtro) {
    //if (filtro >0 ){
        Crud_Catalogos("funcionalidades", "", "get", "", "" , "" , "", "", [], "", "", "/bloque/"+filtro).then((regresaValores) => {
          this.setState({ FHabilitados:regresaValores });
        }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+this.state.catalogo +" verifique el Nombre del Catalogo");}  });
    //}
  }
  //Fin Carga Habilitados

  //Inicio Carga Catalogo
  cargaCatalogo(Nombre) {
    var nuevoJSON = [];
    var f = 0;
    if (this.state.actuales.length>0){
    //if (this.state.data.length>0){
      //nuevoJSON=this.state.actuales;
      nuevoJSON=this.state.data;
      nuevoJSON.map(filas => {
        var col=0;
        for (let j in filas){//for (let j in Jsons){
          col=col+1;
          if ( (filas[j]===null)||(filas[j]==="") ) {
          }else{
            //if (seExporta){//Inicio Se Muestra
                if ( typeof filas[j] === 'object') {
                  switch (j) {
                    case "persona":
                        filas[j]= "" + filas[j].nombre + " " + filas[j].aPaterno + " " + filas[j].aMaterno;
                      break;
                    default:
                      if ((filas[j].nombre!=="")&&(filas[j].nombre!==undefined)) {
                        filas[j]=filas[j].nombre;
                      }else{
                        if ((filas[j].id!=="")&&(filas[j].id!==undefined)) {
                          filas[j]=filas[j].id;
                        }else{
                          filas[j]="Vari(@)s "+j;
                        }
                      }
                  }
                }else{
                  switch (j) {
                    case "password":
                        filas[j]="***************";
                      break;
                    case "persona":
                        filas[j]="***************";
                      break;
                    default:
                  }
                }

            //}//Fin se Muestra
          }
        } f=f+1;
        return filas;
      } );
    }else{
      //console.log("Cargando Datos con Filtro del Catálogo... ");
      Crud_Catalogos(Nombre+this.state.filtro, ""           , "get", "", [],"" , "",     "", []).then( (returnVal) => {
      nuevoJSON=returnVal;
      //nuevoJSON=this.state.data;
      nuevoJSON.map(filas => {
        var col=0;
        for (let j in filas){//for (let j in Jsons){
          col=col+1; //console.log("filas: ");
          if ( (filas[j]===null)||(filas[j]==="") ) {
          }else{
            //if (seExporta){//Inicio Se Muestra
                if ( typeof filas[j] === 'object') {
                  switch (j) {
                    case "persona":
                        filas[j]= "" + filas[j].nombre + " " + filas[j].aPaterno + " " + filas[j].aMaterno;
                      break;
                    default:
                      if ((filas[j].nombre!=="")&&(filas[j].nombre!==undefined)) {
                        filas[j]=filas[j].nombre;
                      }else{
                        if ((filas[j].id!=="")&&(filas[j].id!==undefined)) {
                          filas[j]=filas[j].id;
                        }else{
                          filas[j]="Vari(@)s "+j;
                        }
                      }
                  }
                }else{
                  switch (j) {
                    case "password":
                        filas[j]="***************";
                      break;
                    case "persona":
                        filas[j]="***************";
                      break;
                    default:
                  }
                }

            //}//Fin se Muestra
          }
        } f=f+1;
        return filas;
      } );
      this.setState({data:nuevoJSON });
      }).catch(err =>{if( err.response ){ console.log(err);}else{console.log("Error al Cargar el Catalogo:"+Nombre+" verifique el Nombre del Catalogo");}  });

    }
  }
  //Fin Carga Catalogo

render() {
    return (
        <div><ExportCSV csvData={this.state.data} fileName={this.state.fileName} title={this.props.title? this.props.title:null} />{ }</div>
    );
  }
}

export default Exportar;
