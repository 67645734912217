import React, { Component } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";
import Select from "react-select";
import { Crud_Catalogos } from '../../herramientas/Crud_Catalogos';
var user= JSON.parse(sessionStorage.getItem("usuario"));
class NavieraServiceFailure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenModals: false,
      serviceFailure: { value: 0 , label: "Seleccione una opción" },
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      corredores: [],
      comentario: "",
      nota: "",
      crudbutonEnable: true, // Se desabilita cuando se hace click


    };
    this.enableSave = this.enableSave.bind(this);
    this.serviceFailureL = [];
    this.movServiceFailureObj= {
                                "idMovimiento": this.props.elementos.id,
                                "idServiceFailure": null,
                                "comentario": "",
                                "fecha": "0000-00-00 00:00:00",
                                "id_usuario": user.persona
                              };
  }
  hiddenmodal = () => { this.setState({ hiddenModals: !this.state.hiddenModals }); }
  getfuntion = (metodo="get", obj=[], id="", catalogo="cliente", stateVar= "clientes", hiddenModl="") =>{
    Crud_Catalogos(catalogo, "movimientos", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        switch (metodo) {
          case "get":
              if (stateVar === "corredores"){ this.corredores=[];
                returnVal.map((corredor, index)=>this.corredores.push({ value: corredor.id, label: corredor.nombre, data: corredor }) );
                this.setState({corredores: this.corredores});
                if(this.props.accion === 'nuevo'){this.setState({ idCorredor: { value: 0 , label: "Selecciona un corredor" } });}
              }
            break;
          case "post":
            break;
          case "put":
              break;
          default:
            break;
        }
      }).catch(err =>{
        if( err.response ){ console.log(err.response); }else{console.log("Error desconocido ...."); }
    });
  }
  componentWillMount =() =>{
    this.serviceFailureL.push({ value: 0 , label: "Seleccione una opción" });
    this.props.stor.serviceFailure_object.map((elemento, index) => {
      this.serviceFailureL.push({ value: elemento.id, label: elemento.nombre  });
      return elemento;
    });
  }
  componentDidMount = () => {    
    /////////////////////////////////////////////////////////////////////////////////77
    //console.log(user.persona);
    if (this.state.accion !== 'nuevo') {
    }else{
    }
  }
  enableSave = () => {
    if (this.state.serviceFailure.value > 0) {
      return false;
    } else { return true }
  }

  //************ Combos ***********************
  changeFailure = (event)             => { this.setState({serviceFailure: event}); this.movServiceFailureObj.idServiceFailure =  event.value; }
  changeNota = (event)                => {  if(event.target.value.length<=200){ this.setState({ nota:event.target.value }); this.movServiceFailureObj.comentario = event.target.value; } }
  crud_post() {
    this.props.getfuntion("post", this.movServiceFailureObj, "", "navieraMovimientos_service_failure", "serviceFailure");
    this.setState({ crudbutonEnable: false });
  };

  render() {
    return (
      <div>
        {this.state.accion === "modificar" ? <h1>{this.props.elementos.nombre}</h1> : null}
        <Grid.Row>
          <Grid.Col md={12} xl={12}>
            <div><strong> Corredor        </strong>{this.props.elementos.idCorredor?this.props.elementos.idCorredor.nombre:""}</div>
            <div><strong> Movimento id    </strong>{this.props.elementos.id?this.props.elementos.id :""}</div>
            <div><strong> Movimiento Tipo </strong>{this.props.elementos.idMovimientoTipo?this.props.elementos.idMovimientoTipo.nombre:""}</div>
            <div><strong> Booking         </strong>{this.props.elementos.booking?this.props.elementos.booking:""}</div>
            <div><strong> Po              </strong>{this.props.elementos.po?this.props.elementos.po:""}</div>
            <div><strong> Operacion       </strong>{this.props.elementos.idOperacionTipo?this.props.elementos.idOperacionTipo.nombre:""}</div>
            <div><strong> Contenedor      </strong>{this.props.elementos.contenedor?this.props.elementos.contenedor:""}</div>
            <div></div><Form.Group label="Failure"><Select value={this.state.serviceFailure} onChange={this.changeFailure} options={this.serviceFailureL} /></Form.Group>
            <Form.Group label={<Form.Label aside={this.state.nota.length+"/200"}>Nota</Form.Label>}> <Form.Textarea value={this.state.nota} name="nota" onChange={this.changeNota} placeholder="Comentario.." rows={6} /> </Form.Group>
          </Grid.Col>
        </Grid.Row>

        <div className="float-right">
          <span class="badge">
            <Button target="_blank" size="sm" RootComponent="a" color="primary" disabled={this.enableSave() || !this.state.crudbutonEnable} 
              onClick= { () => this.crud_post()} >
              <span class="badge"><Icon link={true} name="save" />Guardar</span>
            </Button>
          </span>
        </div>
      </div>
    );
  }
}
export default NavieraServiceFailure;
