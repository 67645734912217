import React, { Component } from "react"

import { Crud_Catalogos, Crud_error } from "../../herramientas/Crud_Catalogos";
import {
  Form,
  Icon,
  Button,
  List,
} from "tabler-react";
import { NotificationManager} from 'react-notifications';
import { DateRange } from "react-date-range";
import Select from "react-select";
import { es } from "date-fns/locale";

const Input = (props) => <Form.Input {...props} isHidden={false} />;
class OperadoresNavieros extends Component {
  
    constructor(props) {
      super(props);

      console.log(props)
     // const [isClearable, setIsClearable] = useState(true);
      this.state = {
        idMovimentoPatio:null,
        contacto:"",
        color:"",
        vigencia:"",
        licencia:"",
        remolque:"",
        tractorPlaca:"",
        inputValue:"",
        required:true,
        requiredOperador:true,
        idMovimento:null,
        patio:{},
        idMovimento:null,
        operadoresOpciones:[],
        operadorOpcion:null,
        remolquesOpciones:[],
        remolqueOpcion:null,
        oldMov:null,
        oldTipoRemolque:null,
        newTipoRemolque:null,
        oldOperadorNombre:null,
        oldLicencia:null,
        oldRemolquePlaca:null,
        oldVigencia:null,
        oldContacto:null,
        oldMarca:null,
        oldModelo:null,
        oldColor:null,
      }
      
      this.handleSubmit = this.handleSubmit.bind(this);
              
    }

    componentWillMount = () => {

      
      //this.state.remolque   = this.props.data.idRemolque.nombre != null ? this.props.data.idRemolque.nombre != null: this.state.remolque
      
      //console.log(this.props.data.idRemolque.nombre)

        Crud_Catalogos("movimientosPatio/idMovimiento/"+this.props.data.id,"","get","",[],"","","",[]).then((returnPatio)=>{     
          this.setState({oldMov:{...this.props.data}})                                            
          this.setState({idMovimentoPatio:returnPatio.id})
          this.setState({oldColor:returnPatio.tractorColor})
          this.setState({oldMarca:returnPatio.tractorMarca})
          this.setState({oldRemolquePlaca:returnPatio.remolquePlaca})
          this.setState({oldModelo:returnPatio.tractorModelo})
          this.setState({oldOperadorNombre:returnPatio.operadorNombre})
          this.setState({oldContacto:returnPatio.operadorContacto})
          this.setState({oldLicencia:returnPatio.operadorLicencia})
          this.setState({oldVigencia:returnPatio.operadorLicenciaVigencia})

          this.state.color = returnPatio.tractorColor   != null ? returnPatio.tractorColor   : this.state.color;
          this.state.remolquePlaca = returnPatio.remolquePlaca   != null ? returnPatio.remolquePlaca   : this.state.remolquePlaca;
          this.state.marca = returnPatio.tractorMarca   != null ? returnPatio.tractorMarca   : this.state.marca;
          this.state.modelo = returnPatio.tractorModelo   != null ? returnPatio.tractorModelo   : this.state.modelo;
          this.state.tractorPlaca = returnPatio.tractorPlaca   != null ? returnPatio.tractorPlaca   : this.state.tractorPlaca;
          this.state.inputValue = returnPatio.operadorNombre   != null ? returnPatio.operadorNombre   : this.state.inputValue;
          this.state.contacto   = returnPatio.operadorContacto != null ? returnPatio.operadorContacto : this.state.contacto;
          this.state.licencia   = returnPatio.operadorLicencia != null ? returnPatio.operadorLicencia : this.state.licencia;
          this.state.vigencia   = returnPatio.operadorLicenciaVigencia != null ? returnPatio.operadorLicenciaVigencia : this.state.vigencia;   
          Crud_Catalogos("operadores/transportista/"+this.props.data.idTransportista.id ,"","get","",[],"","","",[]).then((returnVal)=>{                                                
              let operadoresOpcionesTemp = [];
              Object.keys(returnVal).forEach((key) => operadoresOpcionesTemp.push({ value: returnVal[key], label: returnVal[key].nombre + " " + returnVal[key].paterno + " " + returnVal[key].materno } ));
              this.setState({operadoresOpciones:operadoresOpcionesTemp})
              
                Crud_Catalogos("remolques/plataforma/"+this.props.data.idPlataforma.id ,"","get","",[],"","","",[]).then((returnVal)=>{                                                
                  let remolquesOpcionesTemp = [];
                 this.setState({patio:returnPatio});
                  Object.keys(returnVal.remolquesTipos).forEach((key) => remolquesOpcionesTemp.push({ value: returnVal.remolquesTipos[key].idRemolque, label: returnVal.remolquesTipos[key].nombre} )); 
                  this.setState({remolquesOpciones:remolquesOpcionesTemp})
                  let opcionDefault =  remolquesOpcionesTemp.filter((ele)=> {
                    this.setState({required:false});
                    if(ele.value == returnPatio.idRemolque?.idRemolque){
                      this.setState({oldTipoRemolque:ele.label})
                      this.setState({newTipoRemolque:ele.label})
                      this.setState({remolque :  ele});
                      return true;
                    }
                    
                  } )
                  
      
                }).catch(err =>{ console.log(err);});
            }).catch(err =>{ console.log(err);});
          
          }).catch(err =>{ console.log(err);});

         

         
    }


     myFormato(date) {
      function pad(number) {
        if (number < 10) {
          return "0" + number;
        }
        return number;
      }
      return (
        date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate()) +
        " " +
        pad(date.getHours()) +
        ":" +
        pad(date.getMinutes()) +
        ":" +
        pad(date.getSeconds())
      );
    }


    handleDates = (e)=>{
      this.setState({operadorOpcion : {value : {licenciaVigencia: e}}})
    }

    onInputChange = (inputValue, { action }) => {

      if (action === "input-change") {
        this.setState({operadorOpcion:null})
        this.setState({inputValue:inputValue});
      
      }
    };

    handleChange(e){
      this.setState({inputValue:e ? e.label : ""});

      if(this.state.operadorOpcion != null){
        this.setState({contacto:e.value.medioComunicacion})
        this.setState({licencia:e.value.licenciaTipo})
        this.setState({vigencia:e.value.licenciaVigencia})
      }
      
      //newOperador.push({value:e.value,label:e.label}) 
      this.setState({operadorOpcion:e}); 
      e?.value != "" ?  this.setState({requiredOperador:false}): this.setState({requiredOperador:true});  
    }


    detectedChange = (oldMov,newMov)=>{


      let camposmodificados = "";
      let bitacora = 17;

     
          if (
            this.state.oldOperadorNombre !==
            newMov.operadorNombre
          ) {
            this.state.oldOperadorNombre  != null? bitacora = 19 : bitacora = 17;  
            if (camposmodificados.length > 0) {
              camposmodificados = camposmodificados + ", ";
            }
            camposmodificados = camposmodificados + "Nombre Operador:( ";
            camposmodificados =
              camposmodificados +
              this.state.oldOperadorNombre  +
              " a " +
              newMov.operadorNombre +
              ")";
          }


          if (
            this.state.oldContacto !==
            newMov.operadorContacto
          ) {
            this.state.oldContacto != null ? bitacora = 19 : bitacora = 17;  
            if (camposmodificados.length > 0) {
              camposmodificados = camposmodificados + ", ";
            }
            camposmodificados = camposmodificados + " Contacto: ( ";
            camposmodificados =
              camposmodificados +
              this.state.oldContacto +
              " a " +
              newMov.operadorContacto +
              ")";
          }


          if (
            this.state.oldLicencia !==
            newMov.operadorLicencia
          ) {
            this.state.oldLicencia != null ? bitacora = 19 : bitacora = 17;  
            if (camposmodificados.length > 0) {
              camposmodificados = camposmodificados + ",  ";
            }
            camposmodificados = camposmodificados + "Licencia: ( ";
            camposmodificados =
              camposmodificados +
              this.state.oldLicencia +
              " a " +
              newMov.operadorLicencia +
              ")";
          }


          if (
            this.state.oldVigencia !==
            newMov.operadorLicenciaVigencia
          ) {
            this.state.oldVigencia != null ? bitacora = 19 : bitacora = 17;  
            if (camposmodificados.length > 0) {
              camposmodificados = camposmodificados + ", ";
            }
            camposmodificados = camposmodificados + "Vigencia:( ";
            camposmodificados =
              camposmodificados +
              this.state.oldVigencia +
              " a " +
              newMov.operadorLicenciaVigencia +
              ")";
          }

          if (
            this.state.oldRemolquePlaca !==
            newMov.remolquePlaca
          ) {
            this.state.oldRemolquePlaca == null ? bitacora = 20 : bitacora = 18;  
            if (camposmodificados.length > 0) {
              camposmodificados = camposmodificados + ",  ";
            }
            camposmodificados = camposmodificados + "Remolque Placa:( ";
            camposmodificados =
              camposmodificados +
              this.state.oldRemolquePlaca +
              " a " +
              newMov.remolquePlaca +
              ")";
          }


          if (
            this.state.oldTipoRemolque !==
            this.state.newTipoRemolque
          ) {
            this.state.oldTipoRemolque == null ? bitacora = 20 : bitacora = 18;  
            if (camposmodificados.length > 0) {
              camposmodificados = camposmodificados + ",  ";
            }
            camposmodificados = camposmodificados + "Tipo de Remolque:( ";
            camposmodificados =
              camposmodificados +
              this.state.oldTipoRemolque +
              " a " +
              this.state.newTipoRemolque +
              ")";
          }


          if (
            this.state.oldMarca !==
            newMov.tractorMarca
          ) {
            this.state.oldMarca == null  ? bitacora = 20 : bitacora = 18;  
            if (camposmodificados.length > 0) {
              camposmodificados = camposmodificados + ",  ";
            }
            camposmodificados = camposmodificados + "Marca:( ";
            camposmodificados =
              camposmodificados +
              this.state.oldMarca +
              " a " +
              newMov.tractorMarca +
              ")";
          }

          if (
            this.state.oldModelo !==
            newMov.tractorModelo
          ) {
            this.state.oldModelo == null  ? bitacora = 20 : bitacora = 18;  
            if (camposmodificados.length > 0) {
              camposmodificados = camposmodificados + ", ";
            }
            camposmodificados = camposmodificados + " Modelo:( ";
            camposmodificados =
              camposmodificados +
              this.state.oldModelo +
              " a " +
              newMov.tractorModelo +
              ")";
          }

          if (
            this.state.oldColor !==
            newMov.tractorColor
          ) {
            this.state.oldColor == null  ? bitacora = 20 : bitacora = 18;  
            if (camposmodificados.length > 0) {
              camposmodificados = camposmodificados + ",  ";
            }
            camposmodificados = camposmodificados + "Color:( ";
            camposmodificados =
              camposmodificados +
              this.state.oldColor +
              " a " +
              newMov.tractorColor +
              ")";
          }


          
       
      

      return { id: bitacora, descripcion: camposmodificados };

    }


    handleSubmit(event) {
  
        event.preventDefault();
        const form  = event.target;
        const data = new FormData(form);
        const name = this.state.inputValue;
        let method = "post";
  
        console.log(data.get("idRemolque"));
        let JSON_REQUEST ={  
            "idMovimiento":this.props.data.id,
            "idRemolque":{"idRemolque":data.get('idRemolque')},
            "operadorNombre":name,
            "operadorContacto":data.get('contacto'),
            "operadorLicencia":data.get('tipo_licencia'),
            "tractorModelo":data.get('modelo'),
            "tractorMarca":data.get('marca'),
            "tractorColor":data.get('color'),
            "tractorPlaca":data.get('placa_tractor'),
            "remolquePlaca":data.get('placa_remolque'),
            "operadorLicenciaVigencia":data.get('vigencia'),
            "idUsuario":{"id":this.props.usuario.id}
    
          
        };
    
        if(this.state.idMovimentoPatio != null){                 
                 JSON_REQUEST["id"] = this.state.idMovimentoPatio;
                 method = "put";
                     
                  
        }


        Crud_Catalogos("movimientosPatio/" ,"",method,"",JSON_REQUEST,"","","",[]).then((returnVal)=>{                                                
          NotificationManager.info("Se ha vinculado el operador al movimiento existosamente", "Guardado" );


              let bitacora = this.detectedChange(this.state.oldMov,JSON_REQUEST);

              let usuariosLogObject = {
                id: "",
                idBitacoraMonitoreo: bitacora.id,
                idUsuario: this.props.usuario.id,
                idMovimiento: this.props.data.id,
                accionFecha: this.myFormato(new Date()),
                descripcion: bitacora.descripcion,
                idPlataformaTipo: 1,
                Navigator: false,
              };
              
              Crud_Catalogos(
                "usuariosLog",
                this.catalogo,
                "post",
                "",
                usuariosLogObject
              );

          this.props.closeModal();  
        
        }).catch(err =>{ 
          NotificationManager.error("Hubo un error al vincular al operador con el movimiento", "Error" );
          console.log(err);
        });

        
    }

render() {
          return (
                 this.state.patio.operadorNombre?

                 <List.Group>
                    <List.GroupItem action icon="truck"> Transportista:   <strong> { this.props.data.idTransportista? this.props.data.idTransportista.nombre:""} </strong> </List.GroupItem>
                    <Form onSubmit={this.handleSubmit} >
                      <List.GroupItem action icon="user"   className="operador-flex" >Operador:
                          
                          <Select  components={{Input}} 
                                   onInputChange={this.onInputChange} 
                                   isClearable={false} 
                                   inputValue={this.state.inputValue}
                                   controlShouldRenderValue={false}  
                                   className="select-operador" 
                                   name="nombreOperador" 
                                   value={this.state.operadorOpcion == null ? this.state.inputValue : this.state.operadorOpcion} 
                                   onChange={(e,a)=>{ this.handleChange(e)}} 
                                   options={this.state.operadoresOpciones} />
                      <input
                          tabIndex={-4}
                          autoComplete="off"
                          style={{ opacity: 0, height: 0 }}
                          value={this.state.inputValue}
                          required={this.state.requiredOperador}
                        />    
                  
                      </List.GroupItem>       
                      <List.GroupItem action icon="phone"  className="operador-flex"> Contacto:       <Form.Input className="select-operador"  name="contacto" value={this.state.contacto == null ? this.state.operadorOpcion?.value.medioComunicacion : this.state.contacto} onChange={(e,a)=>{this.setState({contacto:e.value}) }} required>  </Form.Input> </List.GroupItem>
                      <List.GroupItem action icon="truck"  className="operador-flex"> Placa tractor:  <Form.Input className="select-operador" name="placa_tractor" value={this.state.tractorPlaca} onChange={(e,a)=>{this.setState({tractorPlaca:e.value})}} required></Form.Input> </List.GroupItem>                  
                      <List.GroupItem action icon="pocket" className="operador-flex"> Marca:          <Form.Input className="select-operador" name="marca" required value={this.state.marca} onChange={(e,a)=>{this.setState({marca:e.value})}} > </Form.Input > </List.GroupItem>
                      <List.GroupItem action icon="circle" className="operador-flex"> Color:          <Form.Input className="select-operador" name="color" required value={this.state.color} onChange={(e,a)=>{this.setState({color:e.value})}}>  </Form.Input> </List.GroupItem>
                      <List.GroupItem action icon="underline" className="operador-flex"> Modelo:      <Form.Input className="select-operador" name="modelo" required value={this.state.modelo} onChange={(e,a)=>{this.setState({modelo:e.value})}}>  </Form.Input> </List.GroupItem>
                      <List.GroupItem action icon="user"      className="operador-flex"> Selecciona tipo de remolque:<Select className="select-operador" name="idRemolque" value={this.state.remolque } defaultValue={this.state.remolque} onChange={(e,a)=>{ this.setState({remolque:e}); this.setState({newTipoRemolque:e.label});  e.value != null ?  this.setState({required:false}): this.setState({required:true})  }} options={this.state.remolquesOpciones} />
                      <input
                          tabIndex={-4}
                          autoComplete="off"
                          style={{ opacity: 0, height: 0 }}
                          value={this.state.remolqueOpcion}
                          required={this.state.required}
                        />  
                      </List.GroupItem>  
                      <List.GroupItem action icon="truck"     className="operador-flex"> Placa Remolque:   <Form.Input className="select-operador" name="placa_remolque" required value={this.state.remolquePlaca}  onChange={(e,a)=>{this.setState({remolquePlaca:e.value})}} >  </Form.Input> </List.GroupItem>
                      <List.GroupItem action icon="underline" className="operador-flex"> Tipo de Licencia: <Form.Input className="select-operador"  name="tipo_licencia"          value={this.state.licencia == null ? this.state.operadorOpcion?.value.licenciaTipo : this.state.licencia}             onChange={(e,a)=>{this.setState({licencia:e.value}) }} required> </Form.Input> </List.GroupItem>
                      <List.GroupItem action icon="underline" className="operador-flex"> Vigencia:         <Form.Input type="date" className="select-operador"  name="vigencia"   value={this.state.vigencia == null ? this.state.operadorOpcion?.value.licenciaVigencia : this.state.vigencia }             onChange={(e,a)=>{this.setState({vigencia:e.value}) }} required> </Form.Input> 
                     
                      
                      </List.GroupItem>
                      <div className="float-right">
                                    
                                    <span class="badge">
                                        <Button  type="submit" size="sm" color="primary"
                                        >
                                        <span class="badge"><Icon  name="save" />Registrar</span>
                                        </Button>
                                    </span>
                      </div>
                    </Form>
                  </List.Group>
                  :
                  <List.Group>
                    <List.GroupItem action icon="truck"> Transportista:   <strong> { this.props.data.idTransportista? this.props.data.idTransportista.nombre:""} </strong> </List.GroupItem>
                    <Form onSubmit={this.handleSubmit} >
                      <List.GroupItem action icon="user"   className="operador-flex" >Operador:
                          <Select  components={{Input}} 
                                   onInputChange={this.onInputChange} 
                                   isClearable={false} 
                                   inputValue={this.state.inputValue}
                                   controlShouldRenderValue={false}  
                                   className="select-operador" 
                                   name="nombreOperador" 
                                   value={this.state.operadorOpcion} 
                                   onChange={(e,a)=>{ this.handleChange(e)}} 
                                   options={this.state.operadoresOpciones} />
                      <input
                          tabIndex={-4}
                          autoComplete="off"
                          style={{ opacity: 0, height: 0 }}
                          value={this.state.inputValue}
                          required={this.state.requiredOperador}
                        />    
                  
                      </List.GroupItem>       
                      <List.GroupItem action icon="phone"  className="operador-flex"> Contacto:       <Form.Input className="select-operador"  name="contacto" value={this.state.operadorOpcion != null ? this.state.operadorOpcion.value.medioComunicacion : this.state.contacto} onChange={(e,a)=>{this.setState({contacto:e.value}) }} required>  </Form.Input> </List.GroupItem>
                      <List.GroupItem action icon="truck"  className="operador-flex"> Placa tractor:  <Form.Input className="select-operador" name="placa_tractor" required></Form.Input> </List.GroupItem>                  
                      <List.GroupItem action icon="pocket" className="operador-flex"> Marca:          <Form.Input className="select-operador" name="marca" required> </Form.Input > </List.GroupItem>
                      <List.GroupItem action icon="circle" className="operador-flex"> Color:          <Form.Input className="select-operador" name="color" required>  </Form.Input> </List.GroupItem>
                      <List.GroupItem action icon="underline" className="operador-flex"> Modelo:      <Form.Input className="select-operador" name="modelo" required>  </Form.Input> </List.GroupItem>
                      <List.GroupItem action icon="user"      className="operador-flex"> Selecciona tipo de remolque:<Select className="select-operador" name="idRemolque" value={this.state.remolqueOpcion} onChange={(e,a)=>{ this.setState({remolqueOpcion:e}); this.setState({newTipoRemolque:e.label}); e.value != null ?  this.setState({required:false}): this.setState({required:true})  }} options={this.state.remolquesOpciones} />
                      <input
                          tabIndex={-4}
                          autoComplete="off"
                          style={{ opacity: 0, height: 0 }}
                          value={this.state.remolqueOpcion}
                          required={this.state.required}
                        />  
                      </List.GroupItem>  
                      <List.GroupItem action icon="truck"     className="operador-flex"> Placa Remolque:   <Form.Input className="select-operador" name="placa_remolque" required>  </Form.Input> </List.GroupItem>
                      <List.GroupItem action icon="underline" className="operador-flex"> Tipo de Licencia: <Form.Input className="select-operador"  name="tipo_licencia" value={this.state.operadorOpcion != null ? this.state.operadorOpcion.value.licenciaTipo : this.state.licencia} onChange={(e,a)=>{this.setState({licencia:e.value}) }} required> </Form.Input> </List.GroupItem>
                      <List.GroupItem action icon="underline" className="operador-flex"> Vigencia:         <Form.Input type="date" className="select-operador"  name="vigencia"   value={this.state.operadorOpcion != null ? this.state.operadorOpcion.value.licenciaVigencia : this.state.vigencia} onChange={(e,a)=>{this.setState({vigencia:e.value}) }} required> 
                      </Form.Input> 
                     
                      
                      </List.GroupItem>
                      <div className="float-right">
                                    
                                    <span class="badge">
                                        <Button  type="submit" size="sm" color="primary"
                                        >
                                        <span class="badge"><Icon  name="save" />Registrar</span>
                                        </Button>
                                    </span>
                      </div>
                    </Form>
                  </List.Group>
      )
}}

export default OperadoresNavieros;