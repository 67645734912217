import * as React from "react";
import {Grid} from "tabler-react";
import SiteWrapper from "../../../SiteWrapper.react";
import Catalogo from "../../../components/Catalogo";
//import {PersonasForm}  from "../../Catalogos";

import Crud_Catalogos from "../../../herramientas/Crud_Catalogos";
import RemolquesForm from "../../../Catalogos/Remolques";
var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
function getfuntion (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") {
  Crud_Catalogos(catalogo, "perfiles", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
    switch (metodo) {
      case "get": 
      if (stateVar === "funcionalidades") {
        for( let item of returnVal){
          if(item.nombre == "Corredores"){ return true; }
        }
        window.history.back();
        window.location.href = "403";
      }  break;
      default:
        break;
    }
  }).catch(err => { console.log("Error desconocido ...."+ err); })
}
function RemolquesTransportistasPage() {
  getfuntion("get", "", "", "funcionalidades/Administracion/"+currentUser[0].perfil.id, "funcionalidades");
  return (
    <SiteWrapper>
        <Grid.Col sm={10} lg={10} className="grid-table"> 
        <Catalogo Ctlogo="remolquesTransportistas" tamanio="45%" FCtlogo= { RemolquesForm } catalogos= {{ 0: "privilegios", 1:"localidadesTransportistas", 2: "remolquesTransportistas" , 3:"remolquesTipo"}}/>
        </Grid.Col>
    </SiteWrapper>
  );
}
export default RemolquesTransportistasPage;
