import React, { Component } from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import "./ModalLayout.react.css";
var appElement = document.getElementById("example");
Modal.setAppElement(appElement);
class ModalLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };
    this.modalIsOpen = false;
  }
  customStyles = {
    content: {
      position: "relative",
      top: "60px",
      width: this.props.tamanio,
      height: "auto",
      margin: "auto",
      zIndex: 70,
    },
  };
  componentWillUpdate = () => {
    if (this.modalIsOpen === true && this.props.hiddenModal === true) {
      this.setState({ modalIsOpen: false });
      this.modalIsOpen = false;
      this.props.Fclose();
    }
  };
  openModal = () => {
    //this.props.Fclose();
    this.setState({ modalIsOpen: true });
    this.modalIsOpen = true;
  };
  closeModal = () => {
    this.setState({ modalIsOpen: false });
    this.modalIsOpen = false;
  };
  handleModalCloseRequest = () => {
    this.setState({ modalIsOpen: false });
    this.modalIsOpen = false;
  };
  handleSaveClicked = (e) => {
    alert("Save button was clicked");
  };
  render() {
    return (
      <>
        <label
          className="transparent-input thumbnail"
          onClick={!this.props.disabled ? this.openModal : null}
        >
          {this.props.name}
        </label>
        <Modal
          closeTimeoutMS={50}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.handleModalCloseRequest}
          className="Modal"
          overlayClassName="Overlay"
          style={this.customStyles}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title"> {this.props.title}</h4>
              <button
                type="button"
                className="btn btn-dark fa fa-close outline pill"
                onClick={this.handleModalCloseRequest}
              />
            </div>
            <div className="modal-body">{this.props.formulario}</div>
          </div>
        </Modal>
      </>
    );
  }
}

//ReactDOM.render(<ModalLayout/>, appElement);
ReactDOM.render(<ModalLayout />, document.getElementById("root"));
export default ModalLayout;
