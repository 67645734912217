import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import TabsLayout  from "../../components/TabsLayout.react";
import { Transportistas,Remolques} from "./Formularios"


class  TransportistaForm extends Component {
  constructor(props) {
    console.log(props)
    super(props);
    this.state = {
                   currentTap:0,
                   id:"",
                   accion:this.props.accion, //'nuevo'// modificar,
                   ready:false
                 };
            this.tabs =[];//this.props.elementos.nombre
            this.Remolques       ={ title:"Remolques",      icon:"layout",   panel:<Remolques       accion={ this.props.accion } elementos= { this.props.elementos } getfuntion= { this.props.getfuntion} data = {this.props.data} stor= { this.props.stor }  closeModal={this.props.cerrarModal} />,close:false };
          }
  componentDidMount =() => { if(this.state.accion !== 'nuevo'){ this.setState({id:this.props.elementos.id}); }else{ } };

  componentWillMount =() => {
    if(this.state.accion !== 'nuevo' && this.props.tipo !== 2){
      if (this.props.elementos.tipo===1){
        this.tabs= [
          this.Transportistas, this.Contactos, this.Etiquetas, this.ContactosCorredor
        ];
      }else{
        this.tabs= [
          this.Transportistas, this.Contactos, this.ContactosCorredor, this.Plataformas, this.Etiquetas
        ];
      }
      if( this.props.plataforma ){
        this.tabs.push(this.WatsAppGrupos);
      }
    }else{
      if(this.props.tipo ===2){
        this.tabs= [ this.Remolques ];
      }else{
        this.tabs= [ this.Transportistas ];
      }
      
    }    
  }
  selectedTab =(index) => { this.setState({ currentTap:index }) }
  render() {
    return (
      <div>
        <TabsLayout tabs={ this.tabs } selectedTab= { this.state.currentTap } selectTab= { this.selectedTab } />
      </div>
    )
  }
}

export default TransportistaForm
