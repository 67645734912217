import React from "react";
import * as ACTIONS from "../store/actions/actions";
import { connect } from "react-redux";
import ModalLayout from "./ModalLayout.react";
import { ActualizarHorariosNavieros } from "../Monitoristas/Monitoreo/panelForms";
import  OperadoresNavieros  from "../Catalogos/OperadoresNavieros/OperadoresNavieros";
import { NavieraServiceFailure } from "../Monitoristas";
import { Crud_Catalogos, Crud_error } from "../herramientas/Crud_Catalogos";
import {
  Form,
  Table,
  Alert,
  Icon,
  Dimmer,
  Button,
  List,
} from "tabler-react";
import { Exportar } from "../herramientas/Excel";
import Select from "react-select";
import { TablePageable } from "../Helpers";
import MonitoreoMensajeria from "../socket/MonitoreoMensajeria.react";
import EtaCalculada from "../Monitoristas/Monitoreo/panelForms/EtaCalculada";
import { timeToTimeFull, timeToMilisegundos, diff } from "../herramientas/DateFormat";
import { NotificationManager } from "react-notifications";
import { timeToDatefull, milisecondsToHours } from "../herramientas/DateFormat";
import classes from "./EspecificoNaviero.module.css";
import IncidenciasFiltro from "./IncidenciasFiltro";
import IncidenciasTransportista from "./IncidenciasTransportista";
//import Swal from 'sweetalert2';

var user = JSON.parse(sessionStorage.getItem("usuario"));
var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
function myFormato(date) {
  function pad(number) {
    if (number < 10) {
      return "0" + number;
    }
    return number;
  }
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    " " +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds())
  );
}
let movsArray = {};
const subDominios = [
  { plataforma: "CMA-APL", dominio: "cliente-cma.skyangel.com.mx" },
  { plataforma: "Maersk", dominio: "intermodalmaersk.com" },
  { plataforma: "MSK-SLV", dominio: "intermodalmaersk.com" },
  { plataforma: "MSK-GTA", dominio: "intermodalmaersk.com" },
  { plataforma: "MSK-HN", dominio: "intermodalmaersk.com" },
  { plataforma: "MSK-NCA", dominio: "intermodalmaersk.com" },
  { plataforma: "MSC", dominio: "msc.skynavieros.com" },
  { plataforma: "Arcor", dominio: "arcor.skynavieros.com" },
  { plataforma: "Hamburg Sud", dominio: "hamburg.skynavieros.com" },
  { plataforma: "CMA-APL", dominio: "apl.skynavieros.com" },
  { plataforma: "CMA-APL", dominio: "cma-cgm.skynavieros.com" },
  { plataforma: "Navieros", dominio: "msc.skynavieros.com" },
  { plataforma: "MSK-GTA", dominio: "localhost:3000" },
];

Date.prototype.getWeekNumber = function() {
  var d = new Date(
    Date.UTC(this.getFullYear(), this.getMonth(), this.getDate())
  );
  var dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
};
class Catalogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      elementos: [],
      movsReport: [],
      movimiento: {},
      entregas: [],
      tableheaderItems: [],
      test: this.categorias_object,
      hiddenModals: false,
      readyData: false,
      loadingEntregas: false,
      message: "",
      ErrorMessage: [],
      showErrormessage: false,
      selectedRow: -1,
      alertasN: {},
      gpsN: {},
      movsGpsEstatus: {},
      plataforma: { value: 0, label: "Todas ...", search: "" },
      funcionalidadesCol: [],
      textBuscar: "",
      movimientoAcumuladores: {},
      colToFilter: "po", //columna a filtrar
      AscDesc: true,
      incidenciaColumna: {col: "", Desc: true},
      incidenciatableheader: [],
      bitacoratableheader:[],
      metodo: "get",
      topics: [],
      excelRender: true,
      activos: true,
      incidenciasReporte: [],
      fecha1: timeToDatefull(
        new Date(
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            0,
            0,
            0,
            0
          ).getTime() -
            1000 * 60 * 60 * 24 * 7
        ).getTime()
      ),
      fecha2: timeToDatefull(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          0,
          0,
          0,
          0
        ).getTime()
      ),
      informe3: [],
      reporte3: [],
      informe4: [],
      reporte4: [],
      informe69: [],
      reporte69: [],
      informe70: [],
      reporte70: [],
      informes: {},
      patio: {}
    };
    this.usuariosLogObject = {
      id: "",
      idBitacoraMonitoreo: 0,
      idUsuario: 0,
      idMovimiento: 0,
      accionFecha: "",
      descripcion: "",
      idPlataformaTipo: 1,
      Navigator: false,
    };
    this.colToFilter = "po";
    this.AscDesc = true;
    this.ErrorMessage = "";
    this.ErrorMessageArray = [];
    this.catalogo = this.props.Ctlogo;
    this.catalogoTem = "";
    this.Catalogo_form = this.props.FCtlogo;
    this.tamanio = this.props.tamanio;
    this.updateStatus_elementos = this.updateStatus_elementos.bind(this);
    this.getfuntion = this.getfuntion.bind(this);
    this.content = [];
    //this.colum = this.props.colum | [];
    this.registro = [];
    this.columna = [];
    this.fila = [];
    this.campo = [];
    this.get_Store_Elements = null; //this.props.plataformas_object;//update_Store_Elements
    this.update_Store_Elements = null; //this.props.plataformas_update;
    this.getMensage = false;
    this.plataformas = [];
    this.remolques= {};
    this.movimientoAcumuladores = {};
    this.mensaje = {
      mensaje: "",
      plataforma: "",
      corredor: "",
      vista: "",
      data: null,
      accion: "get",
    };
    this.usuariosCorredores = [];
    this.camposLogisticosNav = [];

    this.movsReport = [];
  }

  estaPrivilegioActivo = (clave) => {
    var privilegios = this.props.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (privilegios[i].clave === clave) {
        if (
          perfil.privilegios.filter(
            (word) => word.idPrivilegio === privilegios[i].id
          ).length > 0
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  componentDidUpdate = () => {
    if (this.state.elementos !== this.props.movimientos_naviera_object) {
      //this.props.setMovimientosNavieros(this.state.elementos);
    }
    if (this.props.alertasN !== this.state.alertasN) {
      this.setState({ alertasN: this.props.alertasN });
    }
    if (this.props.gpsNavieroReciente !== this.state.gpsN) {
      this.setState({ gpsN: this.props.gpsNavieroReciente });
    }
    
    if (this.props.movsGpsEstatus !== this.state.movsGpsEstatus) {
      this.setState({ movsGpsEstatus: this.props.movsGpsEstatus });
    }
    if (this.state.funcionalidadesCol !== this.props.columnas) {
      this.setState({ funcionalidadesCol: this.props.columnas });
      this.table_headers(true);
    }

    if (this.usuariosCorredores !== this.props.usuariosCorredores) {
      // actualiza socket
      this.usuariosCorredores = this.props.usuariosCorredores;
      var topics = [];
      for (let item of this.props.usuariosCorredores) {
        topics.push("/topic/" + item.idPlataforma + "-" + item.idCorredor);
      }
      this.setState({ topics: topics });
    }
  };
  updateMessage = (sms) => {
    this.setState({ message: sms });
  };


  componentDidMount = () => {
    this.crud("get", "", "", "remolquesTipo", "remolquesTipo");
    if (this.catalogo !== "" || this.catalogo !== null) {
      Object.keys(this.props.catalogos).forEach((key) => {
        if (this.catalogo === this.props.catalogos[key]) {
        }
        switch (this.props.catalogos[key]) {
          case "navieraMovimientosPerfil":
            {
              this.update_Store_Elements = this.props.movimientos_naviera_update;
              let movimientosBy = currentUser[0]
                ? currentUser[0].perfil
                  ? currentUser[0].perfil.movimientosBy
                    ? currentUser[0].perfil.movimientosBy
                    : 0
                  : 0
                : 0;

              if (window.location.host.includes("skyone.skyangel.com.mx") || window.location.host.includes("localhost:3000")) {
                movimientosBy = currentUser[0].perfil.movimientosBy;
              } else {
                for (let s of subDominios) {
                  if (window.location.host.includes(s.dominio)) {
                    for (let cUser of currentUser) {
                      if (cUser.plataforma.nombre === s.plataforma) {
                        this.crud(
                          "get",
                          "",
                          "",
                          "informes/perfil/" + cUser.perfil.id,
                          "informes"
                        );
                        movimientosBy = cUser.perfil.movimientosBy;
                        break;
                      }
                    }
                  }
                }
              }
              if (movimientosBy === 1) { //perfil
                if( Number.isInteger(this.props.mtipo)){
                  if( this.props.mtipo === 0 ){
                    this.getfuntion(
                      "get",
                      [],
                      "",
                      [],
                      this.props.catalogos[key] + "/personalizado",
                      this.update_Store_Elements
                    );
                  }else{
                    this.getfuntion(
                      "get",
                      [],
                      "",
                      [],
                      this.props.catalogos[key] +
                        "/personalizado/movimiento_tipo/" + this.props.mtipo,
                      this.update_Store_Elements
                    );
                  }
                }
              }
              if (movimientosBy === 2) {
                //corredores
                switch (this.props.mtipo) {
                  case 0:
                    this.getfuntion(
                      "get",
                      [],
                      "",
                      [],
                      this.props.catalogos[key] + "/usuario/" + user.id,
                      this.update_Store_Elements
                    );
                    break;
                  default:
                    this.getfuntion(
                      "get",
                      [],
                      "",
                      [],
                      this.props.catalogos[key] +
                        "/usuario/" +
                        user.id +
                        "/" +
                        this.props.mtipo,
                      this.update_Store_Elements
                    );
                    break;
                }
              }
              if (movimientosBy === 3) {
                //transportistas
                switch (this.props.mtipo) {
                  case 0:
                    this.getfuntion(
                      "get",
                      [],
                      "",
                      [],
                      this.props.catalogos[key] +
                        "/usuario_transportista/" +
                        user.id,
                      this.update_Store_Elements
                    );
                    break;
                  default:
                    this.getfuntion(
                      "get",
                      [],
                      "",
                      [],
                      this.props.catalogos[key] +
                        "/usuario_transportista/" +
                        user.id +
                        "/" +
                        this.props.mtipo,
                      this.update_Store_Elements
                    );
                    break;
                }
              }
            }
            break;
          case "movimientosTipo": {
            this.update_Store_Elements = this.props.movimientosTipo_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }
          case "clientes": {
            this.update_Store_Elements = this.props.clientes_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              "catalogos/" + this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }
          case "service_failure": {
            this.update_Store_Elements = this.props.serviceFaulure_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              this.props.catalogos[key] + "/activos",
              this.update_Store_Elements
            );
            break;
          }
          case "navieras": {
            this.update_Store_Elements = this.props.navieras_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }
          case "otd": {
            this.update_Store_Elements = this.props.otd_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }
          case "plataformas": {
            this.update_Store_Elements = this.props.plataformas_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }
          case "rutasEstatus": {
            this.update_Store_Elements = this.props.rutasEstatus_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }
          case "transportistas": {
            this.update_Store_Elements = this.props.transportistas_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              "catalogos/" + this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }
          case "etiquetas": {
            this.update_Store_Elements = this.props.etiquetas_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }
          case "funcionalidades": {
            this.update_Store_Elements = this.props.funcionalidades_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }
          case "privilegios": {
            this.update_Store_Elements = this.props.privilegios_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }
          case "operacionesEstatus": {
            this.update_Store_Elements = this.props.operacionesEstatus_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }
          case "operacionesTipo": {
            this.update_Store_Elements = this.props.operacionesTipo_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }
          case "operadores": {
            this.update_Store_Elements = this.props.operadores_update;
            this.getfuntion(
              "get",
              [],
              "",
              [],
              this.props.catalogos[key],
              this.update_Store_Elements
            );
            break;
          }

          default:
            return;
        }
      });
    }
    this.plataformas.push({ value: 0, label: "Todas ...", search: "" });
    currentUser.map((usr, index) => {
      if (
        usr.plataforma.plataformaTipo.nombre.toUpperCase().includes("NAVIERA")
      ) {
        this.plataformas.push({
          value: usr.plataforma.id,
          label: usr.plataforma.nombre,
          search: usr.plataforma.nombre,
        });
      }
      return usr;
    });
    if(this.plataformas.length === 2){
      this.setState({ plataforma: this.plataformas[1] });
    }
  };
  hiddenmodal = () => {
    this.setState({ hiddenModals: !this.state.hiddenModals });
  };

  getfuntion = (
    metodo = "get",
    params = [],
    id = "",
    arraytoUpdate = [],
    catalogoTem = this.catalogo,
    storeFunction = this.update_Store_Elements
  ) => {
    this.usuariosLogObject.idMovimiento = params;
    let objTemp = params;

    console.log("entro gte")
    if (catalogoTem !== "") {

      console.log("entro crud")
      Crud_Catalogos(
        catalogoTem,
        this.catalogo,
        metodo,
        id,
        params,
        storeFunction,
        this.state.elementos,
        this.hiddenmodal,
        arraytoUpdate
      )
        .then((returnVal) => {
          this.mensaje.accion = metodo;
          this.mensaje.vista = catalogoTem;
          this.setState({ metodo: metodo });
          switch (metodo) {
            case "get":
              if (
                catalogoTem.includes( this.catalogo + "/usuario/" + user.id) ||                
                catalogoTem.includes( this.catalogo + "/personalizado") ||//personalizado activos
                catalogoTem.includes( this.catalogo + "/usuario_personalizado") ||//personalizado finalizados(mas bien cita programada)
                catalogoTem.includes(
                  this.catalogo + "/usuario_transportista/" + user.id
                )
              ) {
                this.updateStatus_elementos();
                this.setState({ readyData: true });
                setTimeout(
                  () => this.setState({ excelRender: true }),
                  1000 * 5
                );
                //Buscamos reportes
                //del subdominio obtenemos la plataforma
                if (window.location.host.includes("skyone.skyangel.com.mx") || window.location.host.includes("localhost:3000")) {
                  this.crud(
                    "get",
                    "",
                    "",
                    "informes/perfil/" + currentUser[0].perfil.id,
                    "informes"
                  );
                } else {
                  for (let s of subDominios) {
                    if (window.location.host.includes(s.dominio)) {
                      //Buscamos el perfil que corresponda a la plataforma
                      for (let cUser of currentUser) {
                        if (cUser.plataforma.nombre === s.plataforma) {
                          //obtener informes del perfil
                          this.crud(
                            "get",
                            "",
                            "",
                            "informes/perfil/" + cUser.perfil.id,
                            "informes"
                          );
                          break;
                        }
                      }
                    }
                  }
                }
              }
              break;
            case "post":
              this.mensaje.data = returnVal;
              this.mensaje.corredor = returnVal.idCorredor.id;
              this.mensaje.plataforma = returnVal.idPlataforma.id;
              this.mensaje.mensaje =
                "Nuevo movimiento (" +
                returnVal.id +
                ", plataforma: " +
                returnVal.idPlataforma.nombre +
                ")";
              this.props.updateStatusMensaje(true);
              this.usuariosLogObject.idBitacoraMonitoreo = 1;
              this.usuariosLogObject.idUsuario = user.id;
              this.usuariosLogObject.idMovimiento = returnVal.id;
              this.usuariosLogObject.idPlataformaTipo = 1;
              this.usuariosLogObject.accionFecha = myFormato(new Date());
              this.usuariosLogObject.descripcion = "Nuevo Movimiento";
              Crud_Catalogos(
                "usuariosLog",
                this.catalogo,
                "post",
                "",
                this.usuariosLogObject
              );
              break;
            case "put":
              this.mensaje.data = returnVal.data;
              this.mensaje.corredor = returnVal.data.idCorredor.id;
              this.mensaje.plataforma = returnVal.data.idPlataforma.id;
              this.mensaje.mensaje =
                "Actualización (" +
                returnVal.data.id +
                ", plataforma: " +
                returnVal.data.idPlataforma.nombre +
                ")";
              this.props.updateStatusMensaje(true);
              this.usuariosLogObject.idBitacoraMonitoreo = 1;
              this.usuariosLogObject.idUsuario = user.id;
              this.usuariosLogObject.idMovimiento = objTemp.id;
              this.usuariosLogObject.idPlataformaTipo = 1;
              this.usuariosLogObject.accionFecha = myFormato(new Date());
              this.usuariosLogObject.descripcion =
                "Actualización de Movimiento";
              if (objTemp.idOperacionEstatus.id === 1) {
                this.usuariosLogObject.idBitacoraMonitoreo = 5;
                this.usuariosLogObject.descripcion = "Se finaliza movimiento";
              }
              Crud_Catalogos(
                "usuariosLog",
                this.catalogo,
                "post",
                "",
                this.usuariosLogObject
              );
              break;
            case "delete":
              this.usuariosLogObject.idBitacoraMonitoreo = 10;
              this.usuariosLogObject.idUsuario = user.id;
              this.usuariosLogObject.accionFecha = myFormato(new Date());
              this.usuariosLogObject.idPlataformaTipo = 1;
              this.usuariosLogObject.descripcion = "Delete: id:" + returnVal.id;
              Crud_Catalogos(
                "usuariosLog",
                this.catalogo,
                "post",
                "",
                this.usuariosLogObject
              );
              break;
            default:
              break;
          }
        })
        .catch((err) => {
          console.log("Error: " + err);
          if (err.response) {
            this.setState({ showErrormessage: true });
            this.ErrorMessage = Crud_error(err.response.status, catalogoTem);
            this.ErrorMessageArray.push(
              <Alert type="danger" icon="alert-octagon">
                {" "}
                {this.ErrorMessage}{" "}
              </Alert>
            );
            this.setState({ ErrorMessage: this.ErrorMessageArray });
          } else {
            this.setState({ showErrormessage: true });
            this.ErrorMessage = Crud_error(404, catalogoTem);
            this.ErrorMessageArray.push(
              <Alert type="danger" icon="alert-octagon">
                {" "}
                {this.ErrorMessage}{" "}
              </Alert>
            );
            this.setState({ ErrorMessage: this.ErrorMessageArray });
          }
        });
    }
  };
  updateStatus_elementos = () => {
    let movsListTemp = [];
    if(this.props.movimientos_naviera_object){
      for( let m of this.props.movimientos_naviera_object ) {
        movsListTemp.push(m);
        movsListTemp[movsListTemp.length-1].variables= JSON.parse(movsListTemp[movsListTemp.length-1].variables);
      } 
    }
    this.setState({ elementos: movsListTemp });
    if (this.props.movimientos_naviera_object) {
      this.setState({ movsReport: movsListTemp });
    }
    this.props.setMovimientosNavieros(
      this.props.movimientos_naviera_object
        ? this.props.movimientos_naviera_object
        : []
    );
    if (this.state.informe3 && this.props.movimientos_naviera_object) {
      if (this.state.informe3.length > 0) {
        let movsTemp = [];
        this.props.movimientos_naviera_object.map((m, index) => {
          movsTemp[index] = {};
          for (let item of this.state.informe3) {
            movsTemp[index][item.label] = m[item.nombre];
          }
          return m;
        });
        this.setState({ reporte3: movsTemp });
      }
    }
    if (this.state.informe4 && this.props.movimientos_naviera_object) {
      if (this.state.informe4.length > 0) {
        let movsTemp = [];
        this.props.movimientos_naviera_object.map((m, index) => {
          movsTemp[index] = {};
          for (let item of this.state.informe4) {
            movsTemp[index][item.label] = m[item.nombre];
          }
          return m;
        });
        this.setState({ reporte4: movsTemp });
      }
    }
  };
  obtenerColumnas=()=>{
    let columnasTemp= [
      /*
        { 
          columna: "id",
          label: "Id",
          //enClick:(e) => setCurrentMovimiento(e), //enClickDisabled: (e)=> false,//e.idSolicitud === 303? false: true,
          //form: (e)=> <i className="fa fa-edit" style={{"font-size": "24px"}}><p style={{fontSize: "16px"}}>{e.idSolicitud}</p></i>
        },
        { columna: "idMovimiento", label: "idMovimiento" },
        { columna: "po", label: "Po", splitBy:";" },
      */
    ];
    for(let c of this.props.columnas){
      switch (c.columna) {
        case "contenedorTipo":
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (e)=> e[c.columna] === 1? "Seco": e[c.columna] === 2? "Refrigerado": "Caja" });
          break;
        case "contenedorSize":
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (e)=>
              e[c.columna] < 12?
                this.remolques[e[c.columna]]?
                  this.remolques[e[c.columna]].nombre
                  : e[c.columna]
                : e[c.columna],
            valor: (e)=> 
              e[c.columna] < 12?
                this.remolques[e[c.columna]]?
                  this.remolques[e[c.columna]].nombre
                  : e[c.columna]
                : e[c.columna]
          });
          break;
        case "estatus":
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (e)=> <Form.Switch type="Checkbox" checked={e[c.columna]} /> });
          break;
        case "ontime":
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (e)=>{
              if( currentUser.filter(c=> c.plataforma.id === 1315 && e.idMovimientoTipo.id === 2 ).length ){
                let tiempoTemp=//2023-10-05 16:41:00- 2023-10-05 13:35:00
                  e.inicioRutaVacio !== null
                  && e.arriboDirecionCarga !== null
                  && (new Date(e.inicioRutaVacio).getTime() - new Date(e.arriboDirecionCarga).getTime()) > 0 ?
                    new Date(e.inicioRutaVacio).getTime() - new Date(e.arriboDirecionCarga).getTime()
                  :0;
                //(date_part('epoch',(m.inicio_ruta_vacio - m.arribo_direcion_carga)) * interval '1 second')::text
                return  <div style={ tiempoTemp ? { "background-color": "green", } : {}}>
                          {timeToTimeFull(tiempoTemp)}
                        </div>
              }else{
                return  <div style={ timeToMilisegundos(e[c.columna]) ? { "background-color": "green", } : {}}>
                          {e[c.columna]}
                        </div>
              }
            }
          });
          break;
        case "delay":
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (e)=>{
              if( currentUser.filter(c=> c.plataforma.id === 1315 && e.idMovimientoTipo.id === 2 ).length ){
                let tiempoTemp=
                  e.inicioRutaVacio !== null
                  && e.arriboDirecionCarga !== null
                  && (new Date(e.arriboDirecionCarga).getTime() - new Date(e.inicioRutaVacio).getTime()) > 0 ?
                    new Date(e.arriboDirecionCarga).getTime() - new Date(e.inicioRutaVacio).getTime()
                  :0;
                //(date_part('epoch',(m.arribo_direcion_carga - m.inicio_ruta_vacio)) * interval '1 second')::text
                return  <div style={ tiempoTemp ? { "background-color": "red", } : {}}>
                          {timeToTimeFull(tiempoTemp)}
                        </div>
              }else{
                return  <div style={ timeToMilisegundos(e[c.columna]) ? { "background-color": "red", } : {}}>
                          {e[c.columna]}
                        </div>
              }
            }
          });
          break;
        case "origen":
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (e)=> e.idCorredor.nombre});
          //TODO revisar si origen destino
          // columnasTemp.push({ columna: c.columna, label: c.label,
          //   form: (e)=> [1,3].includes(e[c.columna])? e.idCorredor.nombre: e.destino });
          break;
        case "destino":
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (e)=> e.destino});
          // columnasTemp.push({ columna: c.columna, label: c.label,
          //   form: (e)=> [1,3].includes(e[c.columna])? e.destino: e.idCorredor.nombre });
          break;
        case "service failure":
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (e)=>
              <ModalLayout
                title="Modificar"
                name={
                  <span
                    className="fa fa-bug"
                    style={{
                      color: "green",
                      "font-size": "120%",
                    }}
                  />
                }
                hiddenModal={this.state.hiddenModals}
                Fclose={this.hiddenmodal}
                formulario={
                  <NavieraServiceFailure
                    accion="modificar"
                    elementos={e}
                    getfuntion={this.crud}
                    stor={this.props}
                  />
                }
              /> });
          break;
        case "full":
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (e)=> e.esFull ? "full" : "sencillo" });
          break;
        case "entrega vacio":
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (e)=> {
              return e.idMovimientoTipo.id === 1 && e.salidaClienteVacio && !e.arriboTerminalVacio2 ?
                diff(
                  new Date(
                    e.salidaClienteVacio
                  ).getTime(),
                  new Date().getTime()
                )
              : e.idMovimientoTipo.id === 1 && e.salidaClienteVacio && e.arriboTerminalVacio2 ?
                diff(
                    new Date( //movimientos_terrestres_object: state.catalogos_reducer.movimientos_terrestres_object,
                      e.salidaClienteVacio
                    ).getTime(),
                    new Date(
                      e.arriboTerminalVacio2
                    ).getTime()
                  )
                : ""
            }});
          break;
        case "gps":
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (elemento)=>{
              if (this.state.movsGpsEstatus) {
                let mPatioTemp;
                if (
                  this.props.movPatio[elemento.id]
                ) {
                  mPatioTemp = movsArray[
                    parseInt(
                      this.props.movPatio[elemento.id]
                        .esFull,
                      10
                    )
                  ]
                    ? movsArray[
                        parseInt(
                          this.props.movPatio[
                            elemento.id
                          ].esFull,
                          10
                        )
                      ]
                    : [];
                }
                if (elemento.movimientoGps) {
                    if (
                      elemento.movimientoGps.length
                    ) {           
                      return <Table.Col>
                          {elemento.movimientoGps.map(
                            (gpsTem, inc001) => (
                              this.state.movsGpsEstatus[
                                gpsTem.gps.id
                              ]?
                                <div style={{ width: "65px"}}>                                  
                                  <div className="row">
                                    <div hidden={false}
                                      className="col-12"
                                      style={{
                                        color:
                                          this.state.movsGpsEstatus[
                                            gpsTem.gps.id
                                          ].iconBattery.color,
                                        "font-size": "80%",
                                      }}
                                    >
                                      {
                                        this.state.movsGpsEstatus[
                                          gpsTem.gps.id
                                        ].nombre
                                      }
                                    </div>
                                    <div className="col-4"> {/*  Bateria */}
                                      <div
                                        className="badge"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={
                                          this.state.movsGpsEstatus[
                                            gpsTem.gps.id
                                          ].nombre +
                                          ", " +
                                          this.state.movsGpsEstatus[
                                            gpsTem.gps.id
                                          ].nivel_bateria +
                                          " %"
                                        }
                                        style={{
                                          color:
                                            this.state.movsGpsEstatus[
                                              gpsTem.gps.id
                                            ].iconBattery.color,
                                          "font-size": "100%",
                                        }}
                                      >
                                        <span
                                          className={
                                            this.state.movsGpsEstatus[
                                              gpsTem.gps.id
                                            ].iconBattery.icono
                                          }
                                        ></span>
                                      </div>
                                    </div>
                                    <div className="col-4">{/*  Ultimo reporte */}
                                      <div
                                        className="badge"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title={
                                          this.state.movsGpsEstatus[
                                            gpsTem.gps.id
                                          ].ultimo_reporte
                                        }
                                        style={{
                                          color:
                                            this.state.movsGpsEstatus[
                                              gpsTem.gps.id
                                            ].iconTime.color,
                                          "font-size": "100%",
                                        }}
                                      >
                                        <span
                                          className={
                                            this.state.movsGpsEstatus[
                                              gpsTem.gps.id
                                            ].iconTime.icono
                                          }
                                        ></span>
                                      </div>
                                    </div>
                                    <div className="col-4">{/*  traking */}
                                      <div
                                        className="badge" data-toggle="tooltip" data-placement="top" title="Tracking"
                                        style={{color: "black", "font-size": "100%",}}
                                        onClick={() => this.tracking( elemento, gpsTem.gps) }
                                      >
                                        <span className="fa fa-map-marker float-left" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              :
                                <div>
                                  {
                                    gpsTem.gps
                                      .nombre
                                  }
                                </div>
                            )
                          )}
                        </Table.Col>
                      
                    } else {
                    return mPatioTemp
                        ? mPatioTemp.movimientoGps
                          ? 
                              <Table.Col>
                                {mPatioTemp.movimientoGps.map(
                                  (
                                    gpsTem,
                                    inc001
                                  ) => (
                                    <div>
                                      {
                                        gpsTem.gps
                                          .nombre
                                      }
                                    </div>
                                  )
                                )}
                              </Table.Col>
                            
                          : 
                              <Table.Col></Table.Col>
                            
                        : 
                            <Table.Col></Table.Col>
                          
                    }
                  } else {        
                      return <Table.Col></Table.Col>         
                  }
              } else {
              
                return  <Table.Col>
                    {elemento.movimientoGps
                      ? elemento.movimientoGps.map(
                          (gpsTem, inc001) => (
                            <div>
                              {" "}
                              {gpsTem.gps.nombre}
                            </div>
                          )
                        )
                      : ""}
                  </Table.Col>
              
              }
            }});
          break;
        case "eta":
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (e)=> {
              return this.props.etasN[e.id]
                ? this.props.etasN[e.id][2]?
                    <ModalLayout
                      tamanio={"90%"}
                      title="Etas calculadas"
                      name={
                        <div className="badge bg-secondary">
                          <Icon name="message-square" />{" "}
                          <span>
                            {
                              this.props.etasN[
                                e.id
                              ][2].total
                            }
                          </span>{" "}
                        </div>
                      }
                      hiddenModal={this.state.hiddenModals}
                      Fclose={this.hiddenmodal}
                      formulario={
                        <div>
                          <EtaCalculada
                            idMovimento={e["id"]}
                          />
                        </div>
                      }
                    />
                  : ""
                : ""
            }});
          break;
        case "citaProgramada":
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (e)=>{
              if( c.idFuncionalidad === 2891 && (e.idMovimientoTipo.id === 1 || e.idMovimientoTipo.id === 304) ){//ultima milla
                return e.citaProgramada;
              }else{
                if( c.idFuncionalidad === 2892 && (e.idMovimientoTipo.id === 2 || e.idMovimientoTipo.id === 303) ){//ultima milla
                  return e.citaProgramada;
                }
                return e.citaProgramada
              }
            },
            valor: (e)=>{
              if( c.idFuncionalidad === 2891 && (e.idMovimientoTipo.id === 1 || e.idMovimientoTipo.id === 304) ){//ultima milla
                return e.citaProgramada;
              }else{
                if( c.idFuncionalidad === 2892 && (e.idMovimientoTipo.id === 2 || e.idMovimientoTipo.id === 303) ){//ultima milla
                  return e.citaProgramada;
                }
                return e.citaProgramada
              }
            }
          });
          break;
        case "citaProgramadaTerminal":
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (e)=>{
              if( c.idFuncionalidad === 2890 && (e.idMovimientoTipo.id === 1 || e.idMovimientoTipo.id === 304) ){//ultima milla
                return e.citaProgramadaTerminal;
              }else{
                if( c.idFuncionalidad === 2893 && (e.idMovimientoTipo.id === 2 || e.idMovimientoTipo.id === 303) ){//ultima milla
                  return e.citaProgramadaTerminal;
                }
                return 
              }
            },
            valor: (e)=>{
              if( c.idFuncionalidad === 2890 && (e.idMovimientoTipo.id === 1 || e.idMovimientoTipo.id === 304) ){//ultima milla
                return e.citaProgramadaTerminal;
              }else{
                if( c.idFuncionalidad === 2893 && (e.idMovimientoTipo.id === 2 || e.idMovimientoTipo.id === 303) ){//ultima milla
                  return e.citaProgramadaTerminal;
                }
                return 
              }
            }
          });
          break;
        case "consolidado_cita":
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (e)=>
              e.entregas.length === 2 ? e.entregas.sort((a, b) =>  new Date(a.cita).getTime() - new Date(b.cita).getTime())[0].cita : null,
            valor: (e)=> e.entregas.length === 2 ? e.entregas.sort((a, b) =>  new Date(a.cita).getTime() - new Date(b.cita).getTime())[0].cita : null,
          });
          break;
        case "desconsolidado_cita":
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (e)=>
              e.entregas.length === 2 ? e.entregas.sort((a, b) =>  new Date(b.cita).getTime() - new Date(a.cita).getTime())[0].cita : null,
            valor: (e)=> e.entregas.length === 2 ? e.entregas.sort((a, b) =>  new Date(b.cita).getTime() - new Date(a.cita).getTime())[0].cita : null,
          });
          break;
        case "contenedor":
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (e)=>{
              return <ModalLayout
                tamanio={"20%"}
                title="Modificar"
                name={
                  <div>
                    {" "}
                    <span
                      className="fa fa-edit float-left"
                      style={{
                        color: "green",
                        "font-size": "120%",
                      }}
                    />
                    <span className="float-left ml-3">
                      {e.contenedor}
                    </span>
                  </div>
                }
                hiddenModal={this.state.hiddenModals}
                Fclose={this.hiddenmodal}
                formulario={
                  <ActualizarHorariosNavieros
                    accion={"modificar"}
                    idusuario={user.id}
                    movimiento={e}
                    getfuntion={this.crud}
                    stor={this.props}
                    camposLogisticosNav={null}
                    rutasEstatusObject={
                      this.props.rutasEstatus_object
                    }
                    operacionesEstatus={
                      this.props.operacionesEstatus_object
                    }
                    closeModal={this.hiddenmodal}
                    clientes_object={
                      this.props.clientes_object
                    }
                    otd_object={this.props.otd_object}
                    currentCampo="contenedor"
                  />
                }
              />;
            }});
          break;
        case "sello":
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (e)=>{
              return <ModalLayout
                tamanio={"20%"}
                title="Modificar"
                name={
                  <div>
                    <span
                      className="fa fa-edit float-left"
                      style={{
                        color: "green",
                        "font-size": "120%",
                      }}
                    />
                    <span className="float-left ml-3">
                      {e.sello}
                    </span>
                  </div>
                }
                hiddenModal={this.state.hiddenModals}
                Fclose={this.hiddenmodal}
                formulario={
                  <ActualizarHorariosNavieros
                    accion={"modificar"}
                    idusuario={user.id}
                    movimiento={e}
                    getfuntion={this.crud}
                    stor={this.props}
                    camposLogisticosNav={null}
                    rutasEstatusObject={
                      this.props.rutasEstatus_object
                    }
                    operacionesEstatus={
                      this.props.operacionesEstatus_object
                    }
                    closeModal={this.hiddenmodal}
                    clientes_object={
                      this.props.clientes_object
                    }
                    otd_object={this.props.otd_object}
                    currentCampo="sello"
                  />
                }
              />},
            valor: (e)=> e.sello
            });
          break;
        case "onTimePickUp"://cita-fechaArribo
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (e)=>{
              if( e.idMovimientoTipo.id === 1 || e.idMovimientoTipo.id === 304 ){//ultima milla
                //return milisecondsToHours( e.citaProgramada, e.arriboTerminalVacio)
                return milisecondsToHours( e.citaProgramadaTerminal, e.arriboTerminalVacio)
              }
              if( e.idMovimientoTipo.id === 2 ){//primera milla
                return milisecondsToHours( e.citaProgramada, e.arriboDirecionCarga)
              }
              if( e.idMovimientoTipo.id === 270 ){
                if (e.entregas.length != 0) {//consolidacion
                  return milisecondsToHours(
                    e.entregas.sort((a, b) =>  new Date(a.cita).getTime() - new Date(b.cita).getTime())[0].cita,
                    e.entregas.sort((a, b) =>  new Date(a.cita).getTime() - new Date(b.cita).getTime())[0].fechaArribo
                  )
                }
                return "S/I";
              }
            },
            valor: (e)=>{
              if( e.idMovimientoTipo.id === 1 || e.idMovimientoTipo.id === 304 ){
                return milisecondsToHours( e.citaProgramada, e.arriboTerminalVacio)
              }
              if( e.idMovimientoTipo.id === 2 ){
                return milisecondsToHours( e.citaProgramada, e.arriboDirecionCarga)
              }
              if( e.idMovimientoTipo.id === 270 ){
                if (e.entregas.length != 0) {
                  return milisecondsToHours(
                    e.entregas.sort((a, b) =>  new Date(a.cita).getTime() - new Date(b.cita).getTime())[0].cita,
                    e.entregas.sort((a, b) =>  new Date(a.cita).getTime() - new Date(b.cita).getTime())[0].fechaArribo
                  )
                }
                return "S/I";
              }
            }
          });
          break;
        case "onTimeDelivery"://importacin: citaProgramada-arriboCliente, distribución: e.cita-e.fechaArribo
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (e)=>{
              if( e.idMovimientoTipo.id === 1 ){
                return milisecondsToHours(e.citaProgramada, e.arriboClienteLleno)
              }
              if( e.idMovimientoTipo.id === 2 ){
                return "S/I";
              }
              if( e.idMovimientoTipo.id === 270 ){
                if (e.entregas.length != 0) {
                  return milisecondsToHours(
                    e.entregas.sort((a, b) =>  new Date(b.cita).getTime() - new Date(a.cita).getTime())[0].cita,
                    e.entregas.sort((a, b) =>  new Date(b.cita).getTime() - new Date(a.cita).getTime())[0].fechaArribo
                  )
                }
                return "S/I";
              }
            },
            valor: (e)=>{
              if( e.idMovimientoTipo.id === 1 ){
                return milisecondsToHours(e.citaProgramada, e.arriboClienteLleno)
              }
              if( e.idMovimientoTipo.id === 2 ){
                return "S/I";
              }
              if( e.idMovimientoTipo.id === 270 ){
                if (e.entregas.length != 0) {
                  return milisecondsToHours(
                    e.entregas.sort((a, b) =>  new Date(b.cita).getTime() - new Date(a.cita).getTime())[0].cita,
                    e.entregas.sort((a, b) =>  new Date(b.cita).getTime() - new Date(a.cita).getTime())[0].fechaArribo
                  )
                }
                return "S/I";
              }
            }
          });
          break;
        case "tiempoDescarga"://importacin: arriboClienteLleno-salidaClieneVacio, distribución: e.fechaArribo-fechaSalida
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (e)=>{
              if( e.idMovimientoTipo.id === 1 ){
                return milisecondsToHours(e.salidaClienteVacio, e.arriboClienteLleno)
              }
              if( e.idMovimientoTipo.id === 2 ){
                return "S/I";
              }
              if( e.idMovimientoTipo.id === 270 ){
                if (e.entregas.length != 0) {
                  return milisecondsToHours(
                    e.entregas.sort((a, b) =>  new Date(b.cita).getTime() - new Date(a.cita).getTime())[0].fechaSalida,
                    e.entregas.sort((a, b) =>  new Date(b.cita).getTime() - new Date(a.cita).getTime())[0].fechaArribo
                  )
                }
                return "S/I";
              }
            },
            valor: (e)=>{
              if( e.idMovimientoTipo.id === 1 ){
                return milisecondsToHours(e.salidaClienteVacio, e.arriboClienteLleno)
              }
              if( e.idMovimientoTipo.id === 2 ){
                return "S/I";
              }
              if( e.idMovimientoTipo.id === 270 ){
                if (e.entregas.length != 0) {
                  return milisecondsToHours(
                    e.entregas.sort((a, b) =>  new Date(b.cita).getTime() - new Date(a.cita).getTime())[0].fechaSalida,
                    e.entregas.sort((a, b) =>  new Date(b.cita).getTime() - new Date(a.cita).getTime())[0].fechaArribo
                  )
                }
                return "S/I";
              }
            }
          });
          break;
        case "consolidado_arribo":
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (e)=>
              e.entregas.length === 2 ? e.entregas.sort((a, b) =>  new Date(a.cita).getTime() - new Date(b.cita).getTime())[0].fechaArribo : null,
            valor: (e)=> e.entregas.length === 2 ? e.entregas.sort((a, b) =>  new Date(a.cita).getTime() - new Date(b.cita).getTime())[0].fechaArribo : null,
          });
          break;
        case "desconsolidado_arribo":
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (e)=>
              e.entregas.length === 2 ? e.entregas.sort((a, b) =>  new Date(b.cita).getTime() - new Date(a.cita).getTime())[0].fechaArribo : null,
            valor: (e)=> e.entregas.length === 2 ? e.entregas.sort((a, b) =>  new Date(b.cita).getTime() - new Date(a.cita).getTime())[0].fechaArribo : null,
          });
          break;
        case "desconsolidado_salida":
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (e)=>
              e.entregas.length === 2 ? e.entregas.sort((a, b) =>  new Date(b.cita).getTime() - new Date(a.cita).getTime())[0].fechaSalida : null,
            valor: (e)=> e.entregas.length === 2 ? e.entregas.sort((a, b) =>  new Date(b.cita).getTime() - new Date(a.cita).getTime())[0].fechaSalida : null
          });
          break;
        case "transporte":
          columnasTemp.push({ columna: c.columna, label: c.label,
            enClick: (e)=>{
              this.setState({patio: {}});
              Crud_Catalogos("movimientosPatio/idMovimiento/"+e.id,"","get","",[],"","","",[]).then((returnVal)=>{                                                
                this.setState({patio: returnVal});
              }).catch(err =>{ console.log(err);});
            },
            enClickDisabled: false,
            form: (e)=>
            <ModalLayout tamanio={'60%'} title="Movimiento"
              name={<span><Icon prefix="fa" name="bullhorn" /></span>}
              hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
              formulario={//999022
                <List.Group>
                  <List.GroupItem action icon="truck"> Transportista:   <strong> { e['idTransportista']? e['idTransportista'].nombre:""} </strong> </List.GroupItem>
                  <List.GroupItem action icon="user"> Operador:         <strong> { this.state.patio.operadorNombre? this.state.patio.operadorNombre:"" } </strong> </List.GroupItem>
                  <List.GroupItem action icon="phone"> Contacto:         <strong> { this.state.patio.operadorContacto? this.state.patio.operadorContacto:"" } </strong> </List.GroupItem>
                  <List.GroupItem action icon="truck"> Tracto:          <strong> { this.state.patio.tractorPlaca? this.state.patio.tractorPlaca: ""           } </strong> </List.GroupItem>                  
                  <List.GroupItem action icon="pocket"> Marca:          <strong> { this.state.patio.tractorMarca? this.state.patio.tractorMarca:""  } </strong> </List.GroupItem>
                  <List.GroupItem action icon="circle"> Color:          <strong> { this.state.patio.tractorColor? this.state.patio.tractorColor: ""           } </strong> </List.GroupItem>
                  <List.GroupItem action icon="underline"> Económico Tracto: <strong> { this.state.patio.economicot? this.state.patio.economicot: ""  } </strong> </List.GroupItem>
                  <List.GroupItem action icon="truck"> Remolque:        <strong> { this.state.patio.remolquePlaca? this.state.patio.remolquePlaca: ""  } </strong> </List.GroupItem>
                  <List.GroupItem action icon="underline"> Económico Remolque: <strong> { this.state.patio.economicor? this.state.patio.economicor: ""  } </strong> </List.GroupItem>
                </List.Group>
            }
          />
          }
            );
          break;
        case "otd":
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (e)=>{
              if(timeToMilisegundos(e['ontime']) > 0){
                return "En tiempo"
              }
              if(timeToMilisegundos(e['delay']) > 0){
                return "Tarde"
              }
            },
            valor: (e)=> e.entregas.length === 2 ? e.entregas.sort((a, b) =>  new Date(a.cita).getTime() - new Date(b.cita).getTime())[0].fechaArribo : null,
          });
          break;
          case "registroOperador":
            columnasTemp.push({ columna: c.columna, label: c.label,
              form: (e)=>{
                return <ModalLayout 
                  tamanio={'60%'} 
                  title="Movimiento"
                  name={<span><Icon prefix="fa" name="bullhorn" /></span>}
                  hiddenModal={this.state.hiddenModals} 
                  Fclose={this.hiddenmodal}
                  formulario={
                    <OperadoresNavieros
                        data = {e}
                        accion="modificar"
                        usuario={user}
                        getfuntion={this.getfuntion}
                        stor={this.props}
                        closeModal={this.hiddenmodal}
                     />  
                  }
                />
              },
            });
            break;
        default: columnasTemp.push({ columna: c.columna, label: c.label });
          break;
      }
    }
    return columnasTemp;
  }
  table_headers = (asc) => {
    this.content = [];
    Object.keys(this.props.columnas).forEach((key, index) => {
      this.content.push({
        content: (
          <div
            onClick={() => {
              this.sortElements(this.props.columnas[index].nombre);
            }}
          >
            {this.props.columnas[index].nombre}
            {this.colToFilter === this.props.columnas[index].nombre ? (
              <Icon name={asc ? "chevron-up" : "chevron-down"} />
            ) : null}
          </div>
        ),
      });
    });
    this.setState({ tableheaderItems: this.content });
  };
  /**
   * 
   * @param {*} asc 
   */
  table_headers_incidencias = (asc, col= this.state.incidenciaColumna.col) => {
    const columsTemp=[
      { col:"fechaCreado", label: "Fecha" },
      { col:"incidencia", label: "Incidencia" },
      { col:"comentario", label: "Comentario" }
    ];
    let columnaTemp=[];
    for(let c of columsTemp){//incidenciaColumna: {col: "fechaCreado", Desc: true},
      columnaTemp.push({
        content: (
          <div  onClick={() =>  this.sortIncidencias(c.col) } >
            { c.label }
            { col === c.col ? ( <Icon name={asc ? "chevron-down":"chevron-up"} />) : null}
          </div>
        ),
      });
    }
    this.setState({ incidenciatableheader: columnaTemp });
  };


  table_headers_bitacora = (asc, col= this.state.incidenciaColumna.col) => {
    const columsTemp=[
      { col:"idBitacoraMonitoreo", label: "Evento" },
      { col:"descripcion", label: "Detalle" },
      { col:"idUsuario", label: "N0mbre Usuario" },
      { col:"accionFecha", label: "Fecha" }
    ];
    let columnaTemp=[];
    for(let c of columsTemp){//incidenciaColumna: {col: "fechaCreado", Desc: true},
      columnaTemp.push({
        content: (
          <div  onClick={() =>  this.sortIncidencias(c.col) } >
            { c.label }
            { col === c.col ? ( <Icon name={asc ? "chevron-down":"chevron-up"} />) : null}
          </div>
        ),
      });
    }
    this.setState({ bitacoratableheader: columnaTemp });
  };
  sortIncidencias = (columna= this.state.incidenciaColumna.col) => {
    let oldCol = this.state.incidenciaColumna.col;
    let AscDescTemp= this.state.incidenciaColumna.Desc;
    if (oldCol === columna) {
      AscDescTemp = !AscDescTemp;
    } else {
      AscDescTemp = true;
    }

    this.setState({ incidenciaColumna: {col: columna, Desc: AscDescTemp} });
    if (oldCol === columna) {
      this.table_headers_incidencias( AscDescTemp, columna );
    } else {
      this.table_headers_incidencias(true, columna);
    }

    var originalArray = [...this.state.incidencias];
    if (!originalArray[0]) return;

    if (AscDescTemp) {
      if (typeof originalArray[0][columna] == "object") {
        originalArray = originalArray.sort(function(a, b) {
          if (a[columna] === null || b[columna] === null) {
            return -1;
          } // si es nulo lo ordena al final
          if (a[columna].nombre < b[columna].nombre) {
            return 1;
          }
          if (a[columna].nombre > b[columna].nombre) {
            return -1;
          }
          return 0;
        });
      } else {
        originalArray = originalArray.sort(function(a, b) {
          if (a[columna] < b[columna]) {
            return 1;
          }
          if (a[columna] > b[columna]) {
            return -1;
          }
          return 0;
        });
      }
      //return a[columna]-b[columna] }); //Asecendente
    } else {
      if (typeof originalArray[0][columna] == "object") {
        originalArray = originalArray.sort(function(a, b) {
          if (a[columna] === null || b[columna] === null) {
            return 1;
          } // si es nulo lo ordena al final
          if (a[columna].nombre > b[columna].nombre) {
            return 1;
          }
          if (a[columna].nombre < b[columna].nombre) {
            return -1;
          }
          return 0;
        });
      } else {
        originalArray = originalArray.sort(function(a, b) {
          if (a[columna] > b[columna]) {
            return 1;
          }
          if (a[columna] < b[columna]) {
            return -1;
          }
          return 0;
        });
      }
    }
    this.setState({ incidencias: originalArray });
  }
/**
 * 
 * @param {*} columna 
 * @returns 
 */
  sortElements = (columna) => {
    let oldCol = this.colToFilter;
    if (this.colToFilter === columna) {
      this.AscDesc = !this.AscDesc;
    } else {
      this.AscDesc = true;
    }
    this.colToFilter = columna;
    this.setState({ colToFilter: columna });
    if (oldCol === columna) {
      this.table_headers(this.AscDesc);
    } else {
      this.table_headers(true);
    }

    var originalArray = this.state.elementos;
    if (!originalArray[0]) {
      return;
    }
    if (this.AscDesc) {
      if (typeof originalArray[0][columna] == "object") {
        originalArray = originalArray.sort(function(a, b) {
          if (a[columna] === null || b[columna] === null) {
            return -1;
          } // si es nulo lo ordena al final
          if (a[columna].nombre > b[columna].nombre) {
            return 1;
          }
          if (a[columna].nombre < b[columna].nombre) {
            return -1;
          }
          return 0;
        });
      } else {
        originalArray = originalArray.sort(function(a, b) {
          if (a[columna] > b[columna]) {
            return 1;
          }
          if (a[columna] < b[columna]) {
            return -1;
          }
          return 0;
        });
      }
      //return a[columna]-b[columna] }); //Asecendente
    } else {
      if (typeof originalArray[0][columna] == "object") {
        originalArray = originalArray.sort(function(a, b) {
          if (a[columna] === null || b[columna] === null) {
            return 1;
          } // si es nulo lo ordena al final
          if (a[columna].nombre < b[columna].nombre) {
            return 1;
          }
          if (a[columna].nombre > b[columna].nombre) {
            return -1;
          }
          return 0;
        });
      } else {
        originalArray = originalArray.sort(function(a, b) {
          if (a[columna] < b[columna]) {
            return 1;
          }
          if (a[columna] > b[columna]) {
            return -1;
          }
          return 0;
        });
      }
      //return b[columna]-a[columna] }); //Descendente
    }
    this.setState({ elementos: originalArray });
  }
  ;
  isobject = () => {
    if (typeof this.columna === "object") {
      if (this.columna.length > 1) {
        for (let item of this.columna) {
          this.colum.push(
            <Table.Col>
              {
                <ModalLayout
                  tamanio={this.tamanio}
                  title="Modificar"
                  name={item["nombre"] + " ..."}
                  hiddenModal={this.state.hiddenModals}
                  Fclose={this.hiddenmodal}
                  formulario={
                    <this.Catalogo_form
                      accion="modificar"
                      elementos={this.fila}
                      getfuntion={this.getfuntion}
                      stor={this.props}
                    />
                  }
                />
              }
            </Table.Col>



          );
          break;
        }
      } else {
        switch (this.campo) {
          case "username":
            this.colum.push(<Table.Col>{this.columna["username"]}</Table.Col>);
            break;
          case "idOtd":
            this.colum.push(
              <Table.Col>
                {timeToMilisegundos(this.registro.delay) > 0 ? (
                  <div
                    className="badge bg-secondary"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={this.columna["nombre"]}
                  >
                    <Icon name="message-square" />
                  </div>
                ) : (
                  ""
                )}
              </Table.Col>
            );
            break;

          default:
            this.colum.push(<Table.Col>{this.columna["nombre"]}</Table.Col>);
            break;
        }
      }
    }
  };

  changePlataformas = (event) => {
    this.setState({ plataforma: event });
  };
  objectFilter = (columna) => {
    if (columna) {
      if (columna.nombre) {
        return columna.nombre;
      } else {
        return columna;
      }
    } else {
      return "";
    }
  };
  getFuncionalidad = (elemento) => {
    if (this.state.elementos.length > 0) {
      let movActivo = this.state.elementos
        .filter( (word) => word.idPlataforma.nombre.includes(this.state.plataforma.search) )
        .sort()[this.state.selectedRow];
      if (movActivo) {
        if (movActivo.idPlataforma) {
          if (this.props.PlataformasPerfiles[movActivo.idPlataforma.id]) {
            let funcionalidadesTemp = this.props.PlataformasPerfiles[
              movActivo.idPlataforma.id
            ].funcionalidades;
            if (!funcionalidadesTemp) {
              return false;
            }
            for (let item of funcionalidadesTemp) {
              if (item.nombre === elemento) {
                return true;
              }
            }
          }
        }
      }
    }
    return false;
  };
  getFuncionalidadGeneral = (elemento) => {
    var BreakException = {};
    var resultado = false;

    try {
      Object.keys(this.props.PlataformasPerfiles).forEach((key, indice) => {
        if (this.props.PlataformasPerfiles[key].funcionalidades) {
          for (let item of this.props.PlataformasPerfiles[key]
            .funcionalidades) {
            if (item.nombre === elemento) {
              resultado = true;
              throw BreakException;
            }
          }
        }
      });
    } catch (e) {
      if (e !== BreakException) throw e;
      return resultado;
    }
    return false;
  };

  getCambiosNav = (movimientoOld, MovimientoUpdated) => {
    console.log(movimientoOld)
    console.log(MovimientoUpdated);
    
    let camposmodificados = "";
    let getCampoMod = "";
    let bitacora = 3;
    if (3 === MovimientoUpdated.idOperacionEstatus.id) {
      bitacora = 1;
      if (camposmodificados.length > 0) {
        camposmodificados = camposmodificados + ",  ";
      }
      camposmodificados = camposmodificados + "( ";
      camposmodificados = camposmodificados + "Se finaliza Movimiento )";
    } //Finalizado

    if (MovimientoUpdated.idRuta) {
      if (movimientoOld.idRuta.id) {
        if (movimientoOld.idRuta.id !== MovimientoUpdated.idRuta.id) {
          bitacora = 8;
          if (camposmodificados.length > 0) {
            camposmodificados = camposmodificados + ",  ";
          }
          camposmodificados = camposmodificados + "( ";
          camposmodificados =
            camposmodificados +
            movimientoOld.idRuta.nombre +
            " a " +
            MovimientoUpdated.idRuta.nombre +
            ")";
        }
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.idRuta.nombre + ")";
      }
    }
    ///////////////////////////////////////////////////////////////////////////////////////77
    if (MovimientoUpdated.idOtd) {
      if (movimientoOld.idOtd.id) {
        if (movimientoOld.idOtd.id !== MovimientoUpdated.idOtd.id) {
          bitacora = 11;
          if (camposmodificados.length > 0) {
            camposmodificados = camposmodificados + ",  ";
          }
          camposmodificados = camposmodificados + "( ";
          camposmodificados =
            camposmodificados +
            movimientoOld.idOtd.nombre +
            " a " +
            MovimientoUpdated.idOtd.nombre +
            ")";
        }
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.idOtd.nombre + ")";
      }
    }
    ///////////////////////////////////////////////////////////////////////////////////////77
    if (MovimientoUpdated.idCliente) {
      if (movimientoOld.idCliente.id) {
        if (movimientoOld.idCliente.id !== MovimientoUpdated.idCliente.id) {
          bitacora = 12;
          if (camposmodificados.length > 0) {
            camposmodificados = camposmodificados + ",  ";
          }
          camposmodificados = camposmodificados + "( ";
          camposmodificados =
            camposmodificados +
            movimientoOld.idCliente.nombre +
            " a " +
            MovimientoUpdated.idCliente.nombre +
            ")";
        }
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.idCliente.nombre + ")";
      }
    }
    ///////////////////////////////////////////////////////////////////////////////////////77
    if (MovimientoUpdated.idMovimientoTipo) {
      if (movimientoOld.idMovimientoTipo.id) {
        if (
          movimientoOld.idMovimientoTipo.id !==
          MovimientoUpdated.idMovimientoTipo.id
        ) {
          bitacora = 14;
          if (camposmodificados.length > 0) {
            camposmodificados = camposmodificados + ",  ";
          }
          camposmodificados = camposmodificados + "( ";
          camposmodificados =
            camposmodificados +
            movimientoOld.idMovimientoTipo.nombre +
            " a " +
            MovimientoUpdated.idMovimientoTipo.nombre +
            ")";
        }
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.idMovimientoTipo.nombre + ")";
      }
    }
    ///////////////////////////////////////////////////////////////////////////////////////77
    if (MovimientoUpdated.idOperacionTipo) {
      if (movimientoOld.idOperacionTipo.id) {
        if (
          movimientoOld.idOperacionTipo.id !==
          MovimientoUpdated.idOperacionTipo.id
        ) {
          bitacora = 15;
          if (camposmodificados.length > 0) {
            camposmodificados = camposmodificados + ",  ";
          }
          camposmodificados = camposmodificados + "( ";
          camposmodificados =
            camposmodificados +
            movimientoOld.idOperacionTipo.nombre +
            " a " +
            MovimientoUpdated.idOperacionTipo.nombre +
            ")";
        }
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.idOperacionTipo.nombre + ")";
      }
    }
    ///////////////////////////////////////////////////////////////////////////////////////77
    if (MovimientoUpdated.idRuta2) {
      if (movimientoOld.idRuta2.id) {
        if (movimientoOld.idRuta2.id !== MovimientoUpdated.idRuta2.id) {
          bitacora = 16;
          if (camposmodificados.length > 0) {
            camposmodificados = camposmodificados + ",  ";
          }
          camposmodificados = camposmodificados + "( ";
          camposmodificados =
            camposmodificados +
            movimientoOld.idRuta2.nombre +
            " a " +
            MovimientoUpdated.idRuta2.nombre +
            ")";
        }
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.idRuta2.nombre + ")";
      }
    }

    if (movimientoOld.citaProgramada !== MovimientoUpdated.citaProgramada) {
      bitacora = 3;
      if (camposmodificados.length > 0) {
        camposmodificados = camposmodificados + ",  ";
      }
      camposmodificados = camposmodificados + "( ";
      if (movimientoOld.citaProgramada) {
        camposmodificados =
          camposmodificados +
          movimientoOld.citaProgramada +
          " a " +
          MovimientoUpdated.citaProgramada +
          ")";
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.citaProgramada + ")";
      }
    }
    if (movimientoOld.sello !== MovimientoUpdated.sello) {
      bitacora = 9;
      if (camposmodificados.length > 0) {
        camposmodificados = camposmodificados + ",  ";
      }
      camposmodificados = camposmodificados + "( ";
      if (movimientoOld.sello) {
        camposmodificados =
          camposmodificados +
          movimientoOld.sello +
          " a " +
          MovimientoUpdated.sello +
          ")";
      } else {
        camposmodificados = camposmodificados + MovimientoUpdated.sello + ")";
      }
    } //No aplica a full
    if (movimientoOld.contenedor !== MovimientoUpdated.contenedor) {
      bitacora = 10;
      if (camposmodificados.length > 0) {
        camposmodificados = camposmodificados + ",  ";
      }
      camposmodificados = camposmodificados + "( ";
      if (movimientoOld.contenedor) {
        camposmodificados =
          camposmodificados +
          movimientoOld.contenedor +
          " a " +
          MovimientoUpdated.contenedor +
          ")";
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.contenedor + ")";
      }
    } //No aplica afull
    if (movimientoOld.actualizarFull && movimientoOld.actualizarFull !== MovimientoUpdated.actualizarFull) {
      bitacora = 13;
      if (camposmodificados.length > 0) {
        camposmodificados = camposmodificados + ",  ";
      }
      camposmodificados = camposmodificados + "( ";
      camposmodificados =
        camposmodificados +
        movimientoOld.actualizarFull +
        " a " +
        MovimientoUpdated.actualizarFull +
        ")";
    }

    if (movimientoOld.idRuta2 !== MovimientoUpdated.idRuta2) {
      bitacora = 16;
      if (camposmodificados.length > 0) {
        camposmodificados = camposmodificados + ",  ";
      }
      camposmodificados = camposmodificados + "( ";
      if (movimientoOld.idRuta2) {
        camposmodificados =
          camposmodificados +
          movimientoOld.idRuta2.nombre +
          " a " +
          MovimientoUpdated.idRuta2.nombre +
          ")";
      } else {
        camposmodificados =
          camposmodificados + MovimientoUpdated.idRuta2.nombre + ")";
      }
    }
    //this.camposLogisticosNav
    this.camposLogisticosNav.map((cLog, index) => {
      switch (cLog.nombreCampo) {
        /**
         * Importacion
         */
        case "arribo_terminal_vacio":
          getCampoMod = this.validarLogisTicos(
            movimientoOld,
            MovimientoUpdated,
            camposmodificados,
            "arriboTerminalVacio",
            cLog.nombre
          );
          if (getCampoMod !== camposmodificados) {
            bitacora = 3;
            camposmodificados = getCampoMod;
          }
          break;
        case "salida_terminal_lleno":
          getCampoMod = this.validarLogisTicos(
            movimientoOld,
            MovimientoUpdated,
            camposmodificados,
            "salidaTerminalLleno",
            cLog.nombre
          );
          if (getCampoMod !== camposmodificados) {
            bitacora = 3;
            camposmodificados = getCampoMod;
          }
          break;
        case "arribo_cliente_lleno":
          getCampoMod = this.validarLogisTicos(
            movimientoOld,
            MovimientoUpdated,
            camposmodificados,
            "arriboClienteLleno",
            cLog.nombre
          );
          if (getCampoMod !== camposmodificados) {
            bitacora = 3;
            camposmodificados = getCampoMod;
          }
          break;
        case "salida_cliente_vacio":
          getCampoMod = this.validarLogisTicos(
            movimientoOld,
            MovimientoUpdated,
            camposmodificados,
            "salidaClienteVacio",
            cLog.nombre
          );
          if (getCampoMod !== camposmodificados) {
            bitacora = 3;
            camposmodificados = getCampoMod;
          }
          break;
        case "arribo_terminal_vacio_2":
          getCampoMod = this.validarLogisTicos(
            movimientoOld,
            MovimientoUpdated,
            camposmodificados,
            "arriboTerminalVacio2",
            cLog.nombre
          );
          if (getCampoMod !== camposmodificados) {
            bitacora = 3;
            camposmodificados = getCampoMod;
          }
          break;
        case "asignacion_piloto":
          getCampoMod = this.validarLogisTicos(
            movimientoOld,
            MovimientoUpdated,
            camposmodificados,
            "asignacionPiloto",
            cLog.nombre
          );
          if (getCampoMod !== camposmodificados) {
            bitacora = 3;
            camposmodificados = getCampoMod;
          }
          break;
        case "inicio_ruta":
          getCampoMod = this.validarLogisTicos(
            movimientoOld,
            MovimientoUpdated,
            camposmodificados,
            "inicioRuta",
            cLog.nombre
          );
          if (getCampoMod !== camposmodificados) {
            bitacora = 3;
            camposmodificados = getCampoMod;
          }
          break;
        case "despacho_predio_cliente":
          getCampoMod = this.validarLogisTicos(
            movimientoOld,
            MovimientoUpdated,
            camposmodificados,
            "despachoPredioCliente",
            cLog.nombre
          );
          if (getCampoMod !== camposmodificados) {
            bitacora = 3;
            camposmodificados = getCampoMod;
          }
          break;
        case "llegada_frontera":
          getCampoMod = this.validarLogisTicos(
            movimientoOld,
            MovimientoUpdated,
            camposmodificados,
            "llegadaFrontera",
            cLog.nombre
          );
          if (getCampoMod !== camposmodificados) {
            bitacora = 3;
            camposmodificados = getCampoMod;
          }
          break;
        case "salida_frontera":
          getCampoMod = this.validarLogisTicos(
            movimientoOld,
            MovimientoUpdated,
            camposmodificados,
            "salidaFrontera",
            cLog.nombre
          );
          if (getCampoMod !== camposmodificados) {
            bitacora = 3;
            camposmodificados = getCampoMod;
          }
          break;
        case "arribo_aduana":
          getCampoMod = this.validarLogisTicos(
            movimientoOld,
            MovimientoUpdated,
            camposmodificados,
            "arriboAduana",
            cLog.nombre
          );
          if (getCampoMod !== camposmodificados) {
            bitacora = 3;
            camposmodificados = getCampoMod;
          }
          break;
        case "salida_aduana":
          getCampoMod = this.validarLogisTicos(
            movimientoOld,
            MovimientoUpdated,
            camposmodificados,
            "salidaAduana",
            cLog.nombre
          );
          if (getCampoMod !== camposmodificados) {
            bitacora = 3;
            camposmodificados = getCampoMod;
          }
          break;
        /**
         * Exportacion
         */
        case "retiro_contenedor":
          getCampoMod = this.validarLogisTicos(
            movimientoOld,
            MovimientoUpdated,
            camposmodificados,
            "retiroContenedor",
            cLog.nombre
          );
          if (getCampoMod !== camposmodificados) {
            bitacora = 3;
            camposmodificados = getCampoMod;
          }
          break;
        case "arribo_cliente_vacio":
          getCampoMod = this.validarLogisTicos(
            movimientoOld,
            MovimientoUpdated,
            camposmodificados,
            "arriboClienteVacio",
            cLog.nombre
          );
          if (getCampoMod !== camposmodificados) {
            bitacora = 3;
            camposmodificados = getCampoMod;
          }
          break;
        case "salida_cliente_lleno":
          getCampoMod = this.validarLogisTicos(
            movimientoOld,
            MovimientoUpdated,
            camposmodificados,
            "salidaClienteLleno",
            cLog.nombre
          );
          if (getCampoMod !== camposmodificados) {
            bitacora = 3;
            camposmodificados = getCampoMod;
          }
          break;
        case "arribo_terminal_cargado":
          getCampoMod = this.validarLogisTicos(
            movimientoOld,
            MovimientoUpdated,
            camposmodificados,
            "arriboTerminalCargado",
            cLog.nombre
          );
          if (getCampoMod !== camposmodificados) {
            bitacora = 3;
            camposmodificados = getCampoMod;
          }
          break;
        case "salida_predio_vacio":
          getCampoMod = this.validarLogisTicos(
            movimientoOld,
            MovimientoUpdated,
            camposmodificados,
            "salidaPredioVacio",
            cLog.nombre
          );
          if (getCampoMod !== camposmodificados) {
            bitacora = 3;
            camposmodificados = getCampoMod;
          }
          break;
        case "cita_fecha_despacho":
          getCampoMod = this.validarLogisTicos(
            movimientoOld,
            MovimientoUpdated,
            camposmodificados,
            "citaFechaDespacho",
            cLog.nombre
          );
          if (getCampoMod !== camposmodificados) {
            bitacora = 3;
            camposmodificados = getCampoMod;
          }
          break;
        case "entrada_ciudad_portuaria":
          getCampoMod = this.validarLogisTicos(
            movimientoOld,
            MovimientoUpdated,
            camposmodificados,
            "entradaCiudadPortuaria",
            cLog.nombre
          );
          if (getCampoMod !== camposmodificados) {
            bitacora = 3;
            camposmodificados = getCampoMod;
          }
          break;
        case "arribo_patio_linea":
          getCampoMod = this.validarLogisTicos(
            movimientoOld,
            MovimientoUpdated,
            camposmodificados,
            "arriboPatioLinea",
            cLog.nombre
          );
          if (getCampoMod !== camposmodificados) {
            bitacora = 3;
            camposmodificados = getCampoMod;
          }
          break;
        case "salida_terminal_vacio":
          getCampoMod = this.validarLogisTicos(
            movimientoOld,
            MovimientoUpdated,
            camposmodificados,
            "salidaTerminalVacio",
            cLog.nombre
          );
          if (getCampoMod !== camposmodificados) {
            bitacora = 3;
            camposmodificados = getCampoMod;
          }
          break;
        default:
          break;
      }
      return cLog;
    });

    return { bitacora: bitacora, camposmodificados: camposmodificados };
  };
  validarLogisTicos = (
    movimientoOld,
    MovimientoUpdated,
    camposmodificados,
    campo,
    labelLog
  ) => {
    let camposmodificadosTemp = camposmodificados;
    if (movimientoOld[campo] !== MovimientoUpdated[campo]) {
      if (camposmodificadosTemp.length > 0) {
        camposmodificadosTemp = camposmodificadosTemp + ",  ";
      }
      camposmodificadosTemp = camposmodificadosTemp + "(  " + labelLog + ": ";
      if (movimientoOld[campo]) {
        camposmodificadosTemp =
          camposmodificadosTemp +
          movimientoOld[campo] +
          " a " +
          MovimientoUpdated[campo] +
          " )";
      } else {
        camposmodificadosTemp =
          camposmodificadosTemp + MovimientoUpdated[campo] + " )";
      }
    }
    return camposmodificadosTemp;
  };
  crud = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = ""
  ) => {
    return Crud_Catalogos(
      catalogo,
      "movimientos",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            if (stateVar === "camposLogisticos") {
              return returnVal;
            }
            if( stateVar === "remolquesTipo"){
              for(let r of returnVal){
                this.remolques[r.idRemolque]= r;
              }
            }
            if (stateVar === "urlGps") {
              window.open(
                returnVal.url,
                "sharer",
                "width=900,height=600,scrollbars=NO"
              );
            }
            if (stateVar === "entregas") {
              this.setState({ loadingEntregas: false });
              if (returnVal) {
                this.setState({ entregas: returnVal });
              } else {
                this.setState({ entregas: [] });
              }
              return returnVal;
            }
            if (stateVar === "informes") {
              let informesTemp = {};
              for (let item of returnVal) {
                if (item.id === 3) {
                  //  3 equipos vacios
                  informesTemp[item.id] = item;
                  this.crud(
                    "get",
                    "",
                    "",
                    "funcionalidades/informe/" + item.id,
                    "funcionalidades3"
                  );
                }
                if (item.id === 4) {
                  //  4 Exportar Reporte AD-HOC C4
                  informesTemp[item.id] = item;
                  this.crud(
                    "get",
                    "",
                    "",
                    "funcionalidades/informe/" + item.id,
                    "funcionalidades4"
                  );
                }
                if (item.id === 69) {
                  //  4 Exportar Reporte AD-HOC C4
                  informesTemp[item.id] = item;
                  this.crud(
                    "get",
                    "",
                    "",
                    "funcionalidades/informe/" + item.id,
                    "funcionalidades69"
                  );
                }
                if (item.id === 70) {
                  //  4 Exportar Reporte AD-HOC C4
                  informesTemp[item.id] = item;
                  this.crud(
                    "get",
                    "",
                    "",
                    "funcionalidades/informe/" + item.id,
                    "funcionalidades70"
                  );
                }
              }
              this.setState({ informes: informesTemp });
            }
            if (stateVar === "funcionalidades3" && returnVal) {
              this.setState({ informe3: returnVal });
              let movsTemp = [];
              this.state.elementos.map((m, index) => {
                movsTemp[index] = {};
                for (let item of returnVal) {
                  movsTemp[index][item.label] = m[item.nombre];
                }
                return m;
              });
              this.setState({ reporte3: movsTemp });
            }
            if (stateVar === "funcionalidades4" && returnVal) {
              this.setState({ informe4: returnVal });
              let movsTemp = [];
              this.state.elementos.map((m, index) => {
                movsTemp[index] = {};
                for (let item of returnVal) {
                  movsTemp[index][item.label] = m[item.nombre];
                }
                return m;
              });
              this.setState({ reporte4: movsTemp });
            }
            if (stateVar === "funcionalidades69" && returnVal) {
              this.setState({ informe69: returnVal });
              let movsTemp = [];
              this.crud(
                "get",
                [],
                "",
                "navieraMovimientos/movimientosImportacionSemanal_c4/" +
                  new Date().getFullYear() +
                  "/" +
                  new Date().getWeekNumber(),
                "data"
              ).then((movs) => {
                if (movs) {
                  movs.map((m, index) => {
                    movsTemp[index] = {};
                    for (let item of returnVal) {
                      movsTemp[index][item.label] = m[item.nombre];
                    }
                    return m;
                  });
                  this.setState({ reporte69: movsTemp });
                }
              });
            }
            if (stateVar === "funcionalidades70" && returnVal) {
              this.setState({ informe70: returnVal });
              let movsTemp = [];
              this.crud(
                "get",
                [],
                "",
                "navieraMovimientos/movimientosExportacionSemanal_c4/" +
                  new Date().getFullYear() +
                  "/" +
                  new Date().getWeekNumber(),
                "data"
              ).then((movs) => {
                if (movs) {
                  movs.map((m, index) => {
                    movsTemp[index] = {};
                    for (let item of returnVal) {
                      movsTemp[index][item.label] = m[item.nombre];
                    }
                    return m;
                  });
                  this.setState({ reporte70: movsTemp });
                }
              });
            }
            return returnVal;
          case "post":
            if (stateVar === "serviceFailure") {
              this.hiddenmodal();
              return returnVal;
            }
            break;
          case "put":
            if (stateVar === "updateFechas") {
              this.hiddenmodal();
              let movsTemp = [...this.state.elementos];
              for (let i = 0; i < movsTemp.length; i++) {
                if (movsTemp[i].id === returnVal.id) {
                  movsTemp[i].contenedor = returnVal.contenedor;
                  movsTemp[i].sello = returnVal.sello;
                  this.setState({ elementos: movsTemp });
                  this.movsReport = movsTemp;
                  break;
                }
              }
              NotificationManager.info(
                "Se ha actualizado mov: " + returnVal.id,
                "Actualizacion"
              );
              let cambios = this.getCambiosNav(id, returnVal); //id= old Movimiento, retunVal= Actualizado // {bitacora: bitacora , camposmodificados: camposmodificados }
              this.usuariosLogObject.idBitacoraMonitoreo = cambios.bitacora;
              this.usuariosLogObject.idUsuario = user.id;
              this.usuariosLogObject.idMovimiento = returnVal.id;
              this.usuariosLogObject.idPlataformaTipo = 1;
              this.usuariosLogObject.accionFecha = myFormato(new Date());
              this.usuariosLogObject.descripcion = cambios.camposmodificados; //en id vienen los campos modificados
              this.crud(
                "get",
                "",
                "",
                "camposLogisticos/plataforma/" +
                  returnVal.idPlataforma.id +
                  "/mtipo/" +
                  returnVal.idMovimientoTipo.id,
                "camposLogisticos"
              ).then((returnVal1) => {
                this.camposLogisticosNav = returnVal1;
                Crud_Catalogos(
                  "usuariosLog",
                  catalogo,
                  "post",
                  "",
                  this.usuariosLogObject
                );
              });
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        if (stateVar === "entregas") {
          this.setState({ loadingEntregas: false });
        }
        console.log(err);
      });
  };
  buscar = () => {
    //@GetMapping("/usuario_personalizado/{idUsuario}/fecha1/{fecha1}/fecha2/{fecha2}")/
    let movimientosBy = 0;
    this.setState({ elementos: [] });
    this.movsReport = [];
    this.props.setMovimientosNavieros([]);
    if (window.location.host.includes("skyone.skyangel.com.mx") || window.location.host.includes("localhost:3000")) {
      movimientosBy = currentUser[0].perfil.movimientosBy;
    } else {
      for (let s of subDominios) {
        if (window.location.host.includes(s.dominio)) {
          for (let cUser of currentUser) {
            if (cUser.plataforma.nombre === s.plataforma) {
              this.crud(
                "get",
                "",
                "",
                "informes/perfil/" + cUser.perfil.id,
                "informes"
              );
              movimientosBy = cUser.perfil.movimientosBy;
              break;
            }
          }
        }
      }
    }
    if (movimientosBy === 1) {//821 por cita, 738  por fecha creacion
      //perfil
      this.getfuntion(
        "get",
        [],
        "",
        [],
        "navieraMovimientosPerfil/personalizado_finalizados/movimiento_tipo/"
          + this.props.mtipo
          + "/fecha1/"
          + this.state.fecha1
          + " 00:00:00/fecha2/"
          + this.state.fecha2
          + " 23:59:59",
        this.props.movimientos_naviera_update
      );
      /*
      switch (this.props.mtipo) {
        case 0:
          this.getfuntion(
            "get",
            [],
            "",
            [],
            "navieraMovimientosPerfil/usuario_personalizado/" +
              user.id +
              "/fecha1/" +
              this.state.fecha1 +
              " 00:00:00/fecha2/" +
              this.state.fecha2 +
              " 23:59:59",
            this.props.movimientos_naviera_update
          );
          break;
        default:
          this.getfuntion(
            "get",
            [],
            "",
            [],
            "navieraMovimientosPerfil/personalizado_finalizados/" +
              user.id +
              "/" +
              this.props.mtipo +
              "/fecha1/" +
              this.state.fecha1 +
              " 00:00:00/fecha2/" +
              this.state.fecha2 +
              " 23:59:59",
            this.props.movimientos_naviera_update
          );
          break;
      }*/
    }
    if (movimientosBy === 2) {
      //corredores
      switch (this.props.mtipo) {
        case 0:
          this.getfuntion(
            "get",
            [],
            "",
            [],
            "navieraMovimientosPerfil/usuario/" +
              user.id +
              "/fecha1/" +
              this.state.fecha1 +
              " 00:00:00/fecha2/" +
              this.state.fecha2 +
              " 23:59:59",
            this.props.movimientos_naviera_update
          );
          break;
        default:
          this.getfuntion(
            "get",
            [],
            "",
            [],
            "navieraMovimientosPerfil/usuario/" +
              user.id +
              "/" +
              this.props.mtipo +
              "/fecha1/" +
              this.state.fecha1 +
              " 00:00:00/fecha2/" +
              this.state.fecha2 +
              " 23:59:59",
            this.props.movimientos_naviera_update
          );
          break;
      }
    }
    if (movimientosBy === 3) {
      //transportistas
      switch (this.props.mtipo) {
        case 0:
          this.getfuntion(
            "get",
            [],
            "",
            [],
            "navieraMovimientosPerfil/usuario_transportista/" +
              user.id +
              "/fecha1/" +
              this.state.fecha1 +
              " 00:00:00/fecha2/" +
              this.state.fecha2 +
              " 23:59:59",
            this.props.movimientos_naviera_update
          );
          break;
        default:
          this.getfuntion(
            "get",
            [],
            "",
            [],
            "navieraMovimientosPerfil/usuario_transportista/" +
              user.id +
              "/" +
              this.props.mtipo +
              "/fecha1/" +
              this.state.fecha1 +
              " 00:00:00/fecha2/" +
              this.state.fecha2 +
              " 23:59:59",
            this.props.movimientos_naviera_update
          );
          break;
      }
    }
  };
  onChangeFecha1 = (date) => this.setState({ fecha1: date.target.value });
  onChangeFecha2 = (date) => this.setState({ fecha2: date.target.value });

  changeActivos = (event) => {
    this.setState({ activos: event.target.checked });
  };

  tracking = (mov, gps) => {
    this.crud(
      "get",
      "",
      "",
      "navieraMovimientos/plataforma/" +
        mov.idPlataforma.idGg +
        "/gps/" +
        gps.idGg,
      "urlGps"
    );
  };

  hideNav = () => {
    this.setState((prev) => ({
      Navigator: !prev.Navigator,
    }));
  };

  getEntregas = () => {
    if (this.state.movimiento.id) {
      this.setState({ loadingEntregas: true });
      this.crud(
        "get",
        "",
        "",
        "entregas_navieras/movimiento/" + this.state.movimiento.id,
        "entregas"
      );
    }
  };
  render() {
    return (
      <div>
      {/*console.log(currentUser[0].perfil.id)*/}
        <div
          className={`${classes.jumb}`}
          style={{ marginRight: "0px", marginLeft: "0px" }}
        >
          <div className="row form-row align-items-center ">
            <div class="col-2">
              <Select
                value={this.state.plataforma}
                onChange={this.changePlataformas}
                options={this.plataformas}
              />
            </div>
            {this.getFuncionalidadGeneral("Exportar") ? (
              <div class="col-auto">
                <Exportar
                  crud={this.getfuntion}
                  catalogo={this.catalogo}
                  filtro={""}
                  data={this.state.movsReport}
                  title={"Reporte general"}
                />
              </div>
            ):null}
            {this.getFuncionalidadGeneral("Exportar") &&
              this.state.excelRender &&
              this.state.informes[3] ? (
                <div class="col-auto">
                  <Exportar
                    crud={this.getfuntion}
                    catalogo={this.catalogo}
                    filtro={"/usuario/" + user.id}
                    data={this.state.reporte3}
                    title={this.state.informes[3].nombre}
                  />
                </div>
              ):null}
            {this.getFuncionalidadGeneral("Exportar") &&
              this.state.excelRender &&
              this.state.informes[4] ? (
                <div class="col-auto">
                  <Exportar
                    crud={this.getfuntion}
                    catalogo={this.catalogo}
                    filtro={"/usuario/" + user.id}
                    data={this.state.reporte3}
                    title={this.state.informes[3].nombre}
                  />
                </div>
              ):null}
            {this.getFuncionalidadGeneral("Exportar") &&
              this.state.excelRender &&
              this.state.informes[69] ?(
                <div class="col-auto">
                  <Exportar
                    crud={this.getfuntion}
                    catalogo={this.catalogo}
                    filtro={"/usuario/" + user.id}
                    data={this.state.reporte69}
                    title={this.state.informes[69].nombre}
                  />
                </div>
              ): null}
            {this.getFuncionalidadGeneral("Exportar") &&
              this.state.excelRender &&
              this.state.informes[70] ?(
                <div class="col-auto">
                  <Exportar
                    crud={this.getfuntion}
                    catalogo={this.catalogo}
                    filtro={"/usuario/" + user.id}
                    data={this.state.reporte70}
                    title={this.state.informes[70].nombre}
                  />
                </div>
              ):null}
            <div className="col-auto">
              <div className="form-check form-switch">
                <Form.Switch
                  type="Checkbox"
                  checked={this.state.activos}
                  onChange={this.changeActivos}
                />
                <label
                  className="form-check-label"
                  for="flexSwitchCheckChecked"
                >
                  {this.state.activos
                    ? "Movimientos Activos"
                    : "Movimientos Finalizados"}
                </label>
              </div>
            </div>
            <div hidden={!this.state.movimiento.idPlataforma || ![952].includes(this.state.movimiento.idPlataforma.id)}>
              <ModalLayout
                title={"Reporte de incidencias"}
                name={<i class="fa fa-file-excel-o" data-toggle="tooltip"
                  data-placement="top" title="Repoete de incidencias"
                  style={{"font-size":" 24px"}}
                  onClick={  
                    () => {                 
                      this.setState({ incidenciasReporte: []});
                      if(this.state.activos){
                        Crud_Catalogos(
                          "movimientoIncidencias/personalizado"+(this.props.mtipo?("/movimiento_tipo/" + this.props.mtipo): "")
                          ,""
                          ,"get"
                          ,""
                          ,[],"","","",[]).then((returnVal)=>{                                                
                            this.setState({ incidenciasReporte: returnVal});
                        }).catch(err =>{ console.log(err);});
                      }else{
                        if(this.state.elementos.length){
                          Crud_Catalogos(
                            "movimientoIncidencias/personalizado_finalizados"
                            +"/movimiento_tipo/" + this.props.mtipo
                            + "/fecha1/" + this.state.fecha1
                            + " 00:00:00/fecha2/"
                            + this.state.fecha2
                            + " 23:59:59"
                            ,""
                            ,"get"
                            ,""
                            ,[],"","","",[]).then((returnVal)=>{                                                
                              this.setState({ incidenciasReporte: returnVal});
                          }).catch(err =>{ console.log(err);});
                        }
                      }
                    }
                  }> Incidencias</i>}
                hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
                formulario={
                  <TablePageable
                    titulo={<h3> { this.state.activos
                      ?"Reporte de incidencias Movimientos activos"
                      :"Reporte de incidencias Movimientos Finalizados"
                    } </h3>}
                    lista={ this.state.incidenciasReporte }
                  columnas={
                    [
                      { columna: "id", label: "Id" },
                      { columna: "idMovimiento", label: "idMovimiento" },
                      { columna: "fechaCreado", label: "fecha" },
                      { columna: "incidencia.nombre", label: "Incidencia" },
                      { columna: "incidencia.incidenciaTipo.nombre", label: "Tipo" },
                      { columna: "comentario", label: "Comentario" },
                    ]
                  }
                  id="id"
                  //selected={(e) => this.setState({ movimiento: e}) }
                  csvname={ this.state.activos
                            ?"Reporte de incidencias Movs activos"
                            :"Reporte de incidencias Movs Finalizados"
                          }
                />                              
                }
              />
            </div>
            {!this.state.activos ? (
              <div class="col-auto">
                <span className="float-right ml-2">
                  <Button
                    pill
                    size="sm"
                    outline
                    color="primary"
                    onClick={() => this.buscar()}
                  >
                    <Icon name="refresh-cw" />
                  </Button>


                </span>
                <span className="float-right ml-2" style={{ width: "35%" }}>
                  <input
                    type="date"
                    id="start"
                    name="trip-start"
                    value={this.state.fecha2}
                    onChange={this.onChangeFecha2}
                  />
                </span>
                <span className="float-right ml-2"> - </span>
                <span className="float-right ml-2" style={{ width: "35%" }}>
                  <input
                    type="date"
                    id="start"
                    name="trip-start"
                    value={this.state.fecha1}
                    onChange={this.onChangeFecha1}
                  />
                </span>
              </div>   
            ):null}
            <div hidden={  [13323, 53607].includes(user.id)  || !this.state.movimiento.id}>
                <ModalLayout
                  title={"Incidencias del movimiento "+ this.state.movimiento.id}
                  name={<Button pill size="sm" outline color="primary" 
                  onClick={  
                          () => {                 
                            let contador=0;
                            Crud_Catalogos("movimientoIncidencias/movimiento/"+this.state.movimiento.id,"","get","",[],"","","",[]).then((returnVal)=>{                                                
                              this.setState({incidencias: returnVal});
                              setTimeout(() => {
                                this.table_headers_incidencias(true, "fechaCreado");
                                this.sortIncidencias("fechaCreado");
                              }, 100);
                            }).catch(err =>{ console.log(err);});
                            }
                    } ><Icon name="list" />{"Incidencias mov ("+ this.state.movimiento.id+")"}</Button>}
                  hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
                  formulario={
                    <Dimmer active={this.state.loadingEntregas} loader>
                      <Table cards={false} striped={false} responsive={true} className="table-vcenter" style={{color:"black", "font-size": "80%"}} > {/** headerItems={ this.state.incidenciatableheader} */}
                        <Table.Header>
                          {this.state.incidenciatableheader.map((item, i) =>
                              <Table.ColHeader key={i}>
                                {item.content}
                              </Table.ColHeader>)}
                        </Table.Header>{/* incidenciaColumna* */}
                        <Table.Body>
                          {this.state.incidencias&&//se ocultan incidencias de gps(7) ejp: Gps reportando sin velocidad
                          this.state.incidencias.filter(i=> ![7].includes(i.incidencia.incidenciaTipo.id)).map((e, index) =>
                              <Table.Row >
                                <Table.Col>{e['fechaCreado']}</Table.Col>
                                <Table.Col>{e.incidencia.nombre}</Table.Col>
                                <Table.Col>{e['comentario']}</Table.Col>
                                                                                  
                              </Table.Row>
                            )}
                        </Table.Body>
                      </Table>
                    </Dimmer>                                    
                  }
                />
                
            </div>

            <div hidden={this.state.movimiento.idPlataforma?.id != 1 || !this.state.movimiento.id}>
                <ModalLayout
                  title={"Bitacora del movimiento "+ this.state.movimiento.id}
                  name={<Button pill size="sm" outline color="primary" 
                  onClick={  
                          () => {                 
                            let contador=0;
                            Crud_Catalogos("usuariosLog/idmovimiento/"+this.state.movimiento.id + "/plataformatipo/1/","","get","",[],"","","",[]).then((returnVal)=>{                                                
                              this.setState({bitacora: returnVal});
                              setTimeout(() => {
                                this.table_headers_bitacora(true, "fechaCreado");
                                //this.sortIncidencias("fechaCreado");
                              }, 100);
                            }).catch(err =>{ console.log(err);});
                            }
                    } ><Icon name="list" />{"Bitacora del  mov ("+ this.state.movimiento.id+")"}</Button>}
                  hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
                  formulario={
                    <Dimmer active={this.state.loadingEntregas} loader>
                      <Table cards={false} striped={false} responsive={true} className="table-vcenter" style={{color:"black", "font-size": "80%"}} > {/** headerItems={ this.state.incidenciatableheader} */}
                        <Table.Header>
                          {this.state.bitacoratableheader.map((item, i) =>
                              <Table.ColHeader key={i}>
                                {item.content}
                              </Table.ColHeader>)}
                        </Table.Header>{/* incidenciaColumna* */}
                        <Table.Body>
                          {
                         
                         this.state.bitacora&&//se ocultan bitacora de gps(7) ejp: Gps reportando sin velocidad
                          this.state.bitacora.map((e, index) =>
                              <Table.Row >
                                <Table.Col>{e.idBitacoraMonitoreo}</Table.Col>
                                <Table.Col>{e.descripcion}</Table.Col>
                                <Table.Col>{e.idUsuario}</Table.Col>
                                <Table.Col>{e.accionFecha}</Table.Col>
                                                                                  
                              </Table.Row>
                            )}
                          
                        </Table.Body>
                      </Table>
                    </Dimmer>                                    
                  }
                />
                
            </div>
            {this.state.movimiento.idPlataforma &&
              this.state.movimiento.idPlataforma.plataformaTipo.id === 3 ? (
                <div class="col-auto">
                  <span className="float-right ml-4">
                    <ModalLayout
                      title={"Entregas del mov: " + this.state.movimiento.id}
                      name={
                        <Button
                          pill
                          size="sm"
                          outline
                          color="primary"
                          onClick={() => this.getEntregas()}
                        >
                          <Icon name="list" />
                          Entregas
                        </Button>
                      }
                      hiddenModal={this.state.hiddenModals}
                      Fclose={this.hiddenmodal}
                      formulario={
                        <Dimmer active={this.state.loadingEntregas} loader>
                          <Table
                            cards={false}
                            striped={false}
                            responsive={true}
                            className="table-vcenter"
                            headerItems={[
                              { content: "idEntrega" },
                              { content: "idMovimiento" },
                              { content: "destino" },
                              { content: "direccion" },
                              { content: "fechaArribo" },
                              { content: "fechaSalida" },
                              { content: "latitud" },
                              { content: "longitud" },
                            ]}
                            style={{
                              color: "black",
                              "font-size": "80%",
                            }}
                          >
                            <Table.Header />
                            <Table.Body>
                              {this.state.entregas.sort().map((e, index) => (
                                <Table.Row>
                                  <Table.Col>{e["idEntrega"]}</Table.Col>
                                  <Table.Col>{e["idMovimiento"]}</Table.Col>
                                  <Table.Col>{e["destino"]}</Table.Col>
                                  <Table.Col>{e["direccion"]}</Table.Col>
                                  <Table.Col>{e["fechaArribo"]}</Table.Col>
                                  <Table.Col>{e["fechaSalida"]}</Table.Col>
                                  <Table.Col>{e["latitud"]}</Table.Col>
                                  <Table.Col>{e["longitud"]}</Table.Col>
                                </Table.Row>
                              ))}
                            </Table.Body>
                          </Table>
                        </Dimmer>
                      }
                    />

                    
                  </span>
                </div>
              ): null}

            {window.location.host.includes("intermodalmaersk.com") ? (
              <div class="col-auto">
                <Button
                  pill
                  size="sm"
                  outline
                  color="primary"
                  onClick={() =>
                    (window.location.href =
                      "https://app.powerbi.com/view?r=eyJrIjoiM2FjMjA3NjAtY2I0NC00NWVmLTk3YjMtYjZjY2FjOWRiNWVhIiwidCI6IjNmNjdkMmVjLWVlM2YtNDJiZS1iMDBmLWY2NjgyNDZiOGFkZiJ9")
                  }
                >
                  <Icon name="activity" />
                  Reporte OTD
                </Button>
              </div>
            ):null}
            {user.id === 6584 ? (
              <div class="col-auto">
                <Button
                  pill
                  size="sm"
                  outline
                  color="primary"
                  onClick={() =>
                    (window.location.href =
                      "https://app.powerbi.com/view?r=eyJrIjoiZGNlOGUwYTQtYWM1Mi00MjE4LWEzZGItZGUwZWQ4NWU4MDI1IiwidCI6IjNmNjdkMmVjLWVlM2YtNDJiZS1iMDBmLWY2NjgyNDZiOGFkZiJ9")
                  }
                >
                  <Icon name="activity" />
                  Dashboard Aceros Guatemala
                </Button>
              </div>
            ):null}
            {[281, 6].includes(user.id) ? (
              <div class="col-auto">
                <Button
                  pill
                  size="sm"
                  outline
                  color="primary"
                  onClick={() =>
                    (window.location.href =
                      //"https://app.powerbi.com/view?r=eyJrIjoiNTA4ODZlNTEtNDlkMS00ZDhlLWE5ZjItMjUxZTkyYzNjMzZlIiwidCI6ImFiM2FmMjJkLWI4ZjEtNGE1Yy05M2NhLWNiMDVkZGE0OGFjZCJ9"
                      "https://app.powerbi.com/view?r=eyJrIjoiOTRjZmEyNjQtZmIxZC00OTkxLWE5YmYtYmNjMDI2YjAzNmQzIiwidCI6ImFiM2FmMjJkLWI4ZjEtNGE1Yy05M2NhLWNiMDVkZGE0OGFjZCJ9"
                    )
                  }
                >
                  <Icon name="activity" />
                  KPI'S CMA
                </Button>
              </div>
            ): null}
            { this.plataformas
              .filter(p=> [3,94,100,556,688].includes(p.value)).length && user.id !== 281 ? (//cma 3,94,100,556,688
              <div class="col-auto">
                <Button
                  pill
                  size="sm"
                  outline
                  color="primary"
                  onClick={() =>
                    (window.location.href =
                      "https://app.powerbi.com/view?r=eyJrIjoiOTlhMTFhMGEtZTU0Mi00OWE1LTk3ZDctZDYxOWM0NjdhMTZlIiwidCI6ImFiM2FmMjJkLWI4ZjEtNGE1Yy05M2NhLWNiMDVkZGE0OGFjZCJ9")
                  }
                >
                  <Icon name="activity" />
                  KPI'S CMA
                </Button>
              </div>
            ):null}
            { this.plataformas//226 Sagot
              .filter(p=> [61, 226].includes(p.value)).length && user.id !== 281 ? (//cma 3,94,100,556,688
              <div class="col-auto">
                <Button
                  pill
                  size="sm"
                  outline
                  color="primary"
                  onClick={() =>
                    (window.location.href =
                      "https://app.powerbi.com/view?r=eyJrIjoiY2UwZTU5ODItYzdlMS00M2JlLTlhYmItMzVkN2IzYzdkNjMyIiwidCI6ImFiM2FmMjJkLWI4ZjEtNGE1Yy05M2NhLWNiMDVkZGE0OGFjZCJ9")
                  }
                >
                  <Icon name="activity" />
                  Dashboard SAGOT
                </Button>
              </div>
            ):null}
            <ul>
              { this.plataformas.filter(p=> [1].includes(p.value)).length ? (
                <li
                  className="badge"
                  onClick={() => window.open(
                    "https://app.powerbi.com/view?r=eyJrIjoiZmNiMjUwN2UtY2M3Yy00ZDEwLWE0NjAtN2U3NzhiZjExMTUxIiwidCI6ImFiM2FmMjJkLWI4ZjEtNGE1Yy05M2NhLWNiMDVkZGE0OGFjZCJ9",
                    "sharer",
                    "width=900,height=600,scrollbars=NO"
                  )
                }
                  data-toggle="tooltip"
                  data-placement="top"
                  title={"Maersk Volumen de servicios"}
                >
                  <i className="fa fa-bar-chart" style={{ fontSize: "24px", color: "#60b5d7"}}></i>
                </li>
              ):null}
            </ul>
            { this.plataformas.filter(p=> [1].includes(p.value)).length ? (
              <div class="col-auto">
              <ModalLayout                
                name={
                      <Button pill size="sm" outline color="primary">
                        <Icon name="list" />{"Descarga Incidencias"}
                      </Button>
                      }
                //hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
                formulario={<IncidenciasFiltro/>}
                //datosForm={{}}
              />
            </div>
            ) : null}              
          </div>
        </div>


        <div className="bg-light"  style={{ height: "100%"}}>
          <TablePageable
            titulo={<h3> Movimientos </h3>}
            lista={ this.state.elementos.filter(m=> this.state.plataforma.value?  m.idPlataforma.id === this.state.plataforma.value : true ) }
            columnas={ this.obtenerColumnas()
              /*[ Modificar  para acciones
                { 
                  columna: "id",
                  label: "Id",
                  //enClick:(e) => setCurrentMovimiento(e), //enClickDisabled: (e)=> false,//e.idSolicitud === 303? false: true,
                  //form: (e)=> <i className="fa fa-edit" style={{"font-size": "24px"}}><p style={{fontSize: "16px"}}>{e.idSolicitud}</p></i>
                },
                { columna: "idMovimiento", label: "idMovimiento" },
                { columna: "po", label: "Po", splitBy:";" },
                { columna: "contenedor", label: "Contenedor", splitBy:";" },
                { columna: "contenedorTipo", label: "Tipo contenedor" },
                { columna: "contenedorSize", label: "Tamaño de Contenedor" },
                { columna: "idOperacionTipo.nombre", label: "Tipo de operacion" },
                { columna: "idMovimientoTipo.nombre", label: "Tipotipo de movimiento" },
                { columna: "idTransportista.nombre", label: "Transportista" },
                { columna: "idCliente.nombre", label: "Cliente" },
              ]*/
            }
            id="id"
            selected={(e) => this.setState({ movimiento: e}) }
            csvname="Solicitudes"
            //resaltarFuncion={(e)=> !e.idMovimiento? true: false}
            //resaltarColor={"rgba(223, 58, 58, 0.47)"}
          />
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    movimientos_naviera_object:
      state.catalogos_reducer.movimientos_naviera_object,
    movimientosTipo_object: state.catalogos_reducer.movimientosTipo_object,
    clientes_object: state.catalogos_reducer.clientes_object,
    navieras_object: state.catalogos_reducer.navieras_object,
    operacionesEstatus_object:
      state.catalogos_reducer.operacionesEstatus_object,
    operacionesTipo_object: state.catalogos_reducer.operacionesTipo_object,
    otd_object: state.catalogos_reducer.otd_object,
    plataformas_object: state.catalogos_reducer.plataformas_object,
    rutasEstatus_object: state.catalogos_reducer.rutasEstatus_object,
    transportistas_object: state.catalogos_reducer.transportistas_object,
    corredores_object: state.catalogos_reducer.corredores_object,
    funcionalidades_object: state.catalogos_reducer.funcionalidades_object,
    privilegios_object: state.catalogos_reducer.privilegios_object,
    operadores_object: state.catalogos_reducer.operadores_object,
    updateStatusMensaje_Object: state.catalogos_reducer.update_mensaje,
    etiquetas_object: state.catalogos_reducer.etiquetas_object,
    serviceFailure_object: state.catalogos_reducer.serviceFailure_object,
  };
}
function mapDispachToProps(dispach) {
  return {
    movimientos_naviera_update: (elementos) =>
      dispach(ACTIONS.elementos_movimientos_naviera_imput(elementos)),
    updateStatusMensaje: (status) => dispach(ACTIONS.update_mensaje(status)),
    movimientosTipo_update: (elementos) =>
      dispach(ACTIONS.elementos_movimientosTipo_imput(elementos)),
    clientes_update: (elementos) =>
      dispach(ACTIONS.elementos_clientes_imput(elementos)),
    navieras_update: (elementos) =>
      dispach(ACTIONS.elementos_navieras_imput(elementos)),
    operacionesEstatus_update: (elementos) =>
      dispach(ACTIONS.elementos_operacionesEstatus_imput(elementos)),
    operacionesTipo_update: (elementos) =>
      dispach(ACTIONS.elementos_operacionestipo_imput(elementos)),
    otd_update: (elementos) => dispach(ACTIONS.elementos_otd_imput(elementos)),
    plataformas_update: (elementos) =>
      dispach(ACTIONS.elementos_plataformas_imput(elementos)),
    rutasEstatus_update: (elementos) =>
      dispach(ACTIONS.elementos_rutasEstatus_imput(elementos)),
    transportistas_update: (elementos) =>
      dispach(ACTIONS.elementos_transportistas_imput(elementos)),
    corredores_update: (elementos) =>
      dispach(ACTIONS.elementos_corredores_imput(elementos)),
    funcionalidades_update: (elementos) =>
      dispach(ACTIONS.elementos_funcionalidades_imput(elementos)),
    privilegios_update: (elementos) =>
      dispach(ACTIONS.elementos_privilegios_imput(elementos)),
    operadores_update: (elementos) =>
      dispach(ACTIONS.elementos_operadores_imput(elementos)),
    etiquetas_update: (elementos) =>
      dispach(ACTIONS.elementos_etiquetas_imput(elementos)),
    serviceFaulure_update: (elementos) =>
      dispach(ACTIONS.elementos_servicefailure_imput(elementos)),
  };
}
export default connect(
  mapStateToProps,
  mapDispachToProps
)(Catalogo);
