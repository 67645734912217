// @flow

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import rootReducer from './store/reducers';
import { createStore } from 'redux';

import "./index.css";
import "./c3jscustom.css";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';

import App from "./App.react";

let store =createStore(rootReducer)

const rootElement = document.getElementById("root");

if (rootElement) {
  ReactDOM.render(
    <Provider store= { store }>
      <App />
    </Provider>,
    rootElement);
} else {
  throw new Error("Could not find root element to mount to!");
}
