import * as conf from "../../config/config";
import axios from 'axios';
//import $ from "jquery";
var user= JSON.parse(sessionStorage.getItem("usuario"));
var movimientos= {};
var cat= {};
export function AlertasT(datos){
    var tokenStore="tok";
      let instance = axios.create();
      instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);
      movimientos= {};
      if(datos){
        datos.map((mov, index) =>{
          if(movimientos[mov.id]){
            movimientos[mov.id].push(mov);
          }else{
            movimientos[mov.id]= [];
            movimientos[mov.id]= mov;
          } return mov;
        });
      }

      return axios.get(conf.api_raiz+"plataformasAlertas/usuario/"+user.id).then(categorias => {
        if( categorias.data ){
          cat= {};
          categorias.data.map((c, index) =>{
            if( c.alertaCategoria ){
              if( cat[c.plataforma] ){
                if( cat[c.plataforma][c.alertaTipo.id] ){
                  cat[c.plataforma][c.alertaTipo.id]= c;
                }else{ cat[c.plataforma][c.alertaTipo.id]= c; }
              }else{
                cat[c.plataforma]={};
                cat[c.plataforma][c.alertaTipo.id]= c;
              }
            } return c;
          });
        }
        //console.log( cat );

        return axios.get(conf.api_raiz+"terrestresAlertasMov/usuario/"+user.id).then(res => {
          if(res.data){
            var alertas= {};
            res.data.map((alerta, index) =>{
              if(alertas[alerta.idMovimiento]){
                if( movimientos[alerta.idMovimiento] ){
                  if( movimientos[alerta.idMovimiento].plataforma ){
                    if( cat[movimientos[alerta.idMovimiento].plataforma.id] ){
                      if(alerta.idAlertaTipo){
                        if(cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo ] ){
                          if( cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo ){
                            if( alertas[alerta.idMovimiento][cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo] ){
                              alertas[alerta.idMovimiento][cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo].push({
                                alert: alerta,
                                color: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.color,
                                tipo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.nombre,
                                icono: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo,
                                tiempo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.tiempoAtencion
                              });
                              if(alertas[alerta.idMovimiento][alerta.idEntrega]){
                                alertas[alerta.idMovimiento][alerta.idEntrega].push({
                                  alert: alerta,
                                  color: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.color,
                                  tipo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.nombre,
                                  icono: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo
                                });
                              }else{
                                alertas[alerta.idMovimiento][alerta.idEntrega]= [];
                                alertas[alerta.idMovimiento][alerta.idEntrega].push({
                                  alert: alerta,
                                  color: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.color,
                                  tipo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.nombre,
                                  icono: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo,
                                });
                              }
                            }else{
                              alertas[alerta.idMovimiento][cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo]= [];
                              alertas[alerta.idMovimiento][cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo].push({alert: alerta,
                                color: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.color,
                                tipo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.nombre,
                                icono: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo,
                                tiempo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.tiempoAtencion
                              });
                              if(alertas[alerta.idMovimiento][alerta.idEntrega]){
                                alertas[alerta.idMovimiento][alerta.idEntrega].push({alert: alerta,
                                  color: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.color,
                                  tipo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.nombre,
                                  icono: cat[ movimientos[alerta.idMovimiento].plataforma.id]
                                });
                              }else{
                                alertas[alerta.idMovimiento][alerta.idEntrega]= [];
                                alertas[alerta.idMovimiento][alerta.idEntrega].push({alert: alerta,
                                  color: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.color,
                                  tipo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.nombre,
                                  icono: cat[ movimientos[alerta.idMovimiento].plataforma.id]
                                });
                              }
                            }
                          }
                        }else{
                          if(!alertas[alerta.idMovimiento]["fa-bell"] ){
                            alertas[alerta.idMovimiento]= {};
                            alertas[alerta.idMovimiento]["fa-bell"]= [];
                          }
                          alertas[alerta.idMovimiento]["fa-bell"].push({alert: alerta, color: "blue", tipo: "Desconocido", tiempo: '00:00:00' });
                          if(alertas[alerta.idMovimiento][alerta.idEntrega]){
                            alertas[alerta.idMovimiento][alerta.idEntrega].push({alert: alerta, color: "blue", tipo: "Desconocido", icono: "fa-bell" });
                          }else{
                            alertas[alerta.idMovimiento][alerta.idEntrega]= [];
                            alertas[alerta.idMovimiento][alerta.idEntrega].push({alert: alerta, color: "blue", tipo: "Desconocido", icono: "fa-bell" });
                          }
                        }
                      }
                    }else{
                      if(!alertas[alerta.idMovimiento]["fa-bell"] ){
                            alertas[alerta.idMovimiento]= {};
                            alertas[alerta.idMovimiento]["fa-bell"]= [];
                          }
                      alertas[alerta.idMovimiento]["fa-bell"].push({alert: alerta, color: "blue", tipo: "Desconocido", tiempo: '00:00:00' });
                      if(alertas[alerta.idMovimiento][alerta.idEntrega]){
                        alertas[alerta.idMovimiento][alerta.idEntrega].push({alert: alerta, color: "blue", tipo: "Desconocido", icono: "fa-bell" });
                      }else{
                        alertas[alerta.idMovimiento][alerta.idEntrega]= [];
                        alertas[alerta.idMovimiento][alerta.idEntrega].push({alert: alerta, color: "blue", tipo: "Desconocido", icono: "fa-bell" });
                      }
                     }
                  }
                }
              }else{
                if( movimientos[alerta.idMovimiento] ){
                  if( movimientos[alerta.idMovimiento].plataforma ){
                    if( cat[movimientos[alerta.idMovimiento].plataforma.id] ){
                        if(cat[movimientos[alerta.idMovimiento].plataforma.id][alerta.idAlertaTipo] ){
                          alertas[alerta.idMovimiento]= {};
                          alertas[alerta.idMovimiento][cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo ]= [];
                          alertas[alerta.idMovimiento][cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo ].push({
                            alert: alerta,
                            color: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.color,
                            tipo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.nombre,
                            icono: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo,
                            tiempo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.tiempoAtencion
                          });
                          if(alertas[alerta.idMovimiento][alerta.idEntrega]){
                            alertas[alerta.idMovimiento][alerta.idEntrega].push({alert: alerta,
                              color: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.color,
                              tipo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.nombre,
                              icono: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo
                            });
                          }else{
                            alertas[alerta.idMovimiento][alerta.idEntrega]= [];
                            alertas[alerta.idMovimiento][alerta.idEntrega].push({alert: alerta,
                              color: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.color,
                              tipo: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.nombre,
                              icono: cat[ movimientos[alerta.idMovimiento].plataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo
                            });
                          }
                        }else{   // nothing
                          alertas[alerta.idMovimiento]= {};
                          alertas[alerta.idMovimiento]["fa-bell"]= [];
                          alertas[alerta.idMovimiento]["fa-bell"].push({alert: alerta, color: "blue", tipo: "Desconocido", tiempo: '00:00:00' });
                          if(alertas[alerta.idMovimiento][alerta.idEntrega]){
                            alertas[alerta.idMovimiento][alerta.idEntrega].push({alert: alerta, color: "blue", tipo: "Desconocido", icono: "fa-bell" });
                          }else{
                            alertas[alerta.idMovimiento][alerta.idEntrega]= [];
                            alertas[alerta.idMovimiento][alerta.idEntrega].push({alert: alerta, color: "blue", tipo: "Desconocido", icono: "fa-bell" });
                          }
                        }
                    }else{
                      alertas[alerta.idMovimiento]= {};
                      alertas[alerta.idMovimiento]["fa-bell"]= [];
                      alertas[alerta.idMovimiento]["fa-bell"].push({alert: alerta, color: "blue", tipo: "Desconocido", tiempo: '00:00:00' });
                      if(alertas[alerta.idMovimiento][alerta.idEntrega]){
                        alertas[alerta.idMovimiento][alerta.idEntrega].push({alert: alerta, color: "blue", tipo: "Desconocido", icono: "fa-bell" });
                      }else{
                        alertas[alerta.idMovimiento][alerta.idEntrega]= [];
                        alertas[alerta.idMovimiento][alerta.idEntrega].push({alert: alerta, color: "blue", tipo: "Desconocido", icono: "fa-bell" });
                      }
                    }
                  }
                }   /*fin*/
              } return alerta;
            });
            sessionStorage.setItem("AlertasT", JSON.stringify(alertas));
            //console.log(alertas);
            return alertas;
          }
        }).catch(errors => console.log(errors));
      }).catch(errors => console.log(errors));

}

  export default AlertasT;
