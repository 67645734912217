import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { validarToken } from "./herramientas/JWT";
import LogOut from "./Auth/LogOut";

import { Error400, Error401, Error403, Error404, Error500, Error503,AdminstracionPage } from "./pages";

import { LoginPage, ProfilePage , DashboardOtd, DetalleRemolques, UpdateRemolques, OrdenesClientePage,MovDownload,MovDownLoadHellmann} from "./pages";

import { TrackingPage, MonitoreoPage, MovimientosNavierosClientePage , LocalidadesTransportistasPage,RemolquesTransportistasPage} from "./pages";



import "tabler-react/dist/Tabler.css";

var user= JSON.parse(sessionStorage.getItem("usuario"));

function changeTema(){

  if(user){if( localStorage.getItem('skyonetema'+user.id) === "2" ){
    require ("./Temas/TablerDark.css");
  }else{
    if( localStorage.getItem('skyonetema'+user.id) === "1" ){
      require ("./Temas/TablerDefault.css");
    }
  }}else{
    require ("./Temas/TablerDefault.css");
  }
}

document.querySelector('body').style.backgroundImage = "url('images/imagenFondo.jpg')";
document.querySelector('body').style.backgroundSize = "cover";
type Props = {||};
function App(props: Props): React.Node {
  return (
    <div>{ !window.location.href.toUpperCase().includes("/LOGIN")? validarToken(sessionStorage.getItem("tok")): null }
      { changeTema() }
      <React.StrictMode>
        <Router>
          <Switch>
            <Route exact path="/" component={Error403} />
            <Route exact path="/400"                  component={Error400} />
            <Route exact path="/401"                  component={Error401} />
            <Route exact path="/403"                  component={Error403} />
            <Route exact path="/404"                  component={Error404} />
            <Route exact path="/500"                  component={Error500} />
            <Route exact path="/503"                  component={Error503} />
            <Route exact path="/logout"               component={LogOut} />
            <Route exact path="/login"                component={LoginPage} />
            <Route exact path="/perfilusuario"        component={ ProfilePage } />            
            <Route exact path="/tracking"             component={TrackingPage} />
            <Route exact path="/monitoreo"            component={MonitoreoPage} />
            <Route exact path="/movimientos_nav_clien"component={MovimientosNavierosClientePage} />
            <Route exact path="/dashboard_otd"        component={DashboardOtd} />
            <Route exact path="/remolques"            component={DetalleRemolques}/>
            <Route exact path="/update_remolques"     component={UpdateRemolques}/>
            <Route exact path="/ordenescliente"       component={OrdenesClientePage}/>
            <Route exact path="/descarga_mov_maerks"  component={MovDownload}/>
            <Route exact path="/descarga_mov_hellmann"component={MovDownLoadHellmann}/>
            <Route exact path="/localidades"          component={LocalidadesTransportistasPage}/>
            <Route exact path="/remolquesTransportista"component={RemolquesTransportistasPage}/>
           
            
              <Route
                exact
                path="/administracion"
                component={AdminstracionPage}
              />
            <Route component={Error404} />
          </Switch>
        </Router>
      </React.StrictMode>
    </div>
  );
}

export default App;
