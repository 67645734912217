
import React, { useEffect, useState } from "react";
import Crud_Catalogos from '../../../herramientas/Crud_Catalogos';
import { Grid } from 'tabler-react';
import SplitterLayout from 'react-splitter-layout';
import LoadStore from "../../../store/LoadStore";
import SiteWrapper from "../../../SiteWrapper.react";
import DetalleMovimiento from "./DetalleMovimiento";

const DetalleMovimientoSubasta = ({movimiento}) => {
  //console.log(movimiento);
  const [tab, setTab]= useState(1);

  useEffect(() => {
    crud("get", "", "", "perfilesFuncionalidades", "columnas"); 
  }, []);


  const crud = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
      return Crud_Catalogos(catalogo, "", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
          switch (metodo) {
              case "get": 
              if(stateVar === "columnas") {
                    
              }  
              break;                       
          default:
              break;
          }
      }).catch(err => { 
          if( err.response ){ console.log(err.response); }else{console.log("Error desconocido .... "+ err); }
      });
  }

    return (
        <>        
        <Grid.Col sm={12} lg={12}>
          <div style={{ height: "100vh", "font-size": "smaller"}}>
            <SplitterLayout vertical= {false} percentage= {true}  secondaryInitialSize= {30}>
              <div className= "mt-1">
                <div>
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <a className={"nav-link"+ (tab === 1 ? " active":"")} onClick={()=> setTab(1)}>Pantalla Principal</a>
                    </li>
                    <li className="nav-item">
                      <a className={"nav-link"+ (tab === 2 ? " active":"")} onClick={()=> setTab(2) }>Horarios</a>
                    </li>  
                    <li className="nav-item">
                      <a className={"nav-link"+ (tab === 3 ? " active":"")} onClick={()=> setTab(3)}>Cotizaciones</a>
                    </li>
                    <li className="nav-item">
                      <a className={"nav-link"+ (tab === 4 ? " active":"")} onClick={()=> setTab(4) }>Incidencias</a>
                    </li>  
                    {/*<li className="nav-item">
                      <a className={"nav-link"+ (tab === 5 ? " active":"")} onClick={()=> setTab(5) }>Cargo</a>
                    </li>*/} 
                    <li className="nav-item">
                      <a className={"nav-link"+ (tab === 6 ? " active":"")} onClick={()=> setTab(6) }>Documentos</a>
                    </li>         
                  </ul>
                  <div hidden={tab !== 1}>
                    <DetalleMovimiento 
                      movimiento={movimiento}
                      tab={tab}/>  
                  </div>
                  <div hidden={tab !== 2}>
                    <DetalleMovimiento 
                      movimiento={movimiento}
                      tab={tab}/>  
                  </div>
                  <div hidden={tab !== 3}>
                    <DetalleMovimiento 
                      movimiento={movimiento}
                      tab={tab}/> 
                  </div>
                  <div hidden={tab !== 4}>
                    <DetalleMovimiento 
                      movimiento={movimiento}
                      tab={tab}/>   
                  </div>
                  {/*<div hidden={tab !== 5}>
                    <DetalleMovimiento 
                      movimiento={movimiento}
                      tab={tab}/> 
                  </div>*/}
                  <div hidden={tab !== 6}>
                    <DetalleMovimiento 
                      movimiento={movimiento}
                      tab={tab}/> 
                  </div>
                </div>              
              </div>                           
            </SplitterLayout>
          </div>
        </Grid.Col>
        
        </>
    )
}

export default DetalleMovimientoSubasta;