import { validarToken } from "../../../../herramientas/JWT";
import {api_raiz} from "../../../../config/config";
import axios from 'axios';
import { object } from "prop-types";

export const Call_Otds = async(api="", metodo="get", id="", params={}, storeFunction="", stateElementos=[], hiddenModal="", arraytoUpdate=[], columna="", filtro="" )=>{
    id = JSON.parse(sessionStorage.getItem("plataforma")).id;
    const tokenStore="tok";
    validarToken(sessionStorage.getItem(tokenStore));
    let instance = axios.create();

    let url = api_raiz + api + '/' + id;
    
    instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    instance.defaults.headers.common['Accept'] = 'application/json';  
          switch(metodo){
                  case "get":{
                       
                        Object.keys(params).forEach(function( key, val){
                                url += `/${params[key]}`
                        });
                        
                        return axios.get(url).then(res => {                                      
                                if( storeFunction !== "" ){
                                        storeFunction(res.data);
                                }
                                
                                return res.data;
                        });
                  }
                  case "post":
                    
                  case "put":
                          
                  case "delete":
                        
                  default: return null;
        }
}
  
  export default Call_Otds;