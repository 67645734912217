import React, { Component } from "react";
import {  Button, Form, Icon, Grid } from "tabler-react";
import { mail } from "../../../herramientas/Forms";
class  PersonaForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
                  nombre: "",
                  aPaterno: "",
                  aMaterno: "",
                  skyangel: false,
                  correo: "",
                  correoEstatus: 0,
                  Etiqueta_arraylist:[],
                  che: false,
                  crudbutonEnable: true, // Se desabilita cuando se hace click
                  accion: this.props.accion, //'nuevo'// modificar, nuevo
};
    this.checarEtiqueta=false;
    this.Etiqueta_array=[];
    this.etiquetaId=0;
    this.personaObject=[];
    this.enableSave = this.enableSave.bind(this);
}
isEnable=(Accion)=>{
  let borrar= "personas_borrar";
  let actualizar= "personas_editar";
  let agregar= "personas_agregar";
  console.log(Accion+" =>");
  console.log("this.props.stor.privilegios_object=> ");
  console.log(this.props.stor.privilegios_object, JSON.parse(sessionStorage.getItem("perfil")));
  var privilegios= this.props.stor.privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
  for (let i = 0;  i<privilegios.length; i++) {
    if(Accion === "borrar"){ 
      if( privilegios[i].clave === borrar){ if(perfil.privilegios.filter(word => word.idPrivilegio === privilegios[i].id).length > 0 ){return true}}}
    if(Accion === "actualizar"){if( privilegios[i].clave === actualizar){ if(perfil.privilegios.filter(word => word.idPrivilegio === privilegios[i].id).length > 0 ){return true}}}
    if(Accion === "agregar"){if( privilegios[i].clave === agregar){ if(perfil.privilegios.filter(word => word.idPrivilegio === privilegios[i].id).length > 0 ){return true}}}
  }
  return false
}

  componentDidMount =() => {
    if(this.state.accion !== 'nuevo'){
      this.setState({nombre: this.props.elementos.nombre});
      this.setState({aPaterno: this.props.elementos.aPaterno});
      this.setState({aMaterno: this.props.elementos.aMaterno});
      this.setState({skyangel: this.props.elementos.skyangel});
      this.setState({correo: this.props.elementos.correo});
      this.setState({Etiqueta_arraylist: this.Etiqueta_array});
      this.setState({correoEstatus: this.props.elementos.correoEstatus });
      this.personaObject= this.props.elementos;
    }else{
        this.personaObject={"nombre": "", "aPaterno": "", "aMaterno": "", "skyangel": 0, "correo": "","correoEstatus": 0, "telefono": "","whatsappEstatus":false }
        }
  }

  enableSave =() =>{
    if(this.state.nombre.length > 0 && this.state.aPaterno.length > 0
      && mail(this.state.correo) ){
      return false;
    }else{
      return true
    }
  }

  changeName =(event) => {
    this.personaObject.nombre = event.target.value;
    this.setState({nombre: event.target.value});
  }
  changePaterno =(event) => {
    this.personaObject.aPaterno = event.target.value;
    this.setState({aPaterno: event.target.value});

  }
  changeMaterno =(event) => {
    this.personaObject.aMaterno = event.target.value;
    this.setState({aMaterno: event.target.value});

  }

  changeEstatus = (event) =>{
    this.personaObject.skyangel = event.target.checked ? 1: 0; 
    event.target.checked ? this.setState({skyangel: 1}) : this.setState({skyangel: 0 });
  }
  changeCorreoEstatus = (event) =>{
    this.personaObject.correoEstatus = event.target.checked ? 1: 0; 
    this.setState({correoEstatus: event.target.checked ? 1:0 });
  }

  changecorreo =(event) => {
    this.personaObject.correo = event.target.value;
    this.setState({correo: event.target.value});

  }

  changeEtiqueta =(etiqueta, personaId, etiqId) => {
    console.log("-->> "+this.Etiqueta_array);
    if (!this.state.Etiqueta_arraylist[etiqueta]){ //add etiqueta
      for (let item of this.props.stor.etiquetas_object) {
        if(item["id"] === etiqId){
          this.personaObject.etiquetas.push(item);
          console.log(this.personaObject);
          break;
        }
      }
    }else{ //deleted etiqueta
      this.personaObject.etiquetas.map((elemento, index) => {
        if(elemento["id"] === etiqId){
          this.personaObject.etiquetas.splice(index, 1);
        } return elemento;
      })
    }

    this.state.Etiqueta_arraylist[etiqueta] ?
    this.props.getfuntion("delete", "", this.props.elementos.id+"/"+etiqId, this.props.stor.etiquetas_update, "etiquetasPersonas"):
    this.props.getfuntion("post",{ "id_persona": personaId, "id_etiqueta": etiqId }, "", this.personaObject, "etiquetasPersonas", this.props.stor.etiquetas_update );

    this.state.Etiqueta_arraylist[etiqueta] ? this.Etiqueta_array[etiqueta]= 0 : this.Etiqueta_array[etiqueta]= 1
    this.setState({Etiqueta_arraylist: this.Etiqueta_array})
    console.log(etiqueta+" :: "+ this.state.Etiqueta_arraylist[etiqueta]);
  }
  delete()    { this.props.getfuntion("delete", "", this.state.id); this.setState({ crudbutonEnable: false });}
  crud_put()  { this.props.getfuntion("put", this.personaObject);   this.setState({ crudbutonEnable: false });}
  crud_post() { this.props.getfuntion("post", this.personaObject);
                this.setState({ crudbutonEnable: false });
                this.setState({nombre:   ""});
                this.setState({aPaterno: ""});
                this.setState({aMaterno: ""});
                this.setState({correo:   ""});
  }

  render() {
    return (
<div>
    { this.state.accion === "modificar"? <h1>({this.props.elementos.id}) { this.props.elementos.nombre }</h1>:null }
  {
    /**
     * Formulario personas
     */
   }
  <Grid.Row>
          <Grid.Col md={12} xl={12}>

                <Grid.Row>
                  <Grid.Col>
                    <Form.Group label="Nombre">
                      <Form.Input name="nombre" type="text" value={ this.state.nombre } onChange={this.changeName} />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col>
                    <Form.Group label="Paterno">
                      <Form.Input name="aPaterno" type="text" value={ this.state.aPaterno } onChange={this.changePaterno} />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col>
                    <Form.Group label="Materno">
                      <Form.Input name="aMaterno" type="text" value={ this.state.aMaterno } onChange={this.changeMaterno} />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  
                  <Grid.Col>
                    <Form.Group label="correo">
                      <Form.Input name="correo" type="email" value={ this.state.correo }  placeholder="example@skyangel.com"onChange={this.changecorreo} />
                    </Form.Group>
                  </Grid.Col>
                  {!this.props.clienteFinal?
                    <Grid.Col>
                      <Form.Group label="Skyangel">
                        <Form.Switch name="skyangel" label=" " value={ this.state.skyangel } checked={this.state.skyangel} onChange={this.changeEstatus} />
                      </Form.Group>
                    </Grid.Col>
                  :null}
                  {!this.props.clienteFinal?
                    <Grid.Col>
                      <Form.Group label="Estatus">
                        <Form.Switch name="correoestatus" label=" " checked={this.state.correoEstatus} onChange={this.changeCorreoEstatus} />
                      </Form.Group>
                    </Grid.Col>
                  :null}
                </Grid.Row>
          </Grid.Col>
        </Grid.Row>
  {
    /**
     * Lista de etiquetas
     */
   }

   {
     /*
     this.state.accion === "modificar" ?
  <Grid.Row>
          <Grid.Col md={12} xl={12}>
            <Card
              title="Etiquetas"
              isCollapsible
              //isClosable
              isCollapsed={ true}
              body={
                <div>
                  <Form.Group>
                    <Table
                      cards={true}
                      striped={true}
                      responsive={true}
                      className="table-vcenter"
                    >
                      <Table.Header>
                        <Table.ColHeader>ID</Table.ColHeader>
                        <Table.ColHeader>Nombre</Table.ColHeader>
                        <Table.ColHeader>Descripción</Table.ColHeader>
                        <Table.ColHeader>Status</Table.ColHeader>
                      </Table.Header>
                      <Table.Body>
                        { console.log( this.Etiqueta_array=[]) }
                        {
                          this.props.stor.etiquetas_object.map((elemento, index) =>
                            <Table.Row>
                              <Table.Col>{ elemento["id"] }</Table.Col>
                              <Table.Col>{ elemento["nombre"] }</Table.Col>
                              <Table.Col>{ elemento["descripcion"] }</Table.Col>
                              { this.checarEtiqueta= false }
                              {
                                this.props.elementos.etiquetas.map((etiq, ind) =>
                                {
                                  if(etiq.idEtiqueta === elemento["id"]){
                                    console.log(this.checarEtiqueta= true);
                                  }
                                  this.etiquetaId= etiq.idEtiqueta;
                                })
                              }
                                { this.checarEtiqueta
                                ? console.log(this.Etiqueta_array.push( 1 ))
                                : console.log(this.Etiqueta_array.push( 0 )) }
                              <Table.Col><Form.Switch type="Checkbox" name="toggle" value="option1"
                                                      checked={ this.state.Etiqueta_arraylist[index] }
                                                      onChange={ () => this.changeEtiqueta(index, this.state.id, elemento["id"]) } /></Table.Col>
                            </Table.Row>
                          )
                        }
                      </Table.Body>
                    </Table>
                  </Form.Group>
                </div>
              }
            />
          </Grid.Col>
        </Grid.Row>
            : null */}

  <div className="float-right">
    {this.isEnable("actualizar") || this.isEnable("agregar") ?
      <span class="badge">
        <Button
          target="_blank" size="sm" RootComponent="a" color="primary"
          disabled={ this.enableSave() || !this.state.crudbutonEnable }
          onClick= { this.state.accion === "modificar" ? () => this.crud_put() : () => this.crud_post()} >
          <span class="badge"><Icon link={true} name="save" />Guardar</span>
        </Button>
      </span>
    :null}
    { this.state.accion === "modificar" && this.isEnable("borrar")? <span class="badge">
    <Button target="_blank" size="sm" RootComponent="a" color="primary" onClick= { () => this.delete()} disabled={!this.state.crudbutonEnable}>
      <span class="badge"><Icon link={true} name="trash" />Borrar</span>
    </Button>
    </span>
    :
    null
    }
  </div>
</div>
    );
  }
}
export default PersonaForm;
