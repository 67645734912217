//Author F.R. Betancourt
import React, { useState, useEffect } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";
import Select from "react-select";
import { element } from "prop-types";

const RemolquesForm = ({
  stor,
  accion,
  elementos,
  getfuntion,
  cerrarModal,
}) => {

  console.log(elementos)
  const [formulario, setFormulario] = useState([]);
  const [crudbutonEnable, setCrudbutonEnable] = useState(true); // Se desabilita cuando se hace click
  const [selectLocalidades, setSelectLocalidades] = useState();
  const [localidadesOpcion, setLocalidadesOpcion] = useState();
  const [localidadesOpciones, setLocalidadesOpciones] = useState();
  const [remolquesOpcion, setRemolquesOpcion] = useState();
  const [remolquesOpciones, setRemolquesOpciones] = useState();
  const [corredorOpcion, setCorredorOpcion] = useState();
  const [corredorOpciones, setCorredorOpciones] = useState();

  const [estatus, setEstatus] = useState();
  var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

  useEffect(() => {
    
    let corredoresTipo = [{value:"F", label:"FORANEO"},{ value:"L" ,label:"LOCAL"}]

    if (accion === "nuevo") {
      let localidades = [];
      let bandera =  true;
      stor.localidadesTransportistas_object.forEach((localidad,index) => {
         
        if(elementos.length> 0){
          elementos.forEach(element => {
            if(localidad.id != element.idTransportistaLocalidades.id ){
              if(localidad.estatus && bandera){
                localidades.push(localidad)
                bandera = false;
              }
              
            }else{
              bandera = true;
            }
          });
        }else{

          if(localidad.estatus ){
            localidades.push(localidad)
           
          }
        }
          
      })

      let localidadesOpcionesTemp = [];
      let remolquesTipoOpcionesTemp=[];
     
      Object.keys(localidades).forEach((key) => localidadesOpcionesTemp.push({ value: localidades[key].id, label: localidades[key].idLocalidad.nombre}));
      Object.keys(stor.remolquesTipo_object).forEach((key) => remolquesTipoOpcionesTemp.push({ value: stor.remolquesTipo_object[key].idRemolque, label: stor.remolquesTipo_object[key].nombre}));
      setLocalidadesOpciones(localidadesOpcionesTemp)
      setRemolquesOpciones(remolquesTipoOpcionesTemp)
      setCorredorOpciones(corredoresTipo)
    } else {
      setCorredorOpciones(corredoresTipo)
      setCorredorOpcion([{value:elementos.tipoCorredor , label:elementos.tipoCorredor == "F" ? "FORANEO" : "LOCAL" ,selected:true}])
      setRemolquesOpcion([{value:elementos.idRemolque.id , label:elementos.idRemolque.nombre ,selected:true}])
      setLocalidadesOpcion([{value:elementos.idTransportistaLocalidades.id , label:elementos.idTransportistaLocalidades.idLocalidad.nombre ,selected:true}])
      setFormulario(elementos);
    }
  }, []);


  const handleChangeOptions = (event=null,data=null)=>{

    let arrayFiltersTemp;
    let selectOption;

    console.log(data ? data.length : null)

    if((event&& event.action === "select-option")){
      selectOption = event.name;
      arrayFiltersTemp = [{"name": selectOption,"value": event.option.value}];

      setSelectLocalidades(arrayFiltersTemp);
    }

    
  }



  const isEnable = (Accion) => {

    console.log(formulario)
    let borrar = "corredores_borrar";
    let actualizar = "corredores_editar";
    let agregar = "corredores_agregar";
    var privilegios = stor.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (Accion === "borrar") {
        if (privilegios[i].clave == borrar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "modificar") {
        if (privilegios[i].clave == actualizar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "nuevo") {
        if (privilegios[i].clave == agregar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const enableSave = () => {
    if (localidadesOpcion ? (localidadesOpcion.length > 0  ? true : false) : false) {
      return false;
    } else {
      return true;
    }
  };

  const guardarLocalidades = () => {
    if (accion === "modificar") crud_put();
    else crud_post();
    cerrarModal();
  };

  const crud_delete = () => {
    setCrudbutonEnable(false);
    getfuntion("delete", "", elementos.id);
  };

  const crud_put = () => {
    setCrudbutonEnable(false);
   
    formulario.idTransportistaLocalidades = formulario.idTransportistaLocalidades.id;
    formulario.idRemolque =  formulario.idRemolque.idRemolque
    formulario.tipoCorredor =  corredorOpcion.value
    getfuntion("put", formulario);
  };
  const crud_post = () => {
    localidadesOpcion.map((localidad,index) =>{
      
      remolquesOpcion.map((remolque) =>{

        corredorOpcion.map((corredor)=>{
          formulario.push({ idTransportistaLocalidades: Number(localidad.value), idRemolque: Number(remolque.value) , tipoCorredor: corredor.value})
        })
          
      })
    
    })

    setCrudbutonEnable(false);
    getfuntion("post", formulario);
  };
  return (
    <div>
      {accion === "modificar" ? <h4> {} </h4> : null}
      
      {accion === "modificar" ?  
        <div>
        <Grid.Row>
        <Grid.Col md={12}>
          <label for="remolques">Localidad</label>
          <Select   name="localidades"  value={localidadesOpcion}    />
        </Grid.Col>

        </Grid.Row> 
        <Grid.Row>
          <Grid.Col md={12}>
              <label for="remolques">Selecciona los remolques a utilizar</label>
                <Select   name="remolques" value={remolquesOpcion} />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
      
        <Grid.Col md={12}>
            <label for="remolques">Selecciona los corredores a utilizar</label>
          <Select   name="remolques" value={corredorOpcion} onChange={(e,a)=>{ setCorredorOpcion(e);  }} options={corredorOpciones} />
        </Grid.Col>
       </Grid.Row>
       </div>
        : 
        <div>
        <Grid.Row>
        <Grid.Col md={12}>
          <label for="remolques">Selecciona las localidades a trackear</label>
          <Select  isMulti name="localidades" value={localidadesOpcion} onChange={(e,a)=>{ setLocalidadesOpcion(e); }} options={localidadesOpciones} />
        </Grid.Col>

        </Grid.Row> 
        <Grid.Row>
          <Grid.Col md={12}>
              <label for="remolques">Selecciona los remolques a utilizar</label>
                <Select  isMulti name="remolques" value={remolquesOpcion} onChange={(e,a)=>{ setRemolquesOpcion(e);  }} options={remolquesOpciones} />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
      
        <Grid.Col md={12}>
            <label for="remolques">Selecciona los corredores a utilizar</label>
          <Select  isMulti name="remolques" value={corredorOpcion} onChange={(e,a)=>{ setCorredorOpcion(e);  }} options={corredorOpciones} />
        </Grid.Col>
       </Grid.Row>
       </div>
       }


      <div className="float-right">
        {isEnable(accion) ? (
          <span class="badge">
            <Button
              target="_blank"
              size="sm"
              RootComponent="a"
              color="primary"
              disabled={enableSave() || !crudbutonEnable}
              onClick={guardarLocalidades}
            >
              <span class="badge">
                <Icon link={true} name="save" />
                Guardar
              </span>
            </Button>
          </span>
        ) : null}
        {accion === "modificar"  ? (
          <span class="badge">
            <Button
              target="_blank"
              size="sm"
              RootComponent="a"
              color="primary"
              onClick={() => crud_delete()}
              disabled={!crudbutonEnable}
            >
              <span class="badge">
                <Icon link={true} name="trash" />
                Borrar
              </span>
            </Button>
          </span>
        ) : null}
      </div>
    </div>
  );
};
export default RemolquesForm;
