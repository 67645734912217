import React, { Component } from "react";
import { Container, Grid, Card, Button, Form, Avatar, Media, GalleryCard, Icon } from "tabler-react";
import Select from "react-select";
import SiteWrapper from "../SiteWrapper.react";
//import temadark from "../../public/images/temadark.jpg";

var tema= [{ value: 0, label: "Selecciona  un tema" }, { value: 1, label: "Defaul" }, { value: 2, label: "Oscuro" }];
var user= JSON.parse(sessionStorage.getItem("usuario"));
var perfil= JSON.parse(sessionStorage.getItem("perfil"));
var currentTema= user ?localStorage.getItem('skyonetema'+user.id): 0;
class ProfilePage extends Component{
  constructor(props) {
    super(props);
    this.state = { tema:{ value: 0, label: "Selecciona  un tema" }};
  }
  componentDidMount =() => {
    console.log( user )
    if( currentTema > 0){
      for(let item of tema){
        if( item.value === currentTema){
          this.setState({ tema: item });
          break;
        }
      }
    }
  }
  changeTema=(event) => {
    this.setState({ tema: event });
  }
  saveTema=() => {
    console.log(user)
    switch(this.state.tema.value){
      case 1:{
        localStorage.setItem('skyonetema'+user.id, 1);
        window.location.href= "/perfilusuario";
        break;
      }
      case 2:{
        localStorage.setItem('skyonetema'+user.id, 2);
        window.location.href= "/perfilusuario";
        break;
      }
      default:
        break;
    }
    //localStorage.getItem('skytemas')? require ("./Temas/Tabler.css"): require ("tabler-react/dist/Tabler.css");
    //localStorage.setItem('skytema', 'dark');
    //this.setState({ tema: event });
  }
  render() {
    return (
      <SiteWrapper>
        <div className="my-3 my-md-5">
          <Container>
            <Grid.Row>
              <Grid.Col lg={4}>
              <Card>
                <Card.Body>
                  <Media>
                    <Avatar
                      size="xxl"
                      className="mr-5"
                      imageURL="./user/user.png"
                    />
                    <Media.BodySocial
                      name={user.persona.aPaterno+" "+ user.persona.aMaterno+" "+user.persona.nombre}
                      workTitle={ perfil.nombre }
                      //facebook="Facebook"
                      //twitter="Twitter"
                      //phone="1234567890"
                      //skype="@skypename"
                    />
                  </Media>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <span Nameclass="badge"><Icon link={true} name="mail" />Correo :{ user.persona.correo }</span>
                  <i class="fa fa-address-book-o"><span Nameclass="badge"> Skyangel: { user.persona.skyangel } </span></i>
                </Card.Body>
              </Card>
              </Grid.Col>
              <Grid.Col lg={8}>
                <Card>
                  <Card.Header>
                    Configuración
                    <Card.Options>
                      <Button target="_blank" size="sm" RootComponent="a" color="primary"
                              onClick= { () => this.saveTema() }
                              disabled={ !this.state.tema.value }
                              icon={"save"}
                      >
                      </Button>
                    </Card.Options>
                  </Card.Header>
                  <Card.Body>
                    <Form.Group label="Corredores">
                      <Select value={ this.state.tema } onChange={this.changeTema} options={ tema } />
                    </Form.Group>
                    {this.state.tema.value === 1
                    ? <GalleryCard.Image
                        src={ "images/temadefault.jpg" }
                      />
                    : this.state.tema.value === 2
                        ?<GalleryCard.Image
                          src={ "images/temadark.jpg" }
                        />
                        :null
                    }
                  </Card.Body>
                </Card>
              </Grid.Col>
            </Grid.Row>
          </Container>
        </div>
      </SiteWrapper>
    );
  }
}

export default ProfilePage;
