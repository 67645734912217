import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Crud_Catalogos from '../herramientas/Crud_Catalogos';
import { timeToDatefull } from '../herramientas/DateFormat';
import { TablePageable } from '../Helpers';
import { Form, Grid, Icon } from 'tabler-react';
import { LoadingScreen } from './LoadingScreen';

const IncidenciasTransportista = ({ idPlataforma }) => {
  let inicioYear = new Date().getFullYear() + '-01-01';

  //const [ transportista, setTransportista] = useState({ value: 0, label: "Selecciona un Transportista", data:{}});
  const [transportista, setTransportista] = useState();
  const [listaTransportistas, setListaTransportistas] = useState([]);
  const [
    listaIncidenciasTransportistasTotales,
    setListaIncidenciasTransportistasTotales,
  ] = useState([]);
  const [listaIncidenciasTransportista, setListaIncidenciasTransportista] =
    useState([]);
  const [date1, setDate1] = useState(inicioYear);
  const [date2, setDate2] = useState(timeToDatefull(new Date().getTime()));
  const [bandera, setBandera] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    crud(
      'get',
      '',
      '',
      'transportistas/plataforma/' + idPlataforma,
      'listaTransportista'
    );
    refrescar();
  }, [idPlataforma]);

  const refrescar = async (e) => {
    setIsLoading(true);
    if (bandera === true) {
      await crud(
        'get',
        '',
        '',
        'movimientoIncidencias/transportistas/plataforma/' + idPlataforma,
        'listaMovimientoIncidenciasTransportistasTotales'
      );
    }
    if (bandera === false) {
      getIncidenciasTransportista(e);
    }
    setIsLoading(false);
  };

  const getIncidenciasTransportista = async (e) => {
    setIsLoading(true);
    if (e && e.length) {
      //console.log("Entro: " + e.length);
      let trans = '';
      let total = e.length;
      e.forEach((element, index) => {
        if (total === index + 1) {
          trans += element.value;
        } else {
          trans += element.value + ',';
        }
      });
      //console.log(trans);
      if (date1 === timeToDatefull(new Date().getTime())) {
        //console.log("date: " + date1);
        await crud(
          'get',
          '',
          '',
          'movimientoIncidencias/plataforma/transportistas?idTransportistas=' +
            trans,
          'listaMovimientoIncidenciasTransportista'
        );
      } else {
        //console.log("DATE: " + date1);
        await crud(
          'get',
          '',
          '',
          'movimientoIncidencias/plataforma/' +
            idPlataforma +
            '/transportistas/' +
            trans +
            '/fecha1/' +
            date1 +
            ' 00:00:00/fecha2/' +
            date2 +
            ' 23:59:59',
          'listaMovimientoIncidenciasTransportista'
        );
      }
    } else {
      setDate1(inicioYear);
      setDate2(timeToDatefull(new Date().getTime()));
      setBandera(true);
      await crud(
        'get',
        '',
        '',
        'movimientoIncidencias/transportistas/plataforma/' + idPlataforma,
        'listaMovimientoIncidenciasTransportistasTotales'
      );
    }
    setIsLoading(false);
  };

  const getByDates = async () => {
    setIsLoading(true);
    if (transportista && transportista.length) {
      //console.log("ENTRO: " + transportista.length);
      let trans = '';
      let total = transportista.length;
      for (let i = 0; i < transportista.length; i++) {
        //console.log(transportista[i]);
        if (total === i + 1) {
          trans += transportista[i].value;
        } else {
          trans += transportista[i].value + ',';
        }
      }
      //console.log(trans);
      await crud(
        'get',
        '',
        '',
        'movimientoIncidencias/plataforma/' +
          idPlataforma +
          '/transportistas/' +
          trans +
          '/fecha1/' +
          date1 +
          ' 00:00:00/fecha2/' +
          date2 +
          ' 23:59:59',
        'listaMovimientoIncidenciasTransportista'
      );
    } else {
      await crud(
        'get',
        '',
        '',
        'movimientoIncidencias/transportistas/plataforma/' +
          idPlataforma +
          '/fecha1/' +
          date1 +
          ' 00:00:00/fecha2/' +
          date2 +
          ' 23:59:59',
        'listaMovimientoIncidenciasTransportistasTotales'
      );
    }
    setIsLoading(false);
  };

  const changeDate1 = (e) => {
    let today = new Date(timeToDatefull(new Date().getTime())).getTime();
    let fecha = new Date(e.target.value).getTime();
    let fecha2 = new Date(date2).getTime();
    //console.log("date1: " + e.target.value);
    if (fecha > fecha2 && fecha < today) {
      setDate2(e.target.value);
    }
    if (fecha <= today) {
      setDate1(e.target.value);
    }
    //console.log("Date2: " + date2);
  };

  const changeDate2 = (e) => {
    let today = new Date(timeToDatefull(new Date().getTime())).getTime();
    let fecha = new Date(e.target.value).getTime();
    let fecha1 = new Date(date1).getTime();
    //console.log("date2: " + e.target.value);
    if (fecha < fecha1 && fecha < today) {
      setDate1(e.target.value);
    }
    if (fecha <= today) {
      setDate2(e.target.value);
    }
    //console.log("Date1: " + date1);
  };

  const getIncidencias = () => {
    if (bandera) {
      return listaIncidenciasTransportistasTotales;
    } else {
      return listaIncidenciasTransportista;
    }
  };

  const crud = (
    metodo = 'get',
    obj = [],
    id = '',
    catalogo = '',
    stateVar = '',
    hiddenModl = ''
  ) => {
    return Crud_Catalogos(
      catalogo,
      '',
      metodo,
      id,
      obj,
      '',
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case 'get':
            if (stateVar === 'listaTransportista' && returnVal) {
              let listaTransportistaTemp = [];
              returnVal.map((i, index) => {
                listaTransportistaTemp.push({
                  value: i.id,
                  label: i.nombre,
                  data: i,
                });
              });
              //console.log(returnVal);
              setListaTransportistas(listaTransportistaTemp);
            }
            if (
              stateVar === 'listaMovimientoIncidenciasTransportistasTotales' &&
              returnVal
            ) {
              let listaMovimientoIncidenciasTransportistasTotalesTemp = [];
              returnVal.map((i, index) => {
                listaMovimientoIncidenciasTransportistasTotalesTemp.push(i);
              });
              //console.log(returnVal);
              setListaIncidenciasTransportistasTotales(
                listaMovimientoIncidenciasTransportistasTotalesTemp
              );
            }
            if (
              stateVar === 'listaMovimientoIncidenciasTransportista' &&
              returnVal
            ) {
              let listaMovimientoIncidenciasTransportistaTemp = [];
              returnVal.map((i, index) => {
                listaMovimientoIncidenciasTransportistaTemp.push(i);
              });
              //console.log(returnVal);
              setListaIncidenciasTransportista(
                listaMovimientoIncidenciasTransportistaTemp
              );
            }
            break;
          case 'put':
            break;
          case 'post':
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {/*console.log(bandera)*/}
      {/*console.log(getIncidencias())*/}
      <div className="row form-row align-items-center ">
        <div class="col-2">
          <Select
            isMulti
            isClearable={true}
            value={transportista}
            onChange={(e) => {
              setTransportista(e);
              getIncidenciasTransportista(e);
              if (bandera) {
                setBandera(false);
              }
            }}
            options={listaTransportistas}
            closeMenuOnSelect={false}
          />
        </div>
        <div>
          <Grid.Row>
            <Grid.Col>
              <div>
                <span className="mr-1 btn text-dark" onClick={getByDates}>
                  <Icon name="refresh-cw" />
                </span>
                <span className="mr-1 float-right">
                  <Form.Input
                    name="date2"
                    type="date"
                    value={date2}
                    onChange={(e) => {
                      changeDate2(e);
                    }}
                  />
                </span>
                <span className="mr-1 ml-1 float-right">y</span>
                <span className="mr-1 float-right">
                  <Form.Input
                    name="date1"
                    type="date"
                    value={date1}
                    onChange={(e) => {
                      changeDate1(e);
                    }}
                  />
                </span>
              </div>
            </Grid.Col>
          </Grid.Row>
        </div>
        <br />
        <TablePageable
          titulo={''}
          lista={getIncidencias()}
          columnas={[
            { columna: 'tipo_movimiento', label: 'TIPO MOVIMIENTO' },
            { columna: 'id_movimiento', label: 'ID MOV' },
            { columna: 'nombre_transportista', label: 'TRANSPORTISTA' },
            { columna: 'contenedor', label: 'CONTENEDOR' },
            { columna: 'booking', label: 'BOOKING' },
            { columna: 'wo', label: 'WO' },
            { columna: 'nombre_incidencia', label: 'INCIDENCIA' },
            { columna: 'tipo_incidencia', label: 'TIPO INCIDENCIA' },
            { columna: 'comentario', label: 'COMENTARIO' },
            { columna: 'fecha_creado', label: 'FECHA CREACION' },
            { columna: 'latitud', label: 'LATITUD' },
            { columna: 'longitud', label: 'LONGITUD' },
          ]}
          id="id_movimiento_incidencia"
          csvname={'Incidencias Maersk por Linea Transportista'}
          //csvname={"Incidencias Maersk por Linea Transportista: " + transportista.label}
          paginacion={true}
          elementosPorPagina={30}
        />
      </div>
      {isLoading ? <LoadingScreen loading={isLoading} /> : null}
    </>
  );
};

export default IncidenciasTransportista;
