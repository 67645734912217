import React, { useState }  from 'react';
import { Grid } from 'tabler-react';
import SplitterLayout from 'react-splitter-layout';
import IncidenciasTransportista from './IncidenciasTransportista';
import IncidenciasClientes from './IncidenciasClientes';
import { useEffect } from 'react';
import Crud_Catalogos from '../herramientas/Crud_Catalogos';

var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

const IncidenciasFiltro = () => {

    const [tab, setTab]= useState(1);

    useEffect(() => {
        crud("get", "", "", "perfilesFuncionalidades", "columnas"); 
    }, []);


    const crud = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
        return Crud_Catalogos(catalogo, "", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
            switch (metodo) {
                case "get": 
                if(stateVar === "columnas") {
                       
                }  
                break;                       
            default:
                break;
            }
        }).catch(err => { 
            if( err.response ){ console.log(err.response); }else{console.log("Error desconocido .... "+ err); }
        });
    } 

  return (
    <>           
        <Grid.Col sm={12} lg={12}>
            <div style={{ height: "100vh", "font-size": "smaller"}}>
                <SplitterLayout vertical= {false} percentage= {true}  secondaryInitialSize= {30}>
                <div className= "mt-1">
                    <div>
                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                        <a className={"nav-link"+ (tab === 1 ? " active":"")} onClick={()=> setTab(1)}>Incidencias por Linea Transportista</a>
                        </li>
                        <li className="nav-item">
                        <a className={"nav-link"+ (tab === 2 ? " active":"")} onClick={()=> setTab(2) }>Incidencias por Cliente</a>
                        </li>          
                    </ul>
                    <div hidden={tab !== 1}>
                        <IncidenciasTransportista 
                            idPlataforma = {currentUser[0].plataforma.id}
                        />   
                    </div>
                    <div hidden={tab !== 2}>
                        <IncidenciasClientes
                            idPlataforma = {currentUser[0].plataforma.id}
                        />   
                    </div>
                    </div>              
                </div>                           
                </SplitterLayout>
            </div>
        </Grid.Col>        
    </>    
  );
}

export default IncidenciasFiltro;