import React, { useEffect, useState } from 'react';
import { List } from 'tabler-react';
import Crud_Catalogos from '../../../herramientas/Crud_Catalogos';

const DetalleMovimiento = ({movimiento, tab}) => {
    //console.log(movimiento);

    const [mov, setMov]= useState({});
    const [variables, setVariables]= useState({});
    const [movSub, setMovSub] = useState({});
    const [tarifarioSub, setTarifarioSub] = useState({});
    
    useEffect(() => {
      crud("get", "", "", "navieraMovimientos/" + movimiento.id_movimiento, "movimiento"); 
      crud("get", "", "", "movimientosSubasta/idMovimiento/" + movimiento.id_movimiento, "movimientoSubasta");
    }, []);
  
  
    const crud = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
        return Crud_Catalogos(catalogo, "", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
            switch (metodo) {
                case "get": 
                if(stateVar === "movimiento" && returnVal) {
                  setMov(returnVal);   
                  setVariables(JSON.parse(returnVal.variables));  
                  crud("get", "", "", "tarifarioSubasta/cliente/" + movimiento.id_cliente, "tarifarioSubasta");
                }               
                if(stateVar === "movimientoSubasta" && returnVal) {
                  setMovSub(returnVal);                       
                } 
                if(stateVar === "tarifarioSubasta" && returnVal) {
                  setTarifarioSub(returnVal);                       
                }  
                break;                     
            default:
                break;
            }
        }).catch(err => { 
            if( err.response ){ console.log(err.response); }else{console.log("Error desconocido .... "+ err); }
        });
    }    
    
  return (
    <>
        <br/> 
        {/*console.log(movSub)*/}        
        {tab !== 6 ?
          <List.Group >
          <List.GroupItem action icon="credit-card"> Id: <span style={{color: '#0992A7'}}>{movimiento.id_movimiento}</span></List.GroupItem>
          <List.GroupItem action icon="calendar"> Cita programada(Cliente): <span style={{color: '#0992A7'}}>{movimiento.cita_programada_cliente}</span></List.GroupItem>
          <List.GroupItem action icon="flag"> Estatus: <span style={{color: '#0992A7'}}>{movimiento.estatus_subasta}</span></List.GroupItem>
          <List.GroupItem action icon="map-pin"> Cliente: <span style={{color: '#0992A7'}}>{movimiento.cliente}</span></List.GroupItem>
          <List.GroupItem action icon="map-pin"> Origen: <span style={{color: '#0992A7'}}>{movimiento.corredor}</span></List.GroupItem>
          <List.GroupItem action icon="user-check"> Ejecutivo: <span style={{color: '#0992A7'}}>{mov.idResponsableMovimiento ? (mov.idResponsableMovimiento.nombre + " " + mov.idResponsableMovimiento.aPaterno + " " + mov.idResponsableMovimiento.aMaterno) : ""}</span>  </List.GroupItem>
          <List.GroupItem action icon="truck"> Transporte: <span style={{color: '#0992A7'}}>{movimiento.transportista}</span></List.GroupItem>
          <List.GroupItem action icon="map-pin"> Destino: <span style={{color: '#0992A7'}}>{movimiento.destino}</span></List.GroupItem>
        </List.Group>  
        : null}               
        <br/>
        {tab === 1 ?
          <div>
            <List.Group>
              <List.GroupItem action icon="user-check"> Persona que solicita el movimiento: <span style={{color: '#0992A7'}}>{variables.persona_ferromex_libera}</span></List.GroupItem>
              <List.GroupItem action icon="map-pin"> Cliente: <span style={{color: '#0992A7'}}>{movimiento.cliente}</span></List.GroupItem>
              <List.GroupItem action icon="truck"> Tipo Contenedor: <span style={{color: '#0992A7'}}>{mov.contenedorSize}</span></List.GroupItem>
              <List.GroupItem action icon="truck"> Movimiento Critico: <span style={{color: '#0992A7'}}>{variables.movimiento_critico ? "Urgente": ""}</span></List.GroupItem>
            </List.Group>          
          <br/>          
          <List.Group>
              <List.GroupItem action icon="flag"> Tipo de servicio: <span style={{color: '#0992A7'}}>{variables.tipo_servicio}</span></List.GroupItem>
              <List.GroupItem action icon="flag"> Tipo de servicio: <span style={{color: '#0992A7'}}>{variables.primera_ultima_milla ? "Ultima Milla": "Primera Milla"}</span></List.GroupItem>
              <List.GroupItem action icon="flag"> Tipo de Material Peligroso: <span style={{color: '#0992A7'}}>{variables.material ? "Si": "No"}</span></List.GroupItem>
              <List.GroupItem action icon="flag"> Tipo de carga: <span style={{color: '#0992A7'}}>{variables.tipo_carga ? "Sobredimensionado": ""}</span>  </List.GroupItem>
            </List.Group>                  
        </div>
        : <div></div>        
        }
        {tab === 2 ?  
          mov.id ? ( <div>
            <List.Group >
              <List.GroupItem action icon="calendar"> { mov.idMovimientoTipo.id === 1 ? "1.- Ingreso a terminal para cargar contenedor: "  : "1.- Ingreso a terminal (Vacio): " } <span style={{color: '#0992A7'}}>{ mov.arriboTerminalVacio }</span></List.GroupItem>
              <List.GroupItem action icon="calendar"> { mov.idMovimientoTipo.id === 1 ? "2.- Salida de Terminal con contenedor(lleno): " : "2.- Salida de Terminal (Vacio): " } <span style={{color: '#0992A7'}}>{ mov.idMovimientoTipo.id === 1 ? mov.salidaTerminalLleno : mov.salidaTerminalVacio }</span> </List.GroupItem> 
              <List.GroupItem action icon="calendar"> { mov.idMovimientoTipo.id === 1 ? "3.- Arribo a dirección de entrega: " : "3.- Arribo a dirección de carga: "  } <span style={{color: '#0992A7'}}>{ mov.idMovimientoTipo.id === 1 ? mov.arriboClienteLleno : mov.arriboDirecionCarga }</span> </List.GroupItem> 
              <List.GroupItem action icon="calendar"> { "4.- Asignación de Rampa: "}  <span style={{color: '#0992A7'}}>{ mov.asignacionRampa }</span></List.GroupItem>
              <List.GroupItem action icon="calendar"> { mov.idMovimientoTipo.id === 1 ? "5.- Salida de dirección de entrega(Vacio): " : "5.- Salida de dirección de carga (lleno): " } <span style={{color: '#0992A7'}}>{ mov.idMovimientoTipo.id === 1 ? mov.salidaClienteVacio : mov.salidaClienteLleno }</span> </List.GroupItem> 
              <List.GroupItem action icon="calendar"> { "6.- Ingreso a terminal para bajar contenedor: "  } <span style={{color: '#0992A7'}}>{ mov.idMovimientoTipo.id === 1 ? mov.arriboTerminalVacio2 : mov.arriboTerminalCargado }</span> </List.GroupItem>              
            </List.Group>               
            </div> ) : <div></div>
          : <div></div>
        }
        {tab === 3 
          ? mov.id ? (
            <div >        
              <List.Group >
                  <List.GroupItem action icon="credit-card"> Estimado de velocidad de transporte: <span style={{color: '#0992A7'}}>53.2 km/h</span> </List.GroupItem>
                  <List.GroupItem action icon="circle"> Distancia: <span style={{color: '#0992A7'}}>{movSub.distanciaOrigenDestino ? movSub.distanciaOrigenDestino : ""} {movSub.distanciaOrigenDestino ? "km" : "No hay calculo de distancia"} </span> </List.GroupItem>
                  <List.GroupItem action icon="alert-circle"> Peso: <span style={{color: '#0992A7'}}>{(variables.peso && variables.peso != "") ? variables.peso : 0 } Kg</span></List.GroupItem>
              </List.Group>
              <br/>
              <List.Group >
                  <List.GroupItem action icon="calendar"> Fecha Solicitud de Movimiento: <span style={{color: '#0992A7'}}>{ variables.fecha_solicitud_movimiento } </span> </List.GroupItem>
                  {/*<List.GroupItem action icon="clock"> Hora de solicitud de movimiento:  </List.GroupItem>*/}
                  <List.GroupItem action icon="money"> Costo de movimiento: <span style={{color: '#0992A7'}}>${tarifarioSub.tarifa ? tarifarioSub.tarifa : 0}</span> </List.GroupItem>
                  <List.GroupItem action icon="truck"> Calificación de transportista: </List.GroupItem>
                  {/*<List.GroupItem action icon="clock"> Tiempo estimado a origen:  </List.GroupItem>*/}
                  <List.GroupItem action icon="clock"> Fecha estimada a destino: <span style={{color: '#0992A7'}}>{movSub.fechaEstimadaLlegada}</span> </List.GroupItem>
                  <List.GroupItem action icon="flag"> Tipo de servicio: <span style={{color: '#0992A7'}}>{variables.tipo_servicio}</span></List.GroupItem>
                  <List.GroupItem action icon="flag"> Tipo de servicio (Primera/Ultima Milla): <span style={{color: '#0992A7'}}>{variables.primera_ultima_milla ? "Ultima Milla": "Primera Milla"}</span></List.GroupItem>
                  <List.GroupItem action icon="flag"> Tipo de Material Peligroso: <span style={{color: '#0992A7'}}>{variables.material ? "Si": "No"}</span></List.GroupItem>
              </List.Group>
          </div>         
        ) : <div></div>        
          : <div></div>
        }
        {tab === 4 
          ? mov.id ? (
            <div >        
              <List.Group >
                  <List.GroupItem action icon="map-pin"> Origen: <span style={{color: '#0992A7'}}>{movimiento.corredor}</span></List.GroupItem>
                  <List.GroupItem action icon="map-pin"> Destino: <span style={{color: '#0992A7'}}>{movimiento.destino}</span></List.GroupItem>                 
              </List.Group>
              <br/>
              <List.Group  >
                  <List.GroupItem action icon="map-pin"> Coordenadas Destino: <span style={{color: '#0992A7'}}>({mov.idCliente.latitud}, {mov.idCliente.longitud})</span> </List.GroupItem>
                  <List.GroupItem action icon="calendar"> Fecha en la que se esta colocando el rastreo:  </List.GroupItem>
                  <List.GroupItem action icon="truck"> Nombre del Transporte: <span style={{color: '#0992A7'}}>{mov.idTransportista.id !== 1 ? mov.idTransportista.nombre : ""}</span></List.GroupItem>
                  <List.GroupItem action icon="flag"> Ciudad: <span style={{color: '#0992A7'}}>{mov.idCliente.idTarifarioSubasta.localidad}</span> </List.GroupItem>
                  <List.GroupItem action icon="clock"> Distancia: <span style={{color: '#0992A7'}}>{movSub.distanciaOrigenDestino ? movSub.distanciaOrigenDestino : ""} {movSub.distanciaOrigenDestino ? "km" : "No hay calculo de distancia"} </span> </List.GroupItem>
                  <List.GroupItem action icon="clock"> Tiempo estimado a Llegada: <span style={{color: '#0992A7'}}>{movSub.tiempoEstimadoDestinoString} </span></List.GroupItem>
                  <List.GroupItem action icon="flag"> Estatus: <span style={{color: '#0992A7'}}>{movimiento.estatus_subasta}</span> </List.GroupItem>
              </List.Group>
          </div>         
        ) : <div></div>        
          : <div></div>
        }
        {tab === 6 
          ? mov.id ? (
            <div >                      
              <List.Group  >
                  <List.GroupItem action icon="credit-card"> Envio de Documentación: </List.GroupItem>
                  <List.GroupItem action icon="credit-card"> Sellos, documentos para transitar:  </List.GroupItem>
                  <List.GroupItem action icon="credit-card"> Evidencia: </List.GroupItem>
                  <List.GroupItem action icon="credit-card"> Documentos de ingreso y salida: </List.GroupItem>                 
              </List.Group>
          </div>         
        ) : <div></div>        
          : <div></div>
        }
      <br/>
    </>
  )
}

export default DetalleMovimiento;
