import * as conf from "../../config/config";
import axios from 'axios';
//import $ from "jquery";
var user= JSON.parse(sessionStorage.getItem("usuario"));
var movimientos= {};
var cat= {};  
export function AlertasN(datos, mtipo){
    var tokenStore="tok";
    let instance = axios.create();
    instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem(tokenStore);
    movimientos= {};
    if(datos){
      datos.map((mov, index) =>{
        if(movimientos[mov.id]){
          movimientos[mov.id].push(mov);
        }else{
          movimientos[mov.id]= [];
          movimientos[mov.id]= mov;
        } return mov;
      });
    }
    return axios.get(conf.api_raiz+"plataformasAlertas/usuario/"+user.id).then(categorias => {             
      if( categorias.data ){
        cat= {};  
        categorias.data.map((c, index) =>{
          if( c.alertaCategoria ){
            if( cat[c.plataforma] ){
              if( cat[c.plataforma][c.alertaTipo.id] ){
                cat[c.plataforma][c.alertaTipo.id]= c;
              }else{ cat[c.plataforma][c.alertaTipo.id]= c; }
            }else{
              cat[c.plataforma]={};
              cat[c.plataforma][c.alertaTipo.id]= c;
            }
          } return c;       
        });
      }
      let apiUrl="";
      if(mtipo){
        apiUrl= conf.api_raiz+"navierasAlertas/usuarioMovimientos/"+user.id+"/mov_tipo/"+mtipo;
      }else{
        apiUrl= conf.api_raiz+"navierasAlertas/usuarioMovimientos/"+user.id;        
      }
        
        return axios.get(apiUrl).then(res => {
          if(res.data){
            var alertas= {};
            res.data.map((alerta, index) =>{
              if(alertas[alerta.idMovimiento]){
                if( movimientos[alerta.idMovimiento] ){
                  if( movimientos[alerta.idMovimiento].idPlataforma ){
                    if( cat[movimientos[alerta.idMovimiento].idPlataforma.id] ){
                      if(alerta.idAlertaTipo){
                        if(cat[ movimientos[alerta.idMovimiento].idPlataforma.id ][ alerta.idAlertaTipo ] ){
                          //console.log( "Movimiento: "+alerta.idMovimiento+", de plataforma: "+ movimientos[alerta.idMovimiento].idPlataforma.id );
                          
                          if( cat[ movimientos[alerta.idMovimiento].idPlataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo ){
                            if( alertas[alerta.idMovimiento][cat[ movimientos[alerta.idMovimiento].idPlataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo] ){
                              alertas[alerta.idMovimiento][cat[ movimientos[alerta.idMovimiento].idPlataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo].push({alert: alerta, 
                                color: cat[ movimientos[alerta.idMovimiento].idPlataforma.id ][ alerta.idAlertaTipo].alertaCategoria.color,
                                tipo: cat[ movimientos[alerta.idMovimiento].idPlataforma.id ][ alerta.idAlertaTipo].alertaCategoria.nombre,
                                tiempo: cat[ movimientos[alerta.idMovimiento].idPlataforma.id ][ alerta.idAlertaTipo].alertaCategoria.tiempoAtencion
                              });
                            }else{
                              alertas[alerta.idMovimiento][cat[ movimientos[alerta.idMovimiento].idPlataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo]= [];
                              alertas[alerta.idMovimiento][cat[ movimientos[alerta.idMovimiento].idPlataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo].push({alert: alerta,
                                color: cat[ movimientos[alerta.idMovimiento].idPlataforma.id ][ alerta.idAlertaTipo].alertaCategoria.color,
                                tipo: cat[ movimientos[alerta.idMovimiento].idPlataforma.id ][ alerta.idAlertaTipo].alertaCategoria.nombre,
                                tiempo: cat[ movimientos[alerta.idMovimiento].idPlataforma.id ][ alerta.idAlertaTipo].alertaCategoria.tiempoAtencion
                              });
                            }                            
                            
                            //alertas[alerta.idMovimiento]["icono"]= cat[ movimientos[alerta.idMovimiento].idPlataforma.id ][alerta.idAlertaTipo].alertaCategoria.logo;
                          }                          
                          //console.log( alerta )
                          //alertas[alerta.idMovimiento].push(alerta);
                        }else{
                          if(!alertas[alerta.idMovimiento]["fa-bell"] ){
                            alertas[alerta.idMovimiento]= {};
                            alertas[alerta.idMovimiento]["fa-bell"]= [];
                          }                           
                          alertas[alerta.idMovimiento]["fa-bell"].push({alert: alerta, color: "blue", tipo: "Desconocido", tiempo: '00:00:00' });
                        }
                      }                      
                    }else{
                      if(!alertas[alerta.idMovimiento]["fa-bell"] ){
                            alertas[alerta.idMovimiento]= {};
                            alertas[alerta.idMovimiento]["fa-bell"]= [];
                          }  
                      alertas[alerta.idMovimiento]["fa-bell"].push({alert: alerta, color: "blue", tipo: "Desconocido", tiempo: '00:00:00' });
                     }
                  }                  
                }
              }else{
                if( movimientos[alerta.idMovimiento] ){
                  if( movimientos[alerta.idMovimiento].idPlataforma ){   
                    if( cat[movimientos[alerta.idMovimiento].idPlataforma.id] ){
                        if(cat[movimientos[alerta.idMovimiento].idPlataforma.id][alerta.idAlertaTipo] ){
                          alertas[alerta.idMovimiento]= {};
                          alertas[alerta.idMovimiento][cat[ movimientos[alerta.idMovimiento].idPlataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo ]= [];
                          //alertas[alerta.idMovimiento]["icono"]= cat[ movimientos[alerta.idMovimiento].idPlataforma.id ][ alerta.idAlertaTipo ]; 
                          alertas[alerta.idMovimiento][cat[ movimientos[alerta.idMovimiento].idPlataforma.id ][ alerta.idAlertaTipo].alertaCategoria.logo ].push({alert: alerta,
                            color: cat[ movimientos[alerta.idMovimiento].idPlataforma.id ][ alerta.idAlertaTipo].alertaCategoria.color,
                            tipo: cat[ movimientos[alerta.idMovimiento].idPlataforma.id ][ alerta.idAlertaTipo].alertaCategoria.nombre,
                            tiempo: cat[ movimientos[alerta.idMovimiento].idPlataforma.id ][ alerta.idAlertaTipo].alertaCategoria.tiempoAtencion
                          });
                        }else{   // nothing                       
                          alertas[alerta.idMovimiento]= {};
                          alertas[alerta.idMovimiento]["fa-bell"]= [];
                          //alertas[alerta.idMovimiento]["icono"]= "message-square"; 
                          alertas[alerta.idMovimiento]["fa-bell"].push({alert: alerta, color: "blue", tipo: "Desconocido", tiempo: '00:00:00' });
                        }                    
                    }else{
                    alertas[alerta.idMovimiento]= {};
                    alertas[alerta.idMovimiento]["fa-bell"]= [];
                    alertas[alerta.idMovimiento]["fa-bell"].push({alert: alerta, color: "blue", tipo: "Desconocido", tiempo: '00:00:00' });
                    }                
                  } 
                }                
              } return alerta;
            });
            return alertas;
          }
        })
      }).catch(errors => console.log(errors));

}

  export default AlertasN;
