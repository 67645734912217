import * as ACTION_TYPES from '../actions/action_types'

const initialState = {
                       alertasCategorias_object: [],
                            alertasTipos_object: [],

                                 bloques_object: [],
                                destinos_object: [],

                                informes_object: [],
                 informesFuncionalidades_object: [],
                            rutasEstatus_object: [],
                              categorias_object: [],
                                clientes_object: [],
                              corredores_object: [],
                             localidades_object: [],
                localidadesTransportistas_object:[],
                remolquesTransportistas_object:[],
                remolquesTipo_object:[],
                               etiquetas_object: [],
                         funcionalidades_object: [],
                                 eventos_object: [],
                         incidenciasTipo_object: [],
                                personas_object: [],
                             plataformas_object: [],
                         plataformasTipo_object: [],
                                  reglas_object: [],

                                     gps_object: [],
                                gpsFijos_object: [],
                             gpsHardware_object: [],
                              gpsEstatus_object: [],
                       gpstipocolocacion_object: [],


                     movimientos_naviera_object: [],
                  movimientos_terrestres_object: [],
                         movimientosTipo_object: [],

                             privilegios_object: [],
                             incidencias_object: [],
                                usuarios_object: [],
                           usuarioperfil_object: [],
                                perfiles_object: [],
                       clientescontactos_object: [],
                          transportistas_object: [],
                                     otd_object:[],
                            acumuladores_object:[],
                       bitacoraMonitoreo_object:[],
                          salasMonitoreo_object:[],

                     operacionesClientes_object:[],
                      operacionesEstatus_object:[],
                         operacionesTipo_object:[],
                              operadores_object:[],
                        etaConfiguracion_object:[],

                                unidades_object: [],
                          unidadesmarcas_object:[],
                              unidadTipo_object: [],
                         unidadElementos_object: [],
                  unidadEstatusOperacion_object: [],
                    unidadTipoColocacion_object: [],
                         alertasUnidades_object: [],     
                                 update_mensaje: false,
                          serviceFailure_object: []
}

const Catalogos_reducer = (state = initialState, action) => {
    switch(action.type){
        case ACTION_TYPES.UPDATE_MENSAJE:                                       return { ...state, update_mensaje: action.payload }
        case ACTION_TYPES.ELEMENTOS_ALERTASUNIDADES_IMPUT:                      return { ...state, alertasUnidades_object: action.payload }
        case ACTION_TYPES.ElEMENTOS_ALERTASCATEGORIAS_IMPUT:                    return { ...state, alertasCategorias_object: action.payload }
        case ACTION_TYPES.ElEMENTOS_ALERTASTIPOS_IMPUT:                         return { ...state, alertasTipos_object: action.payload }

        case ACTION_TYPES.ElEMENTOS_BLOQUES_IMPUT:                              return { ...state, bloques_object: action.payload }

        case ACTION_TYPES.ElEMENTOS_DESTINOS_IMPUT:                             return { ...state, destinos_object: action.payload }

        case ACTION_TYPES.ELEMENTOS_MOVIMIENTOS_NAVIERA_IMPUT:                  return { ...state, movimientos_naviera_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_MOVIMIENTOS_TERRESTRES_IMPUT:               return { ...state, movimientos_terrestres_object: action.payload }

        case ACTION_TYPES.ELEMENTOS_MOVIMIENTOSTIPO_IMPUT:                      return { ...state, movimientosTipo_object: action.payload }

        case ACTION_TYPES.ElEMENTOS_INFORMES_IMPUT:                             return { ...state, informes_object: action.payload }

        case ACTION_TYPES.ElEMENTOS_INFORMESFUNCIONALIDADES_IMPUT:              return { ...state, informesFuncionalidades_object: action.payload }

        case ACTION_TYPES.ELEMENTOS_RUTASESTATUS_IMPUT:                         return { ...state, rutasEstatus_object: action.payload }

        case ACTION_TYPES.ELEMENTOS_GPSTIPOCOLOCACION_IMPUT:                    return { ...state, gpstipocolocacion_object: action.payload }

        case ACTION_TYPES.ELEMENTOS_CATEGORIAS_IMPUT:                           return { ...state, categorias_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_CLIENTES_IMPUT:                             return { ...state, clientes_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_CORREDORES_IMPUT:                           return { ...state, corredores_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_LOCALIDADES_IMPUT:                          return { ...state, localidades_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_ETIQUETAS_IMPUT:                            return { ...state, etiquetas_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_EVENTOS_IMPUT:                              return { ...state, eventos_object: action.payload }

        case ACTION_TYPES.ELEMENTOS_FUNCIONALIDADES_IMPUT:                      return { ...state, funcionalidades_object: action.payload }

        case ACTION_TYPES.ELEMENTOS_GPSFIJOS_IMPUT:                             return { ...state, gpsFijos_object: action.payload }

        case ACTION_TYPES.ELEMENTOS_GPSESTATUS_IMPUT:                           return { ...state, gpsEstatus_object: action.payload }

        case ACTION_TYPES.ELEMENTOS_INCIDENCIASTIPO_IMPUT:                      return { ...state, incidenciasTipo_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_PERSONAS_IMPUT:                             return { ...state, personas_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_PLATAFORMAS_IMPUT:                          return { ...state, plataformas_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_PLATAFORMASTIPO_IMPUT:                      return { ...state, plataformasTipo_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_REGLAS_IMPUT:                               return { ...state, reglas_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_PRIVILEGIOS_IMPUT:                          return { ...state, privilegios_object: action.payload }
        case ACTION_TYPES.ElEMENTOS_USUARIOS_IMPUT:                             return { ...state, usuarios_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_INCIDENCIAS_IMPUT:                          return { ...state, incidencias_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_GPSHARDWARE_IMPUT:                          return { ...state, gpsHardware_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_PERFILES_IMPUT:                             return { ...state, perfiles_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_CLIENTESCONTACTOS_IMPUT:                    return { ...state, clientescontactos_object: action.payload }
        case ACTION_TYPES.ElEMENTOS_USUARIOPERFIL_IMPUT:                        return { ...state, usuarioperfil_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_GPS_IMPUT:                                  return { ...state, gps_object: action.payload }
        case ACTION_TYPES.ElEMENTOS_REMOLQUESTRANSPORTISTAS_IMPUT:              return { ...state, remolquesTransportistas_object: action.payload }
        case ACTION_TYPES.ElEMENTOS_LOCALIDADESTRANSPORTISTAS_IMPUT:            return { ...state, localidadesTransportistas_object: action.payload }
        case ACTION_TYPES.ElEMENTOS_REMOLQUESTIPO_IMPUT:                        return { ...state, remolquesTipo_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_TRANSPORTISTAS_IMPUT:                       return { ...state, transportistas_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_OTD_IMPUT:                                  return { ...state, otd_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_NAVIERAS_IMPUT:                             return { ...state, navieras_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_ACUMULADORES_IMPUT:                         return { ...state, acumuladores_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_BITACORAMONITOREO_IMPUT:                    return { ...state,  bitacoraMonitoreo_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_SALASMONITOREO_IMPUT:                       return { ...state, salasMonitoreo_object: action.payload }

        case ACTION_TYPES.ELEMENTOS_OPERACIONESCLIENTES_IMPUT:                  return { ...state, operacionesClientes_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_OPERACIONESESTATUS_IMPUT:                   return { ...state, operacionesEstatus_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_OPERACIONESTIPO_IMPUT:                      return { ...state, operacionesTipo_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_OPERADORES_IMPUT:                           return { ...state, operadores_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_ETACONFIGURACION_IMPUT:                     return { ...state, etaConfiguracion_object: action.payload }

        case ACTION_TYPES.ElEMENTOS_UNIDADES_IMPUT:                             return { ...state,unidades_object: action.payload }

        case ACTION_TYPES.ElEMENTOS_UNIDADTIPO_IMPUT:                           return { ...state, unidadTipo_object: action.payload }
        case ACTION_TYPES.ElEMENTOS_UNIDADELEMENTO_IMPUT:                       return { ...state, unidadElementos_object: action.payload }
        case ACTION_TYPES.ElEMENTOS_UNIDADESTATUSOPERACION_IMPUT:               return { ...state, unidadEstatusOperacion_object: action.payload }

        case ACTION_TYPES.ELEMENTOS_UNIDADES_MARCAS_IMPUT:                      return { ...state, unidadesmarcas_object: action.payload }
        case ACTION_TYPES.ElEMENTOS_UNIDADTIPOCOLOCACION_IMPUT:                 return { ...state, unidadTipoColocacion_object: action.payload }
        case ACTION_TYPES.ELEMENTOS_SERVICEFAILURE_IMPUT:                       return { ...state, serviceFailure_object: action.payload }
        
        default:
        return state
    }
}
export default Catalogos_reducer;
