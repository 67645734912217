import React, { useState, useEffect } from "react";

export const LoadingScreen = ({ loading }) => {

    const [show, setShow] = React.useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShow(true);
        }, 50)

        return () => clearTimeout(timeout);
    }, [show]);

    if (!show) return null;

    return (  
		<div>
            {loading ? (
                 <div className="loading-container">
                    <img src="/images/loader.gif"></img>
                </div>
            ) : null}
        </div>
    );
};