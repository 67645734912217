import React from 'react';
import * as ACTIONS from '../store/actions/actions'
import { connect } from 'react-redux';
import ModalLayout from "./ModalLayout.react";
import { Crud_Catalogos, Crud_error } from '../herramientas/Crud_Catalogos';
import { Form, Grid, Card, Table, Alert, Icon, Dimmer, Button } from "tabler-react";
import { Exportar } from '../herramientas/Excel';
import Select from "react-select";
import { TablePageable } from "../Helpers";
import MonitoreoMensajeria from '../socket/MonitoreoMensajeria.react';
var user = JSON.parse(sessionStorage.getItem("usuario"));
var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
function myFormato(date) {
  function pad(number) {
    if (number < 10) { return '0' + number; }
    return number;
  }
  return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate()) + ' ' + pad(date.getHours()) + ':' + pad(date.getMinutes()) + ':' + pad(date.getSeconds());
}

class Catalogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      elementos: this.props.elementos || [],
      tableheaderItems: [],
      test: this.categorias_object,
      hiddenModals: false,//Cierra o No el Modal
      readyData: false,
      message: "",
      ErrorMessage: [],
      showErrormessage: false,
      selectedRow: -1,
      alertasT: {},
      gpsT: {},
      plataforma: { value: 0, label: "Todas ...", search: "" },
      funcionalidadesCol: [],
      search: "",
      metodo: "get",
      colToFilter : "origen", //columna a filtrar
      AscDesc: true,
      topics: []
    };
    this.usuariosLogObject = {
      "id":0,
      "idBitacoraMonitoreo":0,
      "idUsuario":0,
      "idMovimiento":0,
      "accionFecha":"",
      "descripcion":"",
      "idPlataformaTipo":2
    };
    this.ErrorMessage = "";
    this.ErrorMessageArray = [];
    this.catalogo = this.props.Ctlogo;
    this.catalogoTem = "";
    this.Catalogo_form = this.props.FCtlogo;
    this.tamanio = this.props.tamanio;
    this.updateStatus_elementos = this.updateStatus_elementos.bind(this);
    this.getfuntion = this.getfuntion.bind(this);
    this.content = [];
    //this.colum = this.props.colum | [];
    this.columna = [];
    this.fila = [];
    this.get_Store_Elements = null;
    this.update_Store_Elements = null;
    this.hiddenmodal = this.hiddenmodal.bind(this);
    this.getMensage = false;
    this.movimientoudated = true;
    this.plataformas = [];
    this.mensaje= { "mensaje": "", "plataforma": "", "corredor": "", "vista": "", "data": null, "accion": "get" };
    this.movimientoToUpdate= null;
  }

  estaPrivilegioActivo = (clave)=> {
    var privilegios= this.props.privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0;  i<privilegios.length; i++) {
      if(privilegios[i].clave === clave){ if(perfil.privilegios.filter(word => word.idPrivilegio === privilegios[i].id).length > 0 ){return true}else{ return false }
      }
    }
  }

  filter(event){
    var text = event.target.value
    const data = this.state.productoBackup
    const newData = data.filter(function(item){
        const itemDataTitle = item.titulo.toUpperCase()
        const itemDataDescp = item.descripcion.toUpperCase()
        const campo = itemDataTitle+" "+itemDataDescp
        const textData = text.toUpperCase()
        return campo.indexOf(textData) > -1
    })
    this.setState({
        producto: newData,
        text: text,
    })
  }

  componentDidUpdate = () => {
    if (this.props.movimientoToUpdate !== this.movimientoToUpdate && this.props.movimientoToUpdate !== null) {
      this.movimientoToUpdate= this.props.movimientoToUpdate;
      let movimientos= this.props.movimientos_terrestres_object;
      if( this.movimientoToUpdate !== null ){
        let indice= 0;
        for( let item of movimientos){
          if (item.id === this.props.movimientoToUpdate.id) {
            item = this.props.movimientoToUpdate;
            this.props.movimientos_terrestres_update(movimientos);
            this.setState({ elementos: movimientos });
            this.props.setMovimiento( item );
            this.setState({ metodo: "put"});
            this.setState({ selectedRow: indice });
            this.mensaje.data= this.props.movimientoToUpdate;
            this.mensaje.vista= this.catalogo;
            this.mensaje.corredor= this.props.movimientoToUpdate.corredor.id;
              this.mensaje.plataforma= this.props.movimientoToUpdate.plataforma.id;

            if(item.operacionEstatus.nombre.toUpperCase() === "TERMINADO" ){
              this.mensaje.accion= "delete";
              this.mensaje.mensaje = "Se termina Mov("+this.props.movimientoToUpdate.id+", plataforma: "+this.props.movimientoToUpdate.plataforma.nombre+")";
            }else{
              this.mensaje.accion= "put";
              this.mensaje.mensaje = "Actualización Mov("+this.props.movimientoToUpdate.id+", plataforma: "+this.props.movimientoToUpdate.plataforma.nombre+")";
            }
            this.props.updateStatusMensaje(true);
            break
          }
          indice++;
        }
      }
    }
    if (this.props.alertasT !== this.state.alertasT) { this.setState({ alertasT: this.props.alertasT }); }
    if (this.props.gpsTerrestreReciente !== this.state.gpsT) { this.setState({ gpsT: this.props.gpsTerrestreReciente }); }

    if (this.state.funcionalidadesCol !== this.props.columnas) {
      this.setState({ funcionalidadesCol: this.props.columnas });
    }
  }
  updateMessage = (sms) => { this.setState({ message: sms }); }

  componentDidMount = () => {
    var topics = [];
    for (let item of this.props.usuariosCorredores) {
      topics.push("/topic/" + item.idPlataforma + "-" + item.idOperacionCliente);
    }
    this.setState({ topics: topics });

    if (this.catalogo !== "" || this.catalogo !== null) {
      Object.keys(this.props.catalogos).forEach(
        key => {
          if (this.catalogo === this.props.catalogos[key]) {
          }
          switch (this.props.catalogos[key]) {
            case "terrestreMovimientos":
              {
                this.update_Store_Elements = this.props.movimientos_terrestres_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key] + "/usuario/" + user.id, this.update_Store_Elements);
                break;
              }
            case "clientes":
              {
                this.update_Store_Elements = this.props.clientes_update;
                this.getfuntion("get", [], "", [], "catalogos/" + this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "movimientosTipo":
              {
                this.update_Store_Elements = this.props.movimientosTipo_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "navieras":
              {
                this.update_Store_Elements = this.props.navieras_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "transportistas":
              {
                this.update_Store_Elements = this.props.transportistas_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "otd":
              {
                this.update_Store_Elements = this.props.otd_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "plataformas":
              {
                this.update_Store_Elements = this.props.plataformas_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "rutasEstatus":
              {
                this.update_Store_Elements = this.props.rutasEstatus_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "corredores":
              {
                this.update_Store_Elements = this.props.corredores_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "funcionalidades":
              {
                this.update_Store_Elements = this.props.funcionalidades_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "privilegios":
              {
                this.update_Store_Elements = this.props.privilegios_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }

            case "operacionesClientes":
              {
                this.update_Store_Elements = this.props.operacionesClientes_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "operacionesEstatus":
              {
                this.update_Store_Elements = this.props.operacionesEstatus_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "operacionesTipo":
              {
                this.update_Store_Elements = this.props.operacionesTipo_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "operadores":
              {
                this.update_Store_Elements = this.props.operadores_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }
            case "unidades":
              {
                this.update_Store_Elements = this.props.unidades_update;
                this.getfuntion("get", [], "", [], this.props.catalogos[key], this.update_Store_Elements)
                break;
              }

            default: return;
          }
        }
      )
    }
    this.plataformas.push({ value: 0, label: "Todas ...", search: "" });
    currentUser.map((usr, index) => {
      if(usr.plataforma.plataformaTipo.nombre === 'Terrestre'){
        this.plataformas.push({ value: usr.plataforma.id, label:usr.plataforma.nombre, search: usr.plataforma.nombre });
      } return usr;
    })
  }
  hiddenmodal = () => { this.setState({ hiddenModals: !this.state.hiddenModals }); }

  getfuntion = (metodo = "get", params = [], id = "", arraytoUpdate = [], catalogoTem = this.catalogo, storeFunction = this.update_Store_Elements) => {
    //let idsTemp = JSON.parse("{"+ params +"}");
    this.usuariosLogObject.idPlataformaTipo = 2;
    let objTemp = params;
    if (catalogoTem !== "") {
      Crud_Catalogos(catalogoTem, this.catalogo, metodo, id, params, storeFunction, this.state.elementos, this.hiddenmodal, arraytoUpdate).then((returnVal) => {
        this.updateStatus_elementos();
        this.mensaje.accion= metodo
        this.mensaje.vista= catalogoTem;
        this.setState({ metodo: metodo })
        /**************************************************************/
        switch (metodo) {
          case "get":
            {
              if (catalogoTem === this.catalogo + "/usuario/" + user.id) {
                this.setState({ readyData: true });
              }
              break;
            }
          case "post":
            {
              this.mensaje.data= returnVal;
              this.mensaje.corredor= returnVal.corredor.id;
              this.mensaje.plataforma= returnVal.plataforma.id;
              this.mensaje.mensaje = "Nuevo Movimiento Terrestre ("+returnVal.id+", plataforma: "+returnVal.plataforma.nombre+")";
              this.props.updateStatusMensaje(true);
              this.usuariosLogObject.idBitacoraMonitoreo = 1;
              this.usuariosLogObject.idUsuario = user.id;
              this.usuariosLogObject.idMovimiento = returnVal.id;
              this.usuariosLogObject.idPlataformaTipo = 2;
              this.usuariosLogObject.accionFecha = myFormato(new Date());
              this.usuariosLogObject.descripcion = "Nuevo Movimiento Terrestre Post id=" + returnVal.id;
              Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject, "", "", this.hiddenmodal, "");
              break;
            }
          case "put":
            {
              this.mensaje.data= returnVal.data;
              this.mensaje.corredor= returnVal.data.corredor.id;
              this.mensaje.plataforma= returnVal.data.plataforma.id;
              this.mensaje.mensaje = "Actualización ("+returnVal.data.id+", plataforma "+returnVal.data.plataforma.nombre+")";
              this.props.updateStatusMensaje(true);
              this.usuariosLogObject.idBitacoraMonitoreo = 2;
              this.usuariosLogObject.idUsuario = user.id;
              this.usuariosLogObject.idMovimiento = objTemp.id;//returnVal.id;
              this.usuariosLogObject.idPlataformaTipo = 2;
              this.usuariosLogObject.accionFecha = myFormato(new Date());
              this.usuariosLogObject.descripcion = "Actualizacion de Movimiento Terrestre Put id:" + objTemp.id;//returnVal.id;
              if (objTemp.operacionEstatus.id === 2) {
                this.usuariosLogObject.idBitacoraMonitoreo = 3;
                this.usuariosLogObject.descripcion = "Actualizacion de Movimiento Terrestre Put id:" + objTemp.id + " Editar Movimiento";
              }
              if (objTemp.operacionEstatus.id === 3) {
                this.usuariosLogObject.idBitacoraMonitoreo = 3;
                this.usuariosLogObject.descripcion = "Actualizacion de Movimiento Terrestre Put id:" + objTemp.id + " Actualizacion de Horario";
              }
              if (objTemp.operacionEstatus.id === 4) {
                this.usuariosLogObject.idBitacoraMonitoreo = 4;
                this.usuariosLogObject.descripcion = "Actualizacion de Movimiento Terrestre Put id:" + objTemp.id + " Atender Alerta";
              }
              //if (returnVal.idOperacionEstatus = 5) {
              if (objTemp.operacionEstatus.id === 5) {
                this.usuariosLogObject.idBitacoraMonitoreo = 5;
                this.usuariosLogObject.descripcion = "Actualizacion de Movimiento Terrestre Put id:" + objTemp.id + " Movimiento Finalizado";
              }
              Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject, "", "", this.hiddenmodal, "");
              break;
            }
          case "delete":
            {
              this.usuariosLogObject.idBitacoraMonitoreo = 10;
              this.usuariosLogObject.idUsuario = user.id;
              this.usuariosLogObject.accionFecha = myFormato(new Date());
              this.usuariosLogObject.descripcion = "Delete Movimiento Terrestre: id:" + objTemp.id;
              Crud_Catalogos("usuariosLog", this.catalogo, "post", "", this.usuariosLogObject, "", "", this.hiddenmodal, "");
              break;
            }
          default: return;
        }
      })
        .catch(err => {
          console.log("Error: " + err);
          if (err.response) {
            this.setState({ readyData: false });
            this.setState({ showErrormessage: true });
            this.ErrorMessage = Crud_error(err.response.status, catalogoTem);
            this.ErrorMessageArray.push(<Alert type="danger" icon="alert-octagon" > {this.ErrorMessage} </Alert>);
            this.setState({ ErrorMessage: this.ErrorMessageArray });
          }
          else {
            this.setState({ readyData: false });
            this.setState({ showErrormessage: true });
            this.ErrorMessage = Crud_error(404, catalogoTem);
            this.ErrorMessageArray.push(<Alert type="danger" icon="alert-octagon" > {this.ErrorMessage} </Alert>);
            this.setState({ ErrorMessage: this.ErrorMessageArray });
          }
        })
    }
  }
  updateStatus_elementos = (vista) => {
    this.setState({ elementos: this.props.movimientos_terrestres_object });
    this.props.setMovimientosTerrestres(this.props.movimientos_terrestres_object);
  }
  changePlataformas = (event) => {
    this.setState({ plataforma: event });
  }
  sortElements=(columna) =>{
    if(this.state.colToFilter === columna){
      this.setState({ AscDesc: !this.state.AscDesc });
    }else{ this.setState({ AscDesc: true }); }


    this.setState({colToFilter: columna });

    var originalArray= this.state.elementos;
    if( this.state.AscDesc ){
      originalArray= originalArray.sort(function(a,b) {
        if (a[columna] > b[columna]) {  return  1;  }
        if (a[columna] < b[columna]) {  return -1;  }

        return 0;
      });
    }else{
      originalArray= originalArray.sort(function(a,b) {
        if (a[columna] < b[columna]) {  return 1;   }
        if (a[columna] > b[columna]) {  return -1;  }

        return 0;
      });
    }
    this.setState({ elementos: originalArray });

  }
  objectFilter =(columna) =>{
    if(columna){
      if( columna.nombre ){ return columna.nombre;  }else{ return columna; }
    }else{return "" }
  }
  getFuncionalidad=(elemento)=>{
      let movActivo = this.state.elementos.filter( word =>
                        word.plataforma.nombre.includes(this.state.plataforma.search) &&
                        ( !Number.isInteger(word[this.state.colToFilter])? //Si se filtra texto
                            this.objectFilter(word[this.state.colToFilter]).toUpperCase().includes(this.state.search.toUpperCase())
                            : this.state.search !==""?
                              parseInt(word[this.state.colToFilter], 10) === parseInt(this.state.search, 10)? true : false //Si se filtra numero o boolean
                          : true
                        )
      ).sort()[this.state.selectedRow];
      if(movActivo){
          if(movActivo.plataforma){
            if(this.props.PlataformasPerfiles[movActivo.plataforma.id]) {
              let funcionalidadesTemp= this.props.PlataformasPerfiles[movActivo.plataforma.id].funcionalidades;
              if(!funcionalidadesTemp){
                return false;
              }
              for(let item of funcionalidadesTemp){
                if(item.nombre === elemento ){
                  return true;
                }
              }
            }
          }
        }
    //}
    return false;
  }


  crud = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = ""
  ) => {
    return Crud_Catalogos(
      catalogo,
      "movimientos",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            if (stateVar === "camposLogisticos") {
              return returnVal;
            }
            if( stateVar === "remolquesTipo"){
              for(let r of returnVal){
                this.remolques[r.idRemolque]= r;
              }
            }
            if (stateVar === "urlGps") {
              window.open(
                returnVal.url,
                "sharer",
                "width=900,height=600,scrollbars=NO"
              );
            }
            if (stateVar === "entregas") {
              this.setState({ loadingEntregas: false });
              if (returnVal) {
                this.setState({ entregas: returnVal });
              } else {
                this.setState({ entregas: [] });
              }
              return returnVal;
            }
            if (stateVar === "informes") {
              let informesTemp = {};
              for (let item of returnVal) {
                if (item.id === 3) {
                  //  3 equipos vacios
                  informesTemp[item.id] = item;
                  this.crud(
                    "get",
                    "",
                    "",
                    "funcionalidades/informe/" + item.id,
                    "funcionalidades3"
                  );
                }
                if (item.id === 4) {
                  //  4 Exportar Reporte AD-HOC C4
                  informesTemp[item.id] = item;
                  this.crud(
                    "get",
                    "",
                    "",
                    "funcionalidades/informe/" + item.id,
                    "funcionalidades4"
                  );
                }
                if (item.id === 69) {
                  //  4 Exportar Reporte AD-HOC C4
                  informesTemp[item.id] = item;
                  this.crud(
                    "get",
                    "",
                    "",
                    "funcionalidades/informe/" + item.id,
                    "funcionalidades69"
                  );
                }
                if (item.id === 70) {
                  //  4 Exportar Reporte AD-HOC C4
                  informesTemp[item.id] = item;
                  this.crud(
                    "get",
                    "",
                    "",
                    "funcionalidades/informe/" + item.id,
                    "funcionalidades70"
                  );
                }
              }
              this.setState({ informes: informesTemp });
            }
            if (stateVar === "funcionalidades3" && returnVal) {
              this.setState({ informe3: returnVal });
              let movsTemp = [];
              this.state.elementos.map((m, index) => {
                movsTemp[index] = {};
                for (let item of returnVal) {
                  movsTemp[index][item.label] = m[item.nombre];
                }
                return m;
              });
              this.setState({ reporte3: movsTemp });
            }
            if (stateVar === "funcionalidades4" && returnVal) {
              this.setState({ informe4: returnVal });
              let movsTemp = [];
              this.state.elementos.map((m, index) => {
                movsTemp[index] = {};
                for (let item of returnVal) {
                  movsTemp[index][item.label] = m[item.nombre];
                }
                return m;
              });
              this.setState({ reporte4: movsTemp });
            }
            if (stateVar === "funcionalidades69" && returnVal) {
              this.setState({ informe69: returnVal });
              let movsTemp = [];
              this.crud(
                "get",
                [],
                "",
                "navieraMovimientos/movimientosImportacionSemanal_c4/" +
                  new Date().getFullYear() +
                  "/" +
                  new Date().getWeekNumber(),
                "data"
              ).then((movs) => {
                if (movs) {
                  movs.map((m, index) => {
                    movsTemp[index] = {};
                    for (let item of returnVal) {
                      movsTemp[index][item.label] = m[item.nombre];
                    }
                    return m;
                  });
                  this.setState({ reporte69: movsTemp });
                }
              });
            }
            if (stateVar === "funcionalidades70" && returnVal) {
              this.setState({ informe70: returnVal });
              let movsTemp = [];
              this.crud(
                "get",
                [],
                "",
                "navieraMovimientos/movimientosExportacionSemanal_c4/" +
                  new Date().getFullYear() +
                  "/" +
                  new Date().getWeekNumber(),
                "data"
              ).then((movs) => {
                if (movs) {
                  movs.map((m, index) => {
                    movsTemp[index] = {};
                    for (let item of returnVal) {
                      movsTemp[index][item.label] = m[item.nombre];
                    }
                    return m;
                  });
                  this.setState({ reporte70: movsTemp });
                }
              });
            }
            return returnVal;
          case "post":
            if (stateVar === "serviceFailure") {
              this.hiddenmodal();
              return returnVal;
            }
            break;
          case "put":
            if (stateVar === "updateFechas") {
              this.hiddenmodal();
              let movsTemp = [...this.state.elementos];
              for (let i = 0; i < movsTemp.length; i++) {
                if (movsTemp[i].id === returnVal.id) {
                  movsTemp[i].contenedor = returnVal.contenedor;
                  movsTemp[i].sello = returnVal.sello;
                  this.setState({ elementos: movsTemp });
                  this.movsReport = movsTemp;
                  break;
                }
              }
              NotificationManager.info(
                "Se ha actualizado mov: " + returnVal.id,
                "Actualizacion"
              );
              let cambios = this.getCambiosNav(id, returnVal); //id= old Movimiento, retunVal= Actualizado // {bitacora: bitacora , camposmodificados: camposmodificados }
              this.usuariosLogObject.idBitacoraMonitoreo = cambios.bitacora;
              this.usuariosLogObject.idUsuario = user.id;
              this.usuariosLogObject.idMovimiento = returnVal.id;
              this.usuariosLogObject.idPlataformaTipo = 1;
              this.usuariosLogObject.accionFecha = myFormato(new Date());
              this.usuariosLogObject.descripcion = cambios.camposmodificados; //en id vienen los campos modificados
              this.crud(
                "get",
                "",
                "",
                "camposLogisticos/plataforma/" +
                  returnVal.idPlataforma.id +
                  "/mtipo/" +
                  returnVal.idMovimientoTipo.id,
                "camposLogisticos"
              ).then((returnVal1) => {
                this.camposLogisticosNav = returnVal1;
                Crud_Catalogos(
                  "usuariosLog",
                  catalogo,
                  "post",
                  "",
                  this.usuariosLogObject
                );
              });
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        if (stateVar === "entregas") {
          this.setState({ loadingEntregas: false });
        }
        console.log(err);
      });
  };

  tracking = (mov, gps) => {
    this.crud(
      "get",
      "",
      "",
      "navieraMovimientos/plataforma/" +
      mov.movimientoGps[0].gps.plataforma.idGg +
        "/gps/" +
        mov.movimientoGps[0].gps.idGg,
      "urlGps"
    );



  };

  obtenerColumnas=()=>{
    let columnasTemp= [
      /*
        { 
          columna: "id",
          label: "Id",
          //enClick:(e) => setCurrentMovimiento(e), //enClickDisabled: (e)=> false,//e.idSolicitud === 303? false: true,
          //form: (e)=> <i className="fa fa-edit" style={{"font-size": "24px"}}><p style={{fontSize: "16px"}}>{e.idSolicitud}</p></i>
        },
        { columna: "idMovimiento", label: "idMovimiento" },
        { columna: "po", label: "Po", splitBy:";" },
      */
    ];
    for(let c of this.props.columnas){
      switch (c.columna) {
        case "contenedorTipo":
          columnasTemp.push({ columna: c.columna, label: c.label,
            form: (e)=> e[c.columna] === 1? "Seco": e[c.columna] === 2? "Refrigerado": "Caja" });
          break;
          case "deRegreso":
            columnasTemp.push({ columna: c.columna, label: c.label,
              form: (e)=> <Form.Switch type="Checkbox" name="toggle" value={e[c.columna]} checked={(e[c.columna]===0)?false:true} className={"disabled"} /> });
            break;
          case "checklist":
            columnasTemp.push({ columna: c.columna, label: c.label,
              form: (e)=> <Form.Switch type="Checkbox" name="toggle" value={e[c.columna]} checked={(e[c.columna]===0)?false:true} className={"disabled"} /> });
            break;
          case "custodia":
            columnasTemp.push({ columna: c.columna, label: c.label,
              form: (e)=> <Form.Switch type="Checkbox" name="toggle" value={e[c.columna]} checked={(e[c.columna]===0)?false:true} className={"disabled"} /> });
            break;
          case "gps":
            console.log("des-"+JSON.stringify(this.state.elementos[0]));
           // columnasTemp.push({ columna: c.columna, label: c.label,form: (e)=> <Form.Label>{e.movimientoGps[0].gps.nombre}</Form.Label>   });
           //columnasTemp.push({ columna: c.columna, label: c.label,form: (e)=> <Form.Label>{this.state.elementos[0].movimientoGps?this.state.elementos[0].movimientoGps[0].gps.nombre:"sin gps"}</Form.Label>   });


           columnasTemp.push({ columna: c.columna, label: c.label,form: (e)=>            
           <div
            className="badge" data-toggle="tooltip" data-placement="top" title="Tracking"
            style={{color: "green", "font-size": "100%",}}
            onClick={() => this.tracking( e, e.movimientoGps[0].gps) }
          > {e.movimientoGps[0].gps.nombre}
            <span className="fa fa-map-marker float-left" />

                                   
          </div>   });




           //columnasTemp.push({ columna: c.columna, label: c.label,form: (e)=> <Form.Label>{this.state.gpsT[1357862]?this.state.gpsT[1357862]:"sin gps"}</Form.Label>   });

            //   if(this.state.gpsT){
            //   this.colum.push(
            //     <Table.Col>
            //     {this.state.gpsT[elemento.id]?
            //     <div>
            //       <div  className="badge" style={{ color: this.state.gpsT[elemento.id].iconBattery.color, "font-size": "80%" }}>
            //         { this.state.gpsT[elemento.id].nombre }
            //       </div>
            //       <div  className="badge" data-toggle="tooltip" data-placement="top" title={ this.state.gpsT[elemento.id].nombre+", "+this.state.gpsT[elemento.id].nivel_bateria+" %" }
            //                                     style={{ color: this.state.gpsT[elemento.id].iconBattery.color, "font-size": "70%" }}>
            //         <span className={this.state.gpsT[elemento.id].iconBattery.icono}></span>
            //       </div>
            //       <div  className="badge" data-toggle="tooltip" data-placement="top" title={ this.state.gpsT[elemento.id].ultimo_reporte }
            //                                     style={{ color: this.state.gpsT[elemento.id].iconTime.color, "font-size": "70%" }}>
            //         <span className={this.state.gpsT[elemento.id].iconTime.icono}></span>
            //       </div>
            //     </div>
            //     :
            //     <div></div>
            //     }
            //     </Table.Col>)
            // }else{
            //   this.colum.push(<Table.Col></Table.Col>);
            // }
          break;
        default:
          columnasTemp.push({ columna: c.columna, label: c.label,});
          break;
      }
    }
    return columnasTemp;
  }
  render() {
    return (
      <Grid.Col lg={12}>
        <div className="m-3">{/*
          <MonitoreoMensajeria topic={this.state.topics} mensaje={this.mensaje} elementosT={this.state.elementos} storeFunction={this.update_Store_Elements}
            updateT={this.updateStatus_elementos} openmodal={this.state.hiddenModals} metodo={this.state.metodo} stor={this.props}
          />*/}
        </div>

        <Card>
          <Card.Header>
            <div className="container-fluid">
              <nav className="navbar navbar-expand-lg navbar-light">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item active">
                    <a href={ () => false} >
                      <div className="float-right" style={{ "width": "160px" }}>
                        <Select value={this.state.plataforma} onChange={this.changePlataformas} options={this.plataformas} />
                      </div>
                    </a>
                  </li>
                  { this.getFuncionalidad("Exportar")?
                      <li className="nav-item active">
                        <a href={ () => false} >
                          <div className="float-right" style={{ "width": "45px" }}>
                            <Exportar crud={this.getfuntion} catalogo={this.catalogo} filtro={"/usuario/"+user.id } //actuales={this.state.elementos}
                            />
                          </div>
                        </a>
                      </li>
                    : <li></li>}
                    { this.estaPrivilegioActivo("movimientos_agregar") ?
                        <li className="nav-item active">
                          <a href={ () => false} >
                          <ModalLayout hiddenModal={this.state.hiddenModals} Fclose={this.hiddenmodal}
                            title="Nuevo" name={<Button pill size="sm" outline color="primary"><Icon name="plus" /></Button>}
                            tamanio={this.tamanio} formulario={<this.Catalogo_form accion="nuevo" elementos={this.state.elementos[0]} getfuntion={this.getfuntion} stor={this.props} />} />
                          </a>
                        </li>
                    : <li></li>}
                </ul>
              </nav>
            </div>
            <div className="w-5"></div>


          </Card.Header>
          <Card.Body>
            <Dimmer active={!this.state.readyData} loader>
            <div className="bg-light"  style={{ height: "100%"}}>{console.log(this.obtenerColumnas())}
              <TablePageable
                titulo={<h3> Movimientos </h3>}
                lista={ this.state.elementos.filter(m=> this.state.plataforma.value?  m.idPlataforma.id === this.state.plataforma.value : true ) }
                columnas={ this.obtenerColumnas()
                  /*[ Modificar  para acciones
                    { 
                      columna: "id",
                      label: "Id",
                      //enClick:(e) => setCurrentMovimiento(e), //enClickDisabled: (e)=> false,//e.idSolicitud === 303? false: true,
                      //form: (e)=> <i className="fa fa-edit" style={{"font-size": "24px"}}><p style={{fontSize: "16px"}}>{e.idSolicitud}</p></i>
                    },
                    { columna: "idMovimiento", label: "idMovimiento" },
                  ]*/
                }
                id="id"
                selected={(e) => this.setState({ movimiento: e}) }
                csvname="Solicitudes"
                //resaltarFuncion={(e)=> !e.idMovimiento? true: false}
                //resaltarColor={"rgba(223, 58, 58, 0.47)"}
              />
            </div>
            </Dimmer>
          </Card.Body>
        </Card>
        <div hidden={!this.state.showErrormessage}>{this.state.ErrorMessage}</div>
      </Grid.Col>
    );

  }

  }
function mapStateToProps(state) {
  return {
    movimientos_naviera_object: state.catalogos_reducer.movimientos_naviera_object,
    movimientos_terrestres_object: state.catalogos_reducer.movimientos_terrestres_object,
    clientes_object: state.catalogos_reducer.clientes_object,
    movimientosTipo_object: state.catalogos_reducer.movimientosTipo_object,
    navieras_object: state.catalogos_reducer.navieras_object,
    operacionesClientes_object: state.catalogos_reducer.operacionesClientes_object,
    operacionesEstatus_object: state.catalogos_reducer.operacionesEstatus_object,
    operacionesTipo_object: state.catalogos_reducer.operacionesTipo_object,
    operadores_object: state.catalogos_reducer.operadores_object,
    transportistas_object: state.catalogos_reducer.transportistas_object,
    updateStatusMensaje_Object:state.catalogos_reducer.update_mensaje,
    otd_object: state.catalogos_reducer.otd_object,
    plataformas_object: state.catalogos_reducer.plataformas_object,
    rutasEstatus_object: state.catalogos_reducer.rutasEstatus_object,
    corredores_object: state.catalogos_reducer.corredores_object,
    funcionalidades_object: state.catalogos_reducer.funcionalidades_object,
    privilegios_object: state.catalogos_reducer.privilegios_object,
    unidades_object: state.catalogos_reducer.unidades_object,
  }
}
function mapDispachToProps(dispach) {
  return {
    movimientos_naviera_update: (elementos) => dispach(ACTIONS.elementos_movimientos_naviera_imput(elementos)),
    movimientos_terrestres_update: (elementos) => dispach(ACTIONS.elementos_movimientos_terrestres_imput(elementos)),
    clientes_update: (elementos) => dispach(ACTIONS.elementos_clientes_imput(elementos)),
    movimientosTipo_update: (elementos) => dispach(ACTIONS.elementos_movimientosTipo_imput(elementos)),
    navieras_update: (elementos) => dispach(ACTIONS.elementos_navieras_imput(elementos)),
    operacionesClientes_update: (elementos) => dispach(ACTIONS.elementos_operacionesClientes_imput(elementos)),
    operacionesEstatus_update: (elementos) => dispach(ACTIONS.elementos_operacionesEstatus_imput(elementos)),
    operacionesTipo_update: (elementos) => dispach(ACTIONS.elementos_operacionestipo_imput(elementos)),
    operadores_update: (elementos) => dispach(ACTIONS.elementos_operadores_imput(elementos)),
    updateStatusMensaje: (status) => dispach(ACTIONS.update_mensaje(status)),
    transportistas_update: (elementos) => dispach(ACTIONS.elementos_transportistas_imput(elementos)),
    otd_update: (elementos) => dispach(ACTIONS.elementos_otd_imput(elementos)),
    plataformas_update: (elementos) => dispach(ACTIONS.elementos_plataformas_imput(elementos)),
    rutasEstatus_update: (elementos) => dispach(ACTIONS.elementos_rutasEstatus_imput(elementos)),
    corredores_update: (elementos) => dispach(ACTIONS.elementos_corredores_imput(elementos)),
    funcionalidades_update: (elementos) => dispach(ACTIONS.elementos_funcionalidades_imput(elementos)),
    privilegios_update: (elementos) => dispach(ACTIONS.elementos_privilegios_imput(elementos)),
    unidades_update: (elementos) => dispach(ACTIONS.elementos_unidades_imput(elementos)),
  }
}
export default connect(mapStateToProps, mapDispachToProps)(Catalogo)
