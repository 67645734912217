import React, { Component } from "react";

import Tracking from "../../components/Tracking";

class TrackingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
                        plataformaTipo  : 2,
    };
  }

  componentWillMount=() =>{}
  componentDidMount =() => {}
  componentDidUpdate=()=>{}
  render() {
    return (
      <Tracking mtipo={this.state.plataformaTipo} />
    )
  }
}
export default TrackingPage;
