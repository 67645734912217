import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { timeToDatefull } from '../herramientas/DateFormat';
import Crud_Catalogos from '../herramientas/Crud_Catalogos';
import { Form, Grid, Icon } from 'tabler-react';
import { TablePageable } from '../Helpers';
import { LoadingScreen } from './LoadingScreen';

const IncidenciasClientes = ({ idPlataforma }) => {
  let inicioYear = new Date().getFullYear() + '-01-01';

  //const [ cliente, setCliente] = useState({ value: 0, label: "Selecciona un Cliente", data:{}});
  const [cliente, setCliente] = useState();
  const [listaClientes, setListaClientes] = useState([]);
  const [listaIncidenciasClientesTotales, setListaIncidenciasClientesTotales] =
    useState([]);
  const [listaIncidenciasCliente, setListaIncidenciasCliente] = useState([]);
  const [date1, setDate1] = useState(timeToDatefull(new Date().getTime()));
  const [date2, setDate2] = useState(timeToDatefull(new Date().getTime()));
  const [bandera, setBandera] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    crud('get', '', '', 'cliente/plataforma/' + idPlataforma, 'listaClientes');
    refrescar();
  }, [idPlataforma]);

  const refrescar = async (e) => {
    setIsLoading(true);
    if (bandera === true) {
      await crud(
        'get',
        '',
        '',
        'movimientoIncidencias/clientes/plataforma/' + idPlataforma,
        'listaMovimientoIncidenciasClientesTotales'
      );
    }
    if (bandera === false) {
      getIncidenciasCliente(e);
    }
    setIsLoading(false);
  };

  const getIncidenciasCliente = async (e) => {
    setIsLoading(true);
    if (e && e.length) {
      //console.log("Entro: " + e.length);
      let cliens = '';
      let total = e.length;
      e.forEach((element, index) => {
        if (total === index + 1) {
          cliens += element.value;
        } else {
          cliens += element.value + ',';
        }
      });
      //console.log(cliens);
      if (date1 === timeToDatefull(new Date().getTime())) {
        //console.log("date: " + date1);
        await crud(
          'get',
          '',
          '',
          'movimientoIncidencias/plataforma/clientes?idClientes=' + cliens,
          'listaMovimientoIncidenciasCliente'
        );
      } else {
        //console.log("DATE: " + date1);
        await crud(
          'get',
          '',
          '',
          'movimientoIncidencias/plataforma/' +
            idPlataforma +
            '/clientes/' +
            cliens +
            '/fecha1/' +
            date1 +
            ' 00:00:00/fecha2/' +
            date2 +
            ' 23:59:59',
          'listaMovimientoIncidenciasCliente'
        );
      }
    } else {
      setDate1(inicioYear);
      setDate2(timeToDatefull(new Date().getTime()));
      setBandera(true);
      await crud(
        'get',
        '',
        '',
        'movimientoIncidencias/clientes/plataforma/' + idPlataforma,
        'listaMovimientoIncidenciasClientesTotales'
      );
    }
    setIsLoading(false);
  };

  const getByDates = async () => {
    setIsLoading(true);
    if (cliente && cliente.length) {
      //console.log("ENTRO: " + cliente.length);
      let cliens = '';
      let total = cliente.length;
      for (let i = 0; i < cliente.length; i++) {
        //console.log(cliente[i]);
        if (total === i + 1) {
          cliens += cliente[i].value;
        } else {
          cliens += cliente[i].value + ',';
        }
      }
      //console.log(cliens);
      await crud(
        'get',
        '',
        '',
        'movimientoIncidencias/plataforma/' +
          idPlataforma +
          '/clientes/' +
          cliens +
          '/fecha1/' +
          date1 +
          ' 00:00:00/fecha2/' +
          date2 +
          ' 23:59:59',
        'listaMovimientoIncidenciasCliente'
      );
    } else {
      await crud(
        'get',
        '',
        '',
        'movimientoIncidencias/clientes/plataforma/' +
          idPlataforma +
          '/fecha1/' +
          date1 +
          ' 00:00:00/fecha2/' +
          date2 +
          ' 23:59:59',
        'listaMovimientoIncidenciasClientesTotales'
      );
    }
    setIsLoading(false);
  };

  const changeDate1 = (e) => {
    let today = new Date(timeToDatefull(new Date().getTime())).getTime();
    let fecha = new Date(e.target.value).getTime();
    let fecha2 = new Date(date2).getTime();
    //console.log("date1: " + e.target.value);
    if (fecha > fecha2 && fecha < today) {
      setDate2(e.target.value);
    }
    if (fecha <= today) {
      setDate1(e.target.value);
    }
    //console.log("Date2: " + date2);
  };

  const changeDate2 = (e) => {
    let today = new Date(timeToDatefull(new Date().getTime())).getTime();
    let fecha = new Date(e.target.value).getTime();
    let fecha1 = new Date(date1).getTime();
    //console.log("date2: " + e.target.value);
    if (fecha < fecha1 && fecha < today) {
      setDate1(e.target.value);
    }
    if (fecha <= today) {
      setDate2(e.target.value);
    }
    //console.log("Date1: " + date1);
  };

  const getIncidencias = () => {
    if (bandera) {
      return listaIncidenciasClientesTotales;
    } else {
      return listaIncidenciasCliente;
    }
  };

  const crud = (
    metodo = 'get',
    obj = [],
    id = '',
    catalogo = '',
    stateVar = '',
    hiddenModl = ''
  ) => {
    return Crud_Catalogos(
      catalogo,
      '',
      metodo,
      id,
      obj,
      '',
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case 'get':
            if (stateVar === 'listaClientes' && returnVal) {
              let listaClientesTemp = [];
              returnVal.map((i, index) => {
                listaClientesTemp.push({
                  value: i.id,
                  label: i.nombre,
                  data: i,
                });
              });
              //console.log(returnVal);
              setListaClientes(listaClientesTemp);
            }
            if (
              stateVar === 'listaMovimientoIncidenciasClientesTotales' &&
              returnVal
            ) {
              let listaMovimientoIncidenciasClientesTotalesTemp = [];
              returnVal.map((i, index) => {
                listaMovimientoIncidenciasClientesTotalesTemp.push(i);
              });
              //console.log(returnVal);
              setListaIncidenciasClientesTotales(
                listaMovimientoIncidenciasClientesTotalesTemp
              );
            }
            if (stateVar === 'listaMovimientoIncidenciasCliente' && returnVal) {
              let listaMovimientoIncidenciasClienteTemp = [];
              returnVal.map((i, index) => {
                listaMovimientoIncidenciasClienteTemp.push(i);
              });
              //console.log(returnVal);
              setListaIncidenciasCliente(listaMovimientoIncidenciasClienteTemp);
            }
            break;
          case 'put':
            break;
          case 'post':
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="row form-row align-items-center ">
        <div class="col-2">
          <Select
            isMulti
            isClearable={true}
            value={cliente}
            onChange={(e) => {
              setCliente(e);
              getIncidenciasCliente(e);
              if (bandera) {
                setBandera(false);
              }
            }}
            options={listaClientes}
            closeMenuOnSelect={false}
          />
        </div>
        <div>
          <Grid.Row>
            <Grid.Col>
              <div>
                <span className="mr-1 btn text-dark" onClick={getByDates}>
                  <Icon name="refresh-cw" />
                </span>
                <span className="mr-1 float-right">
                  <Form.Input
                    name="date2"
                    type="date"
                    value={date2}
                    onChange={(e) => {
                      changeDate2(e);
                    }}
                  />
                </span>
                <span className="mr-1 ml-1 float-right">y</span>
                <span className="mr-1 float-right">
                  <Form.Input
                    name="date1"
                    type="date"
                    value={date1}
                    onChange={(e) => {
                      changeDate1(e);
                    }}
                  />
                </span>
              </div>
            </Grid.Col>
          </Grid.Row>
        </div>
        <br />
        <TablePageable
          titulo={''}
          lista={getIncidencias()}
          columnas={[
            { columna: 'tipo_movimiento', label: 'TIPO MOVIMIENTO' },
            { columna: 'id_movimiento', label: 'ID MOV' },
            { columna: 'nombre_cliente', label: 'CLIENTE' },
            { columna: 'contenedor', label: 'CONTENEDOR' },
            { columna: 'booking', label: 'BOOKING' },
            { columna: 'wo', label: 'WO' },
            { columna: 'nombre_incidencia', label: 'INCIDENCIA' },
            { columna: 'tipo_incidencia', label: 'TIPO INCIDENCIA' },
            { columna: 'comentario', label: 'COMENTARIO' },
            { columna: 'fecha_creado', label: 'FECHA CREACION' },
            { columna: 'latitud', label: 'LATITUD' },
            { columna: 'longitud', label: 'LONGITUD' },
          ]}
          id="id_movimiento_incidencia"
          csvname={'Incidencias Maersk por Cliente'}
          paginacion={true}
          elementosPorPagina={30}
        />
      </div>
      {isLoading ? <LoadingScreen loading={isLoading} /> : null}
    </>
  );
};

export default IncidenciasClientes;
