import React, { Component ,useState,useEffect,ReactDOM, Fragment} from "react";
import SiteWrapper from "../../../SiteWrapper.react";
import {Button, Icon,Dimmer,Table } from "tabler-react";

import ModalLayout from "../../../components/ModalLayout.react.js";
import TablePageable  from "./Table.react";
import TransportistasForm  from "../../../Catalogos/Transportistas/TransportistasForm";
import Call_Otds from "./Call_Otds";
import { NotificationManager} from 'react-notifications';
import Modal from "react-modal";
import CollapsibleTable from "./tableCollapsed"



const DetalleRemolques = (props) => {

  console.log(props)

  const plataforma = JSON.parse(sessionStorage.getItem("plataforma")).id;
  const usuario  = JSON.parse(sessionStorage.getItem("usuario")).id
  const transportista  = JSON.parse(sessionStorage.getItem("usuario")).id
  const [listaUnidades, setListaUnidades] = useState([]);
  const [listaColumns,setListaColumns] = useState();
  const [showComponent,setShowComponent] =  useState(false);
   //Carga de pantalla de carga
  const [isloading, setisLoading] = useState(false);
  const [data,setData] =  useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [idArrastreColumna,setIdArrastreColumna] =  useState({col: "", Desc: true});
  const [bitacoraTableHeader,setBitacoraTableHeader] =  useState([]);
  const [bitacora,setBitacora]= useState([]);
  const [loadingEntregas,setLoadingEntregas] = useState(false);
  const [hiddenModals,setHiddenModals] = useState(false);

  
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setShowComponent(false);
  };
  let dataTemp;

  const updateProps = (newValue,lista,unidades) =>{

    //console.log(newValue)
    openModal()
    let newValor = {listaRemolques:unidades,data:newValue,tiposRemolques:lista};
    let datosForm={
      accion: "nuevo",
      isEnable:"agregar",
      plataforma: {plataformaTipo:{id:plataforma}},
      getfuntion: getfuntion,
      tipo:2,
      stor: {privilegios_object:[],plataforma:plataforma,usuario:usuario,props:newValor},
      elementos:{}};
     setData(datosForm)
   
    
    
 }

 function BitacoraView ()
 {
  return (
    <div >
          <ModalLayout
            tamanio={"1200px"}
            title={"Bitacora de arrastre "}
            name={<Button pill size="sm" outline color="primary" 
            ><Icon name="list" />{"Bitacora de cambios ("+ ")"}</Button>}
            hiddenModal={hiddenModals} Fclose={hiddenmodal}
            formulario={
              <Dimmer active={loadingEntregas} loader>
                <Table cards={false} striped={false} responsive={true} className="table-vcenter" style={{color:"black", "font-size": "80%"}} > {/** headerItems={ state.incidenciatableheader} */}
                  <Table.Header>
                    {bitacoraTableHeader.map((item, i) =>
                        <Table.ColHeader key={i}>
                          {item.content}
                        </Table.ColHeader>)}
                  </Table.Header>{/* incidenciaColumna* */}
                  <Table.Body>
                    {
                  
                  //se ocultan bitacora de gps(7) ejp: Gps reportando sin velocidad
                  bitacora.length > 0&&  
                  bitacora.map((e, index) =>
                        <Table.Row >
                          <Table.Col>{e.idBitacoraMonitoreo}</Table.Col>
                          <Table.Col>{e.descripcion}</Table.Col>
                          <Table.Col>{e.idUsuario}</Table.Col>
                          <Table.Col>{e.accionFecha}</Table.Col>
                                                                            
                        </Table.Row>
                      )}
                    
                  </Table.Body>
                </Table>
              </Dimmer>                                    
            }
          />
        </div>
        )

 }
 
 
  
  useEffect(() => {
    
    getfuntion("get",{id:usuario},{}, "disponibilidadRemolquesTransportistas/remolques/transportistas",1);


    if(listaUnidades.length > 0){
      console.log(listaUnidades[0].remolquesTransportistas[0].idTransportistaLocalidades)
      getfuntion("get",{},{},"arrastreLog/idUsuario/"+usuario + "/idTransportista/"+ listaUnidades[0].remolquesTransportistas[0].idTransportistaLocalidades.idTransportista+  "/plataformatipo/1",3); 
    }

    // 
   
  
  },[data])

  const button = ((row)=>
  
    (<>
    <Button  size="sm" outline color="primary"  name="toggle" value={row.id}
       
       onClick={(e)=> updateProps(row)} ><Icon name="plus" /></Button>
    </>)
  )

  const getColumns = (afterData,lista)=>{

    
    let arrayColumns =  [];
    afterData.forEach(element => {
      
      element.button = button(element)
      arrayColumns.push(element)
      
    });
    
   setListaUnidades(arrayColumns)
  }

 const table_headers_bitacora = (asc, col) => {
  //console.log(col)
    const columsTemp=[
      { col:"idBitacoraMonitoreo", label: "Evento" },
      { col:"descripcion", label: "Detalle" },
      { col:"idUsuario", label: "N0mbre Usuario" },
      { col:"accionFecha", label: "Fecha" }
    ];
    let columnaTemp=[];
    for(let c of columsTemp){//incidenciaColumna: {col: "fechaCreado", Desc: true},
      columnaTemp.push({
        content: (
          <div  onClick={() =>  sortArrastre(c.col) } >
            { c.label }
            { col === c.col ? ( <Icon name={asc ? "chevron-down":"chevron-up"} />) : null}
          </div>
        ),
      });
    }
    setBitacoraTableHeader(columnaTemp);
  };


 const sortArrastre = (columna= idArrastreColumna.col) => {
    let oldCol = idArrastreColumna.col;
    let AscDescTemp= idArrastreColumna.Desc;
    if (oldCol === columna) {
      AscDescTemp = !AscDescTemp;
    } else {
      AscDescTemp = true;
    }

    setIdArrastreColumna ({col: columna, Desc: AscDescTemp});
    if (oldCol === columna) {
      table_headers_bitacora( AscDescTemp, columna );
    } else {
      table_headers_bitacora(true, columna);
    }

    var originalArray = [...bitacora];
    if (!originalArray[0]) return;

    if (AscDescTemp) {
      if (typeof originalArray[0][columna] == "object") {
        originalArray = originalArray.sort(function(a, b) {
          if (a[columna] === null || b[columna] === null) {
            return -1;
          } // si es nulo lo ordena al final
          if (a[columna].nombre < b[columna].nombre) {
            return 1;
          }
          if (a[columna].nombre > b[columna].nombre) {
            return -1;
          }
          return 0;
        });
      } else {
        originalArray = originalArray.sort(function(a, b) {
          if (a[columna] < b[columna]) {
            return 1;
          }
          if (a[columna] > b[columna]) {
            return -1;
          }
          return 0;
        });
      }
      //return a[columna]-b[columna] }); //Asecendente
    } else {
      if (typeof originalArray[0][columna] == "object") {
        originalArray = originalArray.sort(function(a, b) {
          if (a[columna] === null || b[columna] === null) {
            return 1;
          } // si es nulo lo ordena al final
          if (a[columna].nombre > b[columna].nombre) {
            return 1;
          }
          if (a[columna].nombre < b[columna].nombre) {
            return -1;
          }
          return 0;
        });
      } else {
        originalArray = originalArray.sort(function(a, b) {
          if (a[columna] > b[columna]) {
            return 1;
          }
          if (a[columna] < b[columna]) {
            return -1;
          }
          return 0;
        });
      }
    }
    setBitacora(originalArray);
  }

  const getfuntion = (
    metodo = "get",
    params,
    id = "",
    endPoint,
    type,
    afterData
  ) => {
    Call_Otds(
      endPoint,
      metodo,
      id,
      params,

    )
      .then((returnVal) => {
        setisLoading(false);
        switch (metodo) {
          case "get":
            {
              if (returnVal && type == 1 ) {
         
                getColumns(returnVal,"")
                //getfuntion("get",{id:plataforma},{}, "remolques/plataforma",2,returnVal);
                getfuntion("get",{},{},"arrastreLog/idUsuario/"+usuario + "/idTransportista/"+ returnVal[0].remolquesTransportistas[0].idTransportistaLocalidades.idTransportista+  "/plataformatipo/1",3); 
              }
              if(returnVal && type  == 2){
                getColumns(afterData,returnVal.remolquesTipos)
              }

              if(returnVal && type== 3){
                setBitacora(returnVal);

                setTimeout(() => {
                table_headers_bitacora(true, "fechaCreado");
                }, 100);
              }
              setisLoading(true);
            }
            break;
          case "post":{
            NotificationManager.info("Guardado correctamente");
            getfuntion("get",{id:usuario},{}, "disponibilidadRemolquesTransportistas/remolques/transportistas",1);
          } 
          break; 
          default:
            break;
        }
      })
      .catch((err) => {
        setisLoading(false);
        if (err.response) {
          console.log(err.response);
        } else {
        console.log("Error desconocido ...." + err);
        }
      });
  };

  const hiddenmodal = () => {
  
    setHiddenModals(!hiddenModals );
  };
  

  return (
    <SiteWrapper>

          <div className="container remolques-container">
          
          <div className="row">
          {listaUnidades && listaUnidades.length > 0 ? ( 
          <CollapsibleTable data={listaUnidades} temporizador={19} heads={["Localidad","Total Remolques","% Ocupacion","Registrar"]} ></CollapsibleTable>
        ):"Este usuario no esta ligado a un transportista"}      
          {/* <div className="col-12">
                {listaUnidades && listaUnidades.length > 0 ? ( 
                <TablePageable
                  lista={listaUnidades}
                  columnas={
                    listaColumns
                  }
                  id="corredor"
                  selected={() => { }}
                  titulo={<h3>{listaUnidades[0].nombre} </h3>}
                  csvname="Remolques Transportistas"
                  //selected={handle}
                  paginacion={true}
                  elementosPorPagina={4}
                  temporizador={20}
                  accion={"agregar"}
                  bitacora={<BitacoraView/>}

                />
              ):"Este usuario no esta ligado a un transportista"}   */} 
            

            <Modal
            closeTimeoutMS={50}
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className="Modal"
            overlayClassName="Overlay"
            style={
              props.resumirModal
                ? {
                    overlay: { display: "none" },
                    content: {
                      position: "relative",
                      top: "60px",
                      width:
                        window.screen.width > 900
                          ? "90%"
                          : window.screen.width - 20,
                      height: "auto",
                      margin: "auto",
                    },
                  }
                : {
                    overlay: { display: "block" },
                    content: {
                      position: "relative",
                      top: "60px",
                      width:
                        window.screen.width > 900
                          ? "90%"
                          : window.screen.width - 20,
                      height: "auto",
                      margin: "auto",
                    },
                  }
            }
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title"> {"Agregar"}</h4>
                <div class="btn-group">
                  {["Modificar", "Nuevo"].includes("Agregar") && (
                    <button
                      type="button"
                      className="btn btn-primary fa fa-minus"
                      onClick={props.funcionReanudar}
                    />
                  )}
                  <button
                    type="button"
                    className="btn btn-danger fa fa-close outline pill"
                    onClick={closeModal}
                  />
                </div>
              </div>
              <div className="modal-body">
                <TransportistasForm {...data} cerrarModal={closeModal} />
              </div>
            </div>
          </Modal>
      
              </div>
            </div>

            
				  {/* </div> */}
    </SiteWrapper>
      
    )
}
export default DetalleRemolques;
