import * as React from "react";
import * as ACTIONS from './actions/actions'
import { connect } from 'react-redux';
import Crud_Catalogos from '../herramientas/Crud_Catalogos';
/**
 * Se debe recibir como propiedaes array  de catalogos
 * ej.
 */

class LoadStore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
          count :  0,
      catalogos :  false
    };
  }
  componentDidMount=() =>{
    if( this.props.catalogos ){
      for( let item of this.props.catalogos){
        if( getStore( this.props, item ) !== [] ){ this.getfuntion("get", "", "", item); }
      }
      this.setState({ catalogos: true });
    }
  }

  getfuntion = (metodo="get", obj=[], id="", catalogo="caltalogo", stateVar= "variable de estado", hiddenModl="") =>{
    return Crud_Catalogos(catalogo, "loadStore", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        /*** filtro de variables  */
        this.setStore( catalogo, returnVal );
        //console.log( getStore( this.props, catalogo ) );
      }).catch(err =>{  if( err.response ){ console.log(err.response); }else{ console.log(err); }
    })
  }

  render() {
    return (<></>
    );
  }

  setStore =(catalogo, data)=>{
    switch(catalogo){
      case "alertasCategorias"        : this.props.alertasCategorias_update(data);        break;
      case "alertasTipos"             : this.props.alertasTipos_update(data);             break;

      case "bloque"                   : this.props.bloques_update(data);                  break;
      case "destinos"                 : this.props.destinos_update(data);                 break;

      case "informes"                 : this.props.informes_update(data);                 break;
      case "informesFuncionalidades"  : this.props.informesFuncionalidades_update(data);  break;
      case "movimientosTipo"          : this.props.movimientosTipo_update(data);          break;
      case "rutasEstatus"             : this.props.rutasEstatus_update(data);             break;
      case "categoria"                : this.props.categorias_update(data);               break;
      case "cliente"                  : this.props.clientes_update(data);                 break;
      case "clientesContactos"        : this.props.clientescontactos_update(data);        break;
      case "corredores"               : this.props.corredores_update(data);               break;
      case "funcionalidades"          : this.props.funcionalidades_update(data);          break;
      case "incidenciastipo"          : this.props.incidenciasTipo_update(data);          break;
      case "personas"                 : this.props.personas_update(data);                 break;
      case "perfil"                   : this.props.perfiles_update(data);                 break;
      case "plataformas"              : this.props.plataformas_update(data);              break;
      case "plataformasTipos"         : this.props.plataformasTipo_update(data);          break;
      case "privilegios"              : this.props.privilegios_update(data);              break;
      case "usuarios"                 : this.props.usuarios_update(data);                 break;
      case "usuarioPerfil"            : this.props.usuarioperfil_update(data);            break;
      case "incidencias"              : this.props.incidencias_update(data);              break;

      case "gps"                      : this.props.gps_update(data);                      break;
      case "gpsFijos"                 : this.props.gpsFijos_update(data);                 break;
      case "gpsHardware"              : this.props.gpsHardware_update(data);              break;
      case "gpsEstatus"               : this.props.gpsEstatus_update(data);               break;
      case "gpsTipoColocacion"        : this.props.gpstipocolocacion_update(data);        break;

      case "transportistas"           : this.props.transportistas_update(data);           break;
      case "otd"                      : this.props.otd_update(data);                      break;
      case "navieras"                 : this.props.navieras_update(data);                 break;
      case "acumuladores"             : this.props.acumuladores_update(data);             break;
      case "bitacoraMonitoreo"        : this.props.bitacoraMonitoreo_update(data);        break;
      case "salasMonitoreo"           : this.props.salasMonitoreo_update(data);           break;

      case "operacionesClientes"      : this.props.operacionesClientes_update(data);      break;
      case "operacionesEstatus"       : this.props.operacionesEstatus_update(data);       break;
      case "operacionesTipo"          : this.props.operacionesTipo_update(data);          break;
      case "operadores"               : this.props.operadores_update(data);               break;
      case "etaConfiguracion"         : this.props.etaConfiguracion_update(data);         break;

      case "unidades"                 : this.props.unidades_update(data);                 break;
      case "unidadesMarcas"           : this.props.unidadesmarcas_update(data);           break;
      case "unidadTipo"               : this.props.unidadTipo_update(data);               break;
      case "unidadElementos"          : this.props.unidadElementos_update(data);          break;
      case "unidadEstatusOperacion"   : this.props.unidadEstatusOperacion_update(data);   break;
      case "unidadTipoColocacion"     : this.props.unidadTipoColocacion_update(data);     break;

      default:
      break;

    }
  }
}
export function getStore (props, catalogo) { return props[catalogo]; }

function mapStateToProps(state) {
  return {
    acumuladore:              state.catalogos_reducer.acumuladores_object,

    alertasCategorias:        state.catalogos_reducer.alertasCategorias_object,
    alertasTipos:             state.catalogos_reducer.alertasTipos_object,

    bloque:                   state.catalogos_reducer.bloques_object,
    destinos:                 state.catalogos_reducer.destinos_object,
    unidades:                 state.catalogos_reducer.unidades_object,

    categoria:                state.catalogos_reducer.categorias_object,
    cliente:                  state.catalogos_reducer.clientes_object,
    corredores:                state.catalogos_reducer.corredores_object,
    funcionalidades:           state.catalogos_reducer.funcionalidades_object,
    informes:                  state.catalogos_reducer.informes_object,
    informesFuncionalidades:   state.catalogos_reducer.informesFuncionalidades_object,
    incidenciasTipo:           state.catalogos_reducer.incidenciasTipo_object,
    rutasEstatus:              state.catalogos_reducer.rutasEstatus_object,

    gps:                       state.catalogos_reducer.gps_object,
    gpsFijos:                  state.catalogos_reducer.gpsFijos_object,
    gpsEstatus:                state.catalogos_reducer.gpsEstatus_object,
    gpstipocolocacion:         state.catalogos_reducer.gpstipocolocacion_object,
    gpsHardware:               state.catalogos_reducer.gpsHardware_object,

    personas:                  state.catalogos_reducer.personas_object,
    plataformas:               state.catalogos_reducer.plataformas_object,
    plataformasTipo:           state.catalogos_reducer.plataformasTipo_object,
    privilegios:               state.catalogos_reducer.privilegios_object,
    usuarios:                  state.catalogos_reducer.usuarios_object,
    usuarioperfil:             state.catalogos_reducer.usuarioperfil_object,
    incidencias:               state.catalogos_reducer.incidencias_object,
    clientescontactos:         state.catalogos_reducer.clientescontactos_object,
    perfiles:                  state.catalogos_reducer.perfiles_object,
    transportistas:            state.catalogos_reducer.transportistas_object,
    otd:                       state.catalogos_reducer.otd_object,
    navieras:                  state.catalogos_reducer.navieras_object,
    movimientosTipo:           state.catalogos_reducer.movimientosTipo_object,
    bitacoraMonitoreo:         state.catalogos_reducer.bitacoraMonitoreo_object,
    salasMonitoreo:            state.catalogos_reducer.salasMonitoreo_object,
    unidadesmarcas:            state.catalogos_reducer.unidadesmarcas_object,
    operacionesClientes:       state.catalogos_reducer.operacionesClientes_object,
    operacionesEstatus:        state.catalogos_reducer.operacionesEstatus_object,
    operacionesTipo:           state.catalogos_reducer.operacionesTipo_object,
    operadores:                state.catalogos_reducer.operadores_object,
    etaConfiguracion:          state.catalogos_reducer.etaConfiguracion_object,
    updateStatusMensaje:       state.catalogos_reducer.update_mensaje,
    unidadEstatusOperacion:    state.catalogos_reducer.unidadEstatusOperacion_object,
    unidadTipoColocacion:      state.catalogos_reducer.unidadTipoColocacion_object,

  }
}
function mapDispachToProps(dispach) {
  return {
    acumuladores_update: (elementos)            => dispach(ACTIONS.elementos_acumuladores_imput(elementos)),

    alertasCategorias_update: (elementos)       => dispach(ACTIONS.elementos_alertasCategorias_imput(elementos)),
    alertasTipos_update: (elementos)            => dispach(ACTIONS.elementos_alertasTipos_imput(elementos)),

    bloques_update: (elementos)                 => dispach(ACTIONS.elementos_bloques_imput(elementos)),
    bitacoraMonitoreo_update: (elementos)       => dispach(ACTIONS.elementos_bitacoraMonitoreo_imput(elementos)),

    categorias_update: (elementos)              => dispach(ACTIONS.elementos_categorias_imput(elementos)),
    clientes_update: (elementos)                => dispach(ACTIONS.elementos_clientes_imput(elementos)),
    corredores_update: (elementos)              => dispach(ACTIONS.elementos_corredores_imput(elementos)),
    clientescontactos_update: (elementos)       => dispach(ACTIONS.elementos_clientescontactos_imput(elementos)),

    destinos_update: (elementos)                => dispach(ACTIONS.elementos_destinos_imput(elementos)),

    etaConfiguracion_update: (elementos)        => dispach(ACTIONS.elementos_etaConfiguracion_imput(elementos)),

    funcionalidades_update: (elementos)         => dispach(ACTIONS.elementos_funcionalidades_imput(elementos)),

    gps_update: (elementos)                     => dispach(ACTIONS.elementos_gps_imput(elementos)),
    gpsFijos_update: (elementos)                => dispach(ACTIONS.elementos_gpsFijos_imput(elementos)),
    gpstipocolocacion_update: (elementos)       => dispach(ACTIONS.elementos_gpstipocolocacion_imput(elementos)),
    gpsEstatus_update: (elementos)              => dispach(ACTIONS.elementos_gpsestatus_imput(elementos)),
    gpsHardware_update: (elementos)             => dispach(ACTIONS.elementos_gpshardware_imput(elementos)),

    informes_update: (elementos)                => dispach(ACTIONS.elementos_informes_imput(elementos)),
    informesFuncionalidades_update: (elementos) => dispach(ACTIONS.elementos_informesFuncionalidades_imput(elementos)),
    incidenciasTipo_update: (elementos)         => dispach(ACTIONS.elementos_incidenciastipo_imput(elementos)),
    incidencias_update: (elementos)             => dispach(ACTIONS.elementos_incidencias_imput(elementos)),

    movimientosTipo_update: (elementos)         => dispach(ACTIONS.elementos_movimientosTipo_imput(elementos)),
    navieras_update: (elementos)                => dispach(ACTIONS.elementos_navieras_imput(elementos)),

    otd_update: (elementos)                     => dispach(ACTIONS.elementos_otd_imput(elementos)),
    operacionesClientes_update: (elementos)     => dispach(ACTIONS.elementos_operacionesClientes_imput(elementos)),
    operacionesEstatus_update: (elementos)      => dispach(ACTIONS.elementos_operacionesEstatus_imput(elementos)),
    operacionesTipo_update: (elementos)         => dispach(ACTIONS.elementos_operacionestipo_imput(elementos)),
    operadores_update: (elementos)              => dispach(ACTIONS.elementos_operadores_imput(elementos)),

    personas_update: (elementos)                => dispach(ACTIONS.elementos_personas_imput(elementos)),
    plataformas_update: (elementos)             => dispach(ACTIONS.elementos_plataformas_imput(elementos)),
    plataformasTipo_update: (elementos)         => dispach(ACTIONS.elementos_plataformastipo_imput(elementos)),
    privilegios_update: (elementos)             => dispach(ACTIONS.elementos_privilegios_imput(elementos)),
    perfiles_update: (elementos)                => dispach(ACTIONS.elementos_perfiles_imput(elementos)),

    rutasEstatus_update: (elementos)            => dispach(ACTIONS.elementos_rutasEstatus_imput(elementos)),
    salasMonitoreo_update: (elementos)          => dispach(ACTIONS.elementos_salasMonitoreo_imput(elementos)),
    transportistas_update: (elementos)          => dispach(ACTIONS.elementos_transportistas_imput(elementos)),

    unidades_update: (elementos)                => dispach(ACTIONS.elementos_unidades_imput(elementos)),
    usuarios_update: (elementos)                => dispach(ACTIONS.elementos_usuarios_imput(elementos)),
    usuarioperfil_update: (elementos)           => dispach(ACTIONS.elementos_usuarioperfil_imput(elementos)),
    unidadesmarcas_update: (elementos)          => dispach(ACTIONS.elementos_unidadesmarcas_imput(elementos)),
    unidadEstatusOperacion: (elementos)         => dispach(ACTIONS.elementos_unidadEstatusOperacion_imput(elementos)),
    unidadTipoColocacion: (elementos)           => dispach(ACTIONS.elementos_unidadTipoColocacion_imput(elementos)),

    updateStatusMensaje: (status)               => dispach(ACTIONS.update_mensaje(status)),

  }
}

export default connect(mapStateToProps, mapDispachToProps)(LoadStore)
