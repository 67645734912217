import React, { useEffect, useState } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";
import Select from "react-select";
/**
 * Date tools
 */
import DateTimePicker from "react-datetime-picker";
import { timeToDateFill } from "../../../herramientas/DateFormat";
import Crud_Catalogos from "../../../herramientas/Crud_Catalogos";
let campo = "";

const EntregasNavierasForm =({entrega, movimiento, idPlataforma, setEntregasAMov, carga, setHiddenModals})=> {
  const [destinos, setDestinos]= useState([]);
  const [destino, setDestino]= useState({ value: 0, label: "Selecciona un destino" });
  const [entregaState, setEntregaState]= useState({
    idMovimiento: movimiento? movimiento.idMovimiento: null,
    idDestino: null,
    destino: "",
    direccion: "",
    cita: null,
    inicioRuta: null,
    fechaArribo: null,
    fechaSalida: null,
    fechaAlta: null,
    fechaCambio: null,
    latitud: 0.0,
    longitud: 0.0,
    estatus: 0,
    orden: 0,
    carga: carga,
  });
  const [disabledSave, setDisabledSave]= useState(true);
    
  useEffect(()=>{
    getfuntion("get", "", "", "destinos/plataforma/"+ idPlataforma.value, "destinos");
    if( entrega && entrega.idEntrega ){
      setEntregaState({...entrega});
    }
  },[]);

  const disableSave = () => {
    if( destino.value < 2 && entregaState.destino.length < 3 ){
      return true;
    }else{
      return false;
    }
  };
  const getfuntion = ( metodo = "get", obj = [], id = "", catalogo = "cliente", stateVar = "clientes", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, [])
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            if( stateVar === "destinos"){
              let destinosTemp=[{ value: 0, label: "Selecciona un destino", data: null}];
              for( let d of returnVal){
                destinosTemp.push({ value: d.id, label: d.nombre, data: d });
                if ( entrega &&entrega.idEntrega && d.id === entrega.idDestino ) {
                  setDestino(destinosTemp[destinosTemp.length-1]);
                }
              }
              setDestinos(destinosTemp);
            }
            break;
          case "post":
            if( stateVar === "movimiento_whatsapp_grupos_temporal" ){
            }
            break;
          case "put":
            if(stateVar === "solicitudes_cliente"){
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const borrar =()=> {
    if( entregaState && entregaState.idEntrega ){
      getfuntion(
        "delete",
        "",
        entregaState.idEntrega,
        "entregas_navieras",
        "entregas"
      );
    }
  }
  const crud_put =()=> {
    let entregaTemp= {...entregaState};
    entregaTemp.destino= entregaTemp.destino.trim();
    entregaTemp.direccion= entregaTemp.direccion.trim();
    const camposNombre= [
      {value: "cita", label: "Cita"},
      {value: "inicioRuta", label: "Inicio de ruta"},
      {value: "fechaArribo", label: "Fecha arribo"},
      {value: "fechaSalida", label: "Fecha salida"},
    ];
    for( const c of camposNombre ){
      if( entrega[c.value] !== entregaTemp[c.value] ){
        campo =( campo.length ? campo + ", " : campo)
          + c.label+": "
          + timeToDateFill(new Date(entrega[c.value]).getTime())
          +" a "+timeToDateFill(new Date(entregaTemp[c.value]).getTime());
      }
    }
    getfuntion(
      "put",
      entregaTemp,
      "",
      "entregas_navieras",
      "entregas"
    );
  }
  const crud_post =()=> {
    let entregaTemp= {...entregaState};
    entregaTemp.destino= entregaTemp.destino.trim();
    entregaTemp.direccion= entregaTemp.direccion.trim();
    getfuntion(
      "post",
      entregaState,
      "",
      "entregas_navieras",
      "entregas"
    );
  }
  return (
    <div>
      {
        <div>
            <h1> {entregaState.destino? entregaState.destino: ""} </h1>
          <Grid.Row>
            <Grid.Col md={12} xl={12}>
              <Grid.Row>
                <Grid.Col md={8} lg={8}>
                  <Form.Group label="Destino">
                    <Select
                      value={ destino}
                      onChange={ (e)=>{
                        setDestino(e);
                          setEntregaState({
                            ...entregaState,
                              idDestino:  e.value,
                              destino:    e.data.nombre,
                              direccion:  e.data.direccion,
                              latitud:    e.data.latitud,
                              longitud:   e.data.longitud,
                        });
                      }}
                      options={destinos}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Form.Group label="¿Es carga(consolidado) o descarga(desconsolidado)?">
                    <Form.Switch
                      type="Checkbox"
                      name="toggle"
                      label={entregaState.carga? "Es carga(consolidado)": "Es descarga(desconsolidado)"}
                      checked={entregaState.carga}
                      disabled={true}
                      //onChange={(e)=> setEntregaState({ ...entregaState, carga: e.target.checked }) }
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col md={8} lg={6}>
                  <Form.Group label="Nombre de la Entrega">
                    <Form.Input
                      type="text"
                      name="destino"
                      feedback="Al menos 6"
                      value={ entregaState.destino }
                      placeholder="Ingresa Nombre del Destino..."
                      invalid={ !entregaState.destino || entregaState.destino.length < 6 }
                      onChange={ (e)=> setEntregaState({ ...entregaState, destino: e.target.value }) }
                      disabled={destino.value}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col md={8} lg={6}>
                  <Form.Group label="Dirección">
                    <Form.Input
                      type="text"
                      name="direccion"
                      value={entregaState.direccion}
                      placeholder="Ingresa Dirección..."
                      onChange={ (e)=> setEntregaState({ ...entregaState, direccion: e.target.value }) }
                      disabled={destino.value}
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col md={6} lg={6}>
                  <Form.Group label="Latitud">
                    <Form.Input
                      type="number"
                      name="latitud"
                      value={entregaState.latitud}
                      placeholder="Ingresa Latitud..."
                      onChange={ (e)=> setEntregaState({ ...entregaState, latitud: e.target.value }) }
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col md={6} lg={6}>
                  <Form.Group label="Longitud">
                    <Form.Input
                      type="number"
                      name="longitud"
                      value={entregaState.longitud}
                      placeholder="Ingresa Longitud.."
                      onChange={ (e)=> setEntregaState({ ...entregaState, longitud: e.target.value }) }
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Form.Group label="Cita">
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={ entregaState.cita? new Date(entregaState.cita): null }
                      onChange={(e)=> setEntregaState({ ...entregaState, cita: timeToDateFill(e) }) }
                    />{" "}
                  </Form.Group>
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Form.Group label="Inicio Ruta">
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={entregaState.inicioRuta ? new Date(entregaState.inicioRuta): null}
                      onChange={(e)=> setEntregaState({ ...entregaState, inicioRuta: timeToDateFill(e) }) }
                    />{" "}
                  </Form.Group>
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Form.Group label="Fecha Arribo">
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={entregaState.fechaArribo? new Date(entregaState.fechaArribo): null}
                      onChange={(e)=> setEntregaState({ ...entregaState, fechaArribo: timeToDateFill(e) }) }
                    />
                  </Form.Group>
                </Grid.Col>
                <Grid.Col md={4} lg={4}>
                  <Form.Group label="Fecha Salida">
                    <DateTimePicker
                      format="y-MM-dd h:mm:ss a"
                      value={ entregaState.fechaSalida? new Date(entregaState.fechaSalida): null}
                      onChange={(e)=> setEntregaState({ ...entregaState, fechaSalida: timeToDateFill(e) }) }
                    />{" "}
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>
          <div className="float-right">
            <span class="badge">
              <Button
                size="sm"
                RootComponent="a"
                color="primary"
                disabled={ disableSave() }
                onClick={()=>{
                  setEntregasAMov(entregaState);
                  setHiddenModals(true);
                  /*
                  if ( entregaState && entregaState.idEntrega)
                    crud_put();
                  else
                    crud_post();*/
                }}
              >
                <span class="badge">
                  <Icon link={true} name="save" />
                  Guardar
                </span>
              </Button>
            </span>
            { entregaState.idEntrega ? (
              <span className="ml-2">
                <Button
                  pill
                  size="sm"
                  outline
                  color="primary"
                  onClick={() => borrar()}
                >
                  <Icon name="trash" />
                </Button>
              </span>
            ) : null}
          </div>
          <Grid.Col md={3} lg={3}>
            <Form.Group label="Entrega actual">
              <Form.Switch
                type="Checkbox"
                name="toggle"
                label={entregaState.estatus? "Entrega en curso(activa)": "Entrega pendiente"}
                checked={entregaState.estatus}
                onChange={(e)=> setEntregaState({ ...entregaState, estatus: e.target.checked }) }
              />
            </Form.Group>
          </Grid.Col>
        </div>
        //  :null
      }
    </div>
  );
}
export default EntregasNavierasForm;
