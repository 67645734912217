
import React, { Component } from "react";
import * as conf from "../config/config";
import SockJsClient from 'react-stomp';
import { NotificationManager } from 'react-notifications';
import axios from 'axios';

let instance = axios.create();
const username= 'WN8z55WbAzF?bgy!BW5S9VB9$N#$fmUPx!fDf3=ZJft3mY3c@b';
const password= 'DzuJVh%k&Msvr@=k2a?zZz#4CuZ55awXSYkVm?_a#+a@xQ!pDn';
class MonitoreoMensajeria extends Component {
  constructor(props) {
    super(props);
    this.state = {
        udateM: true
    }
    this.mensaje=[];
    this.postOrigin= false;
    this.logged= false;
  }
  
  componentWillMount =() =>{
    instance.defaults.headers.common['Authorization'] = 'Basic '+window.btoa(username+":"+password);
    axios.get(conf.socket+"basicauth").then((response)=>{}).catch(err => {
      console.log("Error: " + err);
      axios.get(conf.socket+"basicauth").then((response)=>{}).catch(err => {
        console.log("Error: " + err);
      });
    });
  }
  componentDidMount =() => { }

  componentDidUpdate =() =>{
      if(this.props.metodo !== "get"  && this.props.stor.updateStatusMensaje_Object && this.props.mensaje.data){        
        this.sendMessage(this.props.mensaje, this.props.mensaje.data)
      }
  }
  sendMessage = (msg, data) => { //plataforma Corredor
    this.props.stor.updateStatusMensaje(false);
    this.postOrigin= true;
    //if (this.eServidor()) {
      if(msg.vista === "navieraMovimientos"){
        this.clientRef.sendMessage("/topic/"+data.idPlataforma.id+"-"+data.idCorredor.id, JSON.stringify(msg));
      }
      if(msg.vista === "terrestreMovimientos"){
        this.clientRef.sendMessage("/topic/"+data.plataforma.id+"-"+data.corredor.id, JSON.stringify(msg));
      }
    //}
  }

  udate=(mensaje) => {
    if(this.props.mtipo !== 0){
      if(this.props.mtipo === 1){
      
      }
      if(this.props.mtipo === 2){
      
      }
    }
      if(mensaje.vista === "navieraMovimientos"){
        if(this.props.mtipo){
          if(this.props.mtipo === mensaje.data.idMovimientoTipo.id){            
          }else{ return;}
        }
        NotificationManager.info(mensaje.mensaje, 
          mensaje.accion === "put" ?  "Modificación":
          mensaje.accion === "post" ? "Nuevo":
          mensaje.accion === "delete" ? "Finalizar Movimiento":"Acción");
        let movimientos=  this.props.elementosN;
        if (mensaje.accion !== "post"){
          movimientos.map((elemento, index) =>{
            switch (mensaje.accion) {
              case "put":     if(elemento["id"] === mensaje.data.id){ movimientos[index]= mensaje.data } break;
              case "delete":  if(elemento["id"] === mensaje.data.id){ movimientos.splice(index, 1) }     break;          
              default:  break;
            } return null;
          });
        }
        if (mensaje.accion === "post" && this.postOrigin === false){ movimientos.unshift(mensaje.data); }else{ this.postOrigin= false; }
        this.props.stor.movimientos_naviera_update(movimientos);
        if( this.props.update !== "" ){ this.props.update(); }
      }
      if(mensaje.vista === "terrestreMovimientos"){
        NotificationManager.info(mensaje.mensaje, 
          mensaje.accion === "put" ?  "Modificación":
          mensaje.accion === "post" ? "Nuevo":
          mensaje.accion === "delete" ? "Finalizar Movimiento":"Acción");
        let movimientos=  this.props.elementosT;
        if (mensaje.accion !== "post"){
          movimientos.map((elemento, index) =>{
            switch (mensaje.accion) {
              case "put":     if(elemento["id"] === mensaje.data.id){ movimientos[index]= mensaje.data } break;
              case "delete":  if(elemento["id"] === mensaje.data.id){ movimientos.splice(index, 1) }     break;          
              default:  break;
            } return null;
          });
        }
        if (mensaje.accion === "post" && this.postOrigin === false){ movimientos.unshift(mensaje.data); }else{ this.postOrigin= false; }
        this.props.stor.movimientos_terrestres_update(movimientos);
        if( this.props.updateT !== "" ){ this.props.updateT(); }        
      }      
      this.udateM= false;
  }
  shouldSendMessage =(ban) => {
    this.setState({ udateM: ban });
  }

  eServidor = () => {
    axios.get(conf.socket+"basicauth").then((response)=>{
      var request = new XMLHttpRequest();
      request.open('GET', conf.socket+'suscribe', true);
      request.send();
      if (request.status === 0) {
        console.log("No Esta disponible el Servidor de Mensajes");
        return false;
      }else{
        return true;
      }

    }).catch(err => {
      console.log("Error: " + err);
    }); 
    
  }

  render() {
    return (
      <div>
        <SockJsClient url={ conf.socket+'suscribe/' }
            topics={this.props.topic}
            onMessage={(msg) => 
              { console.log(msg);
                //this.shouldSendMessage(false)
                this.udate(msg);
              }
            }
            ref={ (client) => { this.clientRef = client }}/>
    </div>
    );
  }
}
export default MonitoreoMensajeria;
