import React from 'react'
//import Button from 'react-bootstrap/Button';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const ExportCSV = ({csvData, fileName, title}) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToXLS = (csvData, fileName) => {
        //console.log("12 csvData");
        //console.log(csvData);
        //alert(csvData);
        //console.log("fileName");
        //console.log(fileName);
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'datos': ws }, SheetNames: ['datos'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
      <div className="exportar" id="plantilla">
        <p className="exportar" id="exportar">
          <center>
            <div variant="warning" onClick={(e) => exportToXLS(csvData,fileName)}>{title? title:"Exp"}</div>
          </center>
        </p>
        <div class="tiptext">Exportar a Excel</div>
      </div>
    )
}
