import React, {useEffect, useState}  from 'react';
import { Button, Form, Grid, Icon, Table } from "tabler-react";
import SiteWrapper from '../../../SiteWrapper.react';
import TablePageable from '../../../Helpers/TablePageable.react';
import Crud_Catalogos from '../../../herramientas/Crud_Catalogos';
import { timeToDatefull } from '../../../herramientas/DateFormat';
import ModalLay from '../../../components/ModalLay/ModalLay';
import DetalleMovimientoSubasta from './DetalleMovimientoSubasta.js';


var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
var plataforma = JSON.parse(sessionStorage.getItem("plataforma"));

const OrdenesClientePage = () => {

const [ movimientosClienteSubasta, setMovimientosClienteSubasta ] = useState([]);
const [ movimientosFinalizadosClienteSubasta, setMovimientosFinalizadosClienteSubasta ] = useState([]);
const [ rangoFechas, setRangoFechas ] = useState(false);
const [ activos, setActivos ] = useState(true);
const [ date1, setDate1] = useState(timeToDatefull(new Date().getTime()));
const [ date2, setDate2] = useState(timeToDatefull(new Date().getTime()));

useEffect(() => {
  refrescar();  
}, [activos]);

const refrescar = () => {
  if( activos === true){
    crud("get", "", "", "navieraMovimientos/subasta/" + plataforma.id  + "/" + currentUser[0].usuario.id, "movimientosClienteSubasta");
  }
  if( activos === false){
    crud("get", "", "", "navieraMovimientos/finalizadosSubastaYaerActual/" + plataforma.id  + "/" + currentUser[0].usuario.id, "movimientosFinalizadosClienteSubasta");
  }
}

const cambioActivosFinalizados = () => {  
  if(activos === true){
    setActivos(false);
  }else{
    setActivos(true);
  }
}

const getMovimientos = () => {
  if(activos){
    return movimientosClienteSubasta;
  } else {
    return movimientosFinalizadosClienteSubasta;
  }
}

const getByDates = () => {  
  /*if( activos === true){
    crud("get", "", "", "navieraMovimientos/subasta/" + plataforma.id  + "/" + + currentUser[0].usuario.id + "/fecha1/"+ date1 + " 00:00:00/fecha2/"+ date2 + " 23:59:59", "movimientosClienteSubasta");
  }*/                       
  if( activos === false){
    crud("get", "", "", "navieraMovimientos/finalizadosSubasta/" + plataforma.id  + "/" + + currentUser[0].usuario.id + "/fecha1/"+ date1 + " 00:00:00/fecha2/"+ date2 + " 23:59:59", "movimientosFinalizadosClienteSubasta");
  }                
}

const changeDate1 = (e) => {
let today= new Date(timeToDatefull(new Date().getTime())).getTime();
let fecha= new Date(e.target.value).getTime();
let fecha2= new Date(date2).getTime();
//console.log("date1: " + e.target.value);   
if(fecha > fecha2 && fecha < today){ setDate2( e.target.value ) }
if(fecha <= today ){ setDate1( e.target.value ) }       
//console.log("Date2: " + date2);
}

const changeDate2 = (e) => {    
let today= new Date(timeToDatefull(new Date().getTime())).getTime();
let fecha= new Date(e.target.value).getTime();
let fecha1= new Date(date1).getTime();
//console.log("date2: " + e.target.value);    
if(fecha < fecha1 && fecha < today){  setDate1( e.target.value ) }
if(fecha <= today ){ setDate2( e.target.value ) }    
//console.log("Date1: " + date1); 
}

const crud = (metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {    
  return Crud_Catalogos(catalogo, "", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
      switch (metodo) {
        case "get":             
        if(stateVar === "movimientosClienteSubasta" && returnVal) {
          setMovimientosClienteSubasta(returnVal);     
        }  
        if(stateVar === "movimientosFinalizadosClienteSubasta" && returnVal) {
          setMovimientosFinalizadosClienteSubasta(returnVal);     
        }      
          break;            
        default:
          break;
      }
  }).catch(err => { console.log(err); });
}

  return (
    <>    
    <div className='bg-light'>
      <SiteWrapper>               
      <br/>
      <h3 className="mr-1 px-6"> {activos ? "Movimientos Activos" : "Movimientos Finalizados"} </h3>       
      <Grid.Row> 
        <Grid.Col>         
          <div className="mr-1 px-6">  
            <span className="mr-1 px-6 float-left" data-toggle="tooltip" data-placement="top" title={activos ? "Estas viendo movimientos activos" : "Estas viendo movimientos finalizados"}>
              <Form.Switch type="Checkbox" checked={activos}  onChange={ cambioActivosFinalizados } />          
            </span>
            { !activos 
              ? <span className="mr-1 float-left"><Form.Input name="date1" type="date" value={ date1 } onChange={(e) => { changeDate1(e) }} disabled={ rangoFechas } /></span>
              : null
            }
            { !activos 
              ? <span className="mr-1 ml-1 float-left">y</span>
              : null
            }
            { !activos 
              ? <span className="mr-1 float-left"><Form.Input name="date2" type="date" value={ date2 } onChange={(e) => { changeDate2(e) }}  disabled={ rangoFechas } /></span>
              : null
            } 
            { !activos 
              ? <span className="mr-1 btn text-dark float-left" onClick={getByDates} ><Icon name="refresh-cw"/></span>
              : null
            }
          </div>                
        </Grid.Col>
      </Grid.Row>
      <TablePageable
            title={<></>}
            lista={getMovimientos()}
            columnas={[
              { columna: "detalle", label: "DETALLE",                  
                form: (e) =>  <ModalLay                  
                    tamanio={"90%"}
                    title="Detalle"
                    name={ 
                    <Button pill size="sm" outline color="primary">
                        <div
                        className={"fa fa-bell"}
                        style={{ color: "green" }}
                        ></div>
                    </Button>
                    }
                    formulario={DetalleMovimientoSubasta}
                    datosForm={{                                                                                                               
                        movimiento: e,                        
                        //refresh: refrescar                                                                        
                    }}
                />    
              },
              { columna: "id_movimiento", label: "NUMERO DE VIAJE" },
              { columna: "cliente", label: "NOMBRE CLIENTE" },
              { columna: "cita_programada_cliente", label: "CITA PROGRAMADA (CLIENTE)" },
              { columna: "cita_programada_terminal", label: "CITA PROGRAMADA (TERMINAL)" },
              { columna: "corredor", label: "ORIGEN" },
              { columna: "destino", label: "DESTINO" },
              { columna: "contenedor", label: "CONTENEDOR" },
              { columna: "estatus_subasta", label: "ESTATUS" },
              { columna: "transportista", label: "TRANSPORTISTA" },
            ]}
            id={"id_movimiento"}                        
            paginacion={true}
            elementosPorPagina={20}
        />        
      </SiteWrapper>
    </div>
    </>
  )
}

export default OrdenesClientePage;
