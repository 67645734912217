export function Forms(){ }
//valida correo
export function mail(email=""){
    var mail= false; var arroba= false; var domain= false;
    var mailChart= ""; var arrobaChart= ""; var domainChart= "";

    for (var i = 0; i < email.length; i++) {
      if( email.charAt(i) === " "){
        return false;
      }
      if(!arroba && !domain && email.charAt(i) === "@"){ 
        arrobaChart=arrobaChart+email.charAt(i); arroba= true;  
        if(mailChart.length > 2 ){ mail=true }
      }
      if(!arroba && !domain){ mailChart= mailChart+email.charAt(i); }
      if(mail && arroba && email.charAt(i) !== "@"){ domainChart= domainChart+email.charAt(i); }
    }
    if(domainChart.includes(".") && domainChart.length > 4 && domainChart.charAt(0) !== "." && domainChart.charAt(domainChart.length-1) !== "." ){ domain= true; }
      if(mail && arroba && domain ){
        return true;
      }else{ 
        return false;
    }
}

export function esFecha(fecha){
  if( !fecha )
    return false;
  const date = new Date(fecha);
  return date instanceof Date && !isNaN(date.valueOf());
}

export function text(txt= "", min= 0, max= 0){
    if(txt.length){
        if(txt.length >= min &&  txt.length <= max ){ return true;  }
        if(txt.length  < min ||  txt.length > max  ){ return false; }
    }else{ return false; }
}
export function disabledSave(valid){
    let band= false;
    Object.keys(valid).forEach(key =>{ if( !valid[key]){ band=true; } });
    if(band){ return true }else{ return false }
}


export default Forms;