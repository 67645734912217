import React, { Component } from "react";
import { Grid } from "tabler-react";
/**
 * SplitterLayout
 */
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';

import SiteWrapper from "../../SiteWrapper.react";
import EspecificoN from "../../components/EspecificoNaviero";
import EspecificoT from "../../components/EspecificoTerrestre";
import './MonitoreoPage.react.css';
import * as ACTIONS from '../../store/actions/actions'
import { connect } from 'react-redux';
import {GpsEstatusNaviero} from '../../Monitoristas/notificaciones/GpsEstatusNaviero';
import {GpsEstatusTerrestre} from '../../Monitoristas/notificaciones/GpsEstatusTerrestre';
import Crud_Catalogos from "../../herramientas/Crud_Catalogos";
import {AlertasN}   from '../../Monitoristas/notificaciones/AlertasN';
import {AlertasT}   from '../../Monitoristas/notificaciones/AlertasT';
import { EtasN }    from '../../Monitoristas/notificaciones/EtasN';

import { timeToMilisegundos } from "../../herramientas/DateFormat";
/**
 * Notificaciones
 */
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import LoadStore from "../../store/LoadStore";

var currentUser     = JSON.parse(sessionStorage.getItem("currentUser"));
let modulosMonitoreo= JSON.parse(localStorage.getItem('modulosMonitoreo'));
var colsN=[];
var colsT=[];
const enruta= [
  "Unidad en ruta vacía",
  "Unidad en ruta cargada",
  "En ruta a cliente vacío",
  "En ruta",
  "Reinicia Ruta",
];
const enpatio=["Unidad en patio de transporte"];
const encliente= [
  "Arribo a cliente",
  "continua en cliente",
 "Sin reporte de arribo a  cliente",
];
class Monitoreo extends Component {
  constructor(props) {
    super(props);
    this.state = {
                   PlataformasPerfiles  : {},
                     Monitoreo_Naviero  : [],
                     currentMovimiento  : {},
            currentMovimientoTerrestre  : {},
                    movimientoToUpdate  : null,
           movimientoToUpdateTerrestre  : null,
                           estatusRuta  : {},
                           headersDash  : [],
                              alertasN  : {},
                              alertasT  : {},
                                 etasN  : {},
                               //alertas  : {},
                             columnasN  : [],
                             columnasT  : [],
                    usuariosCorredores  : [],
                   usuariosCorredoresT  : [],
                        plataformaTipo  : null,
                    movimentosNavieros  : [],
                  movimentosTerrestres  : [],
                    gpsEstatusNavieros  : null,
                    movsGpsEstatus: [],
                  gpsEstatusTerrestres  : null,
                  gpsTerrestreReciente  : null,
                  gpsNavieroReciente   : null,
                                topics  : [],
                                topicsT : [],
                                metodo  : "get",
                     totalAlertCatSpan  : [],
                       MovimientosFull  : {},
                              semaforo  : 0,
                    tableroIncidencias  : [],
                    modulosMonitoreo    : modulosMonitoreo,
    };
    this.gpsPlataformaGPsgG={};
    this.funcionalidades=[];
    this.mensaje= { "mensaje": "", "plataforma": "", "corredor": "", "vista": "", "data": null, "accion": "get" };
    this.loadedN= false;
    this.loadedT= false;
    this.totalAlert= 0;
    this.totalAlertCat= [];
    this.totalAlertCatSpan=[];
    this.gpsFijos= [];
    this.movimentosNavieros= {};
  }

  componentWillMount=() =>{/** Aqui inicia, sin configuracion no carga nada */
    this.getPerfiles();
    if(modulosMonitoreo && modulosMonitoreo ){
      this.setState({ terrestres  : modulosMonitoreo.estatusT   });
      this.setState({ navieras    : modulosMonitoreo.estatusN   });
      this.setState({ modulosMonitoreo:  modulosMonitoreo});
    }else{
      let modulosMonitoreoTemp= {
        navieras: false,
        estatusN: false,
        terrestres: false,
        estatusT: false,
      };      
      for (let item of currentUser) {
        if(item){
          if(item.plataforma){
            if(item.plataforma.plataformaTipo && item.plataforma.plataformaTipo.id){
              if( [1,3].includes(item.plataforma.plataformaTipo.id) ){
                modulosMonitoreoTemp.navieras= true;
                modulosMonitoreoTemp.estatusN= true;
              }
              if( [2].includes(item.plataforma.plataformaTipo.id) ){
                modulosMonitoreoTemp.terrestres= true;
                modulosMonitoreoTemp.estatusT= true;
              }
            }
          }
        }
      }
      if(modulosMonitoreoTemp.navieras && modulosMonitoreoTemp.navieras.terrestres){
        modulosMonitoreoTemp.estatusN= false;
        modulosMonitoreoTemp.estatusT= false;        
      }
      this.setState({ modulosMonitoreo:  modulosMonitoreoTemp});
      localStorage.setItem('modulosMonitoreo', JSON.stringify(modulosMonitoreoTemp));
    }
  }

  getPerfiles=()=>{
    let perf= {};
    for (let item of currentUser) {
      if(item){
        if(item.perfil && item.plataforma){
          if( perf[item.perfil.id] ){
            if( !perf[item.perfil.id][item.plataforma.plataformaTipo.id] ){
              perf[item.perfil.id][item.plataforma.plataformaTipo.id]= [];
            }
            //perf[item.perfil.id][item.plataforma.plataformaTipo.id]= {perfil: item.perfil, plataforma: item.plataforma, funcionalidades: [] };
          }else{
            perf[item.perfil.id]= {};
            perf[item.perfil.id][item.plataforma.plataformaTipo.id]= [];
          }
          perf[item.perfil.id][item.plataforma.plataformaTipo.id]= {perfil: item.perfil, plataforma: item.plataforma, funcionalidades: [] };
        }
      }
    }
    Object.keys( perf ).forEach((key, indice) => {
      Object.keys( perf[key] ).forEach((key2, indice2) => {
        if(perf[key][key2].plataforma.plataformaTipo){
          if(perf[key][key2].plataforma.plataformaTipo.nombre === 'Terrestre'){
            this.getfuntion("get", "", "", "funcionalidades/Monitoreo_Terrestre/"+key, "Monitoreo_Terrestre").then((returnVal) => {
              perf[key][key2].funcionalidades= returnVal;
              let PlataformasPerfiles={};
              for (let item of currentUser) {
                if(item){
                  if(item.perfil && item.plataforma){
                    PlataformasPerfiles[item.plataforma.id]= {perfil: item.perfil, plataforma: item.plataforma, funcionalidades: perf[item.perfil.id][item.plataforma.plataformaTipo.id].funcionalidades };
                  }
                }
              }
              this.setState({ PlataformasPerfiles: PlataformasPerfiles });
            }).catch(err => { console.log(err); });
          }
          if(perf[key][key2].plataforma.plataformaTipo.nombre.toUpperCase().includes('NAVIERA') ){
            this.getfuntion("get", "", "", "funcionalidades/Monitoreo_Naviero/"+key, "Monitoreo_Naviero").then((returnVal) => {
              perf[key][key2].funcionalidades= returnVal;
              let PlataformasPerfiles={};
              for (let item of currentUser) {
                if(item){
                  if(item.perfil && item.plataforma){
                    PlataformasPerfiles[item.plataforma.id]= {perfil: item.perfil, plataforma: item.plataforma, funcionalidades: perf[item.perfil.id][item.plataforma.plataformaTipo.id].funcionalidades };
                    //PlataformasPerfiles[item.plataforma.id]= {perfil: item.perfil, plataforma: item.plataforma, funcionalidades: perf[key][key2].funcionalidades };
                  }
                }
              }
              this.setState({ PlataformasPerfiles: PlataformasPerfiles });
              this.setState({ Monitoreo_Naviero: perf[currentUser[0].perfil.id][ currentUser[0].plataforma.plataformaTipo.id ].funcionalidades });
            }).catch(err => { console.log(err); });
          }
        }
      });
    });
  }

  

  componentDidMount =() => {
    document.querySelector('body').style.backgroundImage = "url('images/imagenFondo.jpg')";
    document.querySelector('body').style.backgroundSize = "cover";
   //this.gpsNavieros();
  }
  componentDidUpdate=()=>{
    const modulosMonitoreoT= JSON.parse(localStorage.getItem('modulosMonitoreo'));
    if(this.props.funcionalidades_object !== this.funcionalidades && this.props.funcionalidades_object.length>0){
      this.funcionalidades= this.props.funcionalidades_object;
      this.getfuntion("get","", "", "perfilesFuncionalidades/"+currentUser[0].perfil.id);
    }
    if(this.state.movimentosNavieros.length>0 && !this.loadedN){
      this.loadedN= true;
      this.gpsNavieros();      
      EtasN(this.state.movimentosNavieros, this.props.mtipo).then((returnVal) => {
        this.setState({etasN: returnVal});
      }).catch(err => { console.log(err); });
      if(modulosMonitoreoT.estatusN){
        this.getfuntion("get","", "", "usuariosCorredores/usuario/"+currentUser[0].usuario.id, "usuariosCorredores");
      }
    }
    if(this.state.movimentosTerrestres.length>0 && !this.loadedT){
      this.loadedT= true;
      this.alertasT(this.state.movimentosTerrestres);
      this.gpsTerrestres();
      if(modulosMonitoreoT.estatusT){
        this.getfuntion("get","", "", "usuarioOperacionCliente/usuario/"+currentUser[0].usuario.id, "usuarioOperacionCliente");
      }
    }
  }

  /**
   * Actualizar gps
   */
      gpsNavieros=() =>{
        if(this.state.modulosMonitoreo.estatusN){/** Movimientos navieros  ,    fuancion que actualiza , Movimientos que estan en full    */
          GpsEstatusNaviero(
            this.getMovimientosNavieros(),
            this.setGpsEstatusNavieros,
            this.state.MovimientosFull,
            this.props.mtipo
          );
        }
        setTimeout(this.gpsNavieros,  1000*60);//1000*60*1
      }
      gpsTerrestres=() =>{
        if(this.state.modulosMonitoreo.estatusT){
          GpsEstatusTerrestre( this.getMovimientosTerrestres(), this.setGpsEstatusTerrestres, this.gpsFijos );
        }
        setTimeout(this.gpsTerrestres,  1000*60);//1000*60*1
      }
      /*** Actualiza gpsEstatusNavieros       */
      setGpsEstatusNavieros = (gpsestatus) => {
        if(gpsestatus && this.state.gpsEstatusNavieros !== gpsestatus){
          this.setState({ gpsEstatusNavieros: gpsestatus.gpsEstatus });
          this.setState({ gpsNavieroReciente: gpsestatus.gpsReciente });
          this.setState({ movsGpsEstatus: gpsestatus.movsGpsEstatus });

        }
      }
      getGpsEstatusNavieros = () => { return this.state.gpsEstatusNavieros  }
      setGpsEstatusTerrestres = (gpsestatus) => {
        if(gpsestatus.gpsEstatus){
          this.setState({ gpsEstatusTerrestres: gpsestatus.gpsEstatus });
          this.setState({ gpsTerrestreReciente: gpsestatus.gpsReciente });
        }
      }
  /**
   * Fin Gps
   */

  /**
   * Movimientos
   */
      getMovimientosNavieros   = ()            => {  return this.state.movimentosNavieros; }
      setMovimientosNavieros   = (movimientos) => {

        let movs= {};
        let estatusRuta= {};
        let headersDash= [{ content: "Activos"}, { content: "ontime"}, { content: "delay"}];
        let movFull=this.state.MovimientosFull;        
        for(let item of movimientos){
          if(this.state.currentMovimiento.id){
            if(item.id === this.state.currentMovimiento.id && item !== this.state.currentMovimiento ){
              this.setState({currentMovimiento: item});
            }
          }
          movs[item.id]= item;
          if(item.idRuta){
            estatusRuta[item.idRuta.nombre]= item.idRuta.nombre;
            let had= false;
            for(let h of headersDash){
              if( h.content === item.idRuta.nombre){ had= true; }
            }
            if(!had){ headersDash.push({content: item.idRuta.nombre }); }
          }
        }
        this.setState({estatusRuta: estatusRuta});
        this.setState({ headersDash: headersDash});

        this.getfuntion("get", "", "", "movimientosPatio/usuario/" + currentUser[0].usuario.id, "movPatio" ).then((returnVal) => {
          if(returnVal){
            returnVal.map((movPatio, index)=> movFull[movPatio.idMovimiento]= { movimiento: movs[movPatio.idMovimiento], full: movPatio.esFull} );
            var topics = [];
            topics.push("/topic/users/" + currentUser[0].usuario.username);
            topics.push("/topic/users");
            for (let item of movimientos) {
              if(item.movimientoGps){
                if( !item.movimientoGps[0] ){
                  if( movFull[item.id] ){
                    if( movFull[item.id].full ){
                      if( movs[movFull[item.id].full] ){
                        let mfull= movs[movFull[item.id].full];
                        if( mfull.movimientoGps[0] ){                          
                          //item.movimientoGps= mfull.movimientoGps;
                          for(let temmovGps of item.movimientoGps){
                            temmovGps.idMovimiento= item.id;
                          }                          
                          //item.movimientoGps.idMovimiento= item.id;
                          movs[item.id].movimientoGps= item.movimientoGps;//mfull.movimientoGps; //ACtualiza Movs Keys
                          movFull[item.id].movimiento= item;
                        }
                      }
                    }
                  }
                }
              }
              topics.push("/topic/" + item.id);
            }
            const contadores={
              ruta: movimientos.filter(m=> enruta.filter(i=> m.incidencia? m.incidencia.includes(i): false).length).length,
              patio: movimientos.filter(m=> enpatio.filter(i=> m.incidencia? m.incidencia.includes(i): false).length).length,
              cliente: movimientos.filter(m=> encliente.filter(i=> m.incidencia? m.incidencia.includes(i): false).length).length,
            };
            this.setState({
              tableroIncidencias: [
                { incidencia: "En ruta", count: contadores.ruta},
                { incidencia: "En patio", count: contadores.patio},
                { incidencia: "En cliente", count: contadores.cliente},
                { incidencia: "En espera de programación", count: (movimientos.length - contadores.ruta - contadores.patio - contadores.cliente)},
              ]
            });            
            this.movimentosNavieros= movs;
            this.setState({ MovimientosFull: movFull });
            this.setState({ movimentosNavieros: movimientos });
            this.setState({ topics: topics });
          }
        });
      }
      getMovimientoNavieroById = (id) => {
        return this.movimentosNavieros[id];
      }
      getMovimientosTerrestres = ()            => {  return this.state.movimentosTerrestres }
      setMovimientosTerrestres = (movimientos) => {
        if(movimientos !== this.state.movimentosTerrestres)
        {
          this.setState({ movimentosTerrestres:movimientos });
          var topicsT = [];
          for (let item of movimientos) { topicsT.push("/topic/" + item.id); }
          this.setState({ topicsT: topicsT });
          this.getfuntion("get", "", "", "gpsFijomov/usuario/"+currentUser[0].usuario.id, "gpsFijos");
        }
      }

      /** uPDATEmOVIMIENTO */
      updateMovimientoNaviero = (movimiento) => {
        this.setState({ movimientoToUpdate: movimiento });
      }
      updateMovimientoTerrestre = (movimiento) => {
        this.setState({ movimientoToUpdateTerrestre: movimiento });
      }
      /**Fin  uPDATEmOVIMIENTO */
      /** movimiento Naviero */
      getMovimientoNaviero = () => {
        return this.state.currentMovimiento;
      }
      setMovimientoNaviero = (movimiento) => {
        this.setState({ plataformaTipo: "Naviera" });
        this.setState({ currentMovimiento: this.movimentosNavieros[movimiento.id] });
        this.setState({ movimientoToUpdate: null });
        this.mensaje.data= null;
        this.setState({ metodo: "get" });
      }
      /**Fin movimiento Naviero */
      /**  Current Movimientos */
      getMovimientoTerrestre = () => {  return this.state.currentMovimientoTerrestre; }
      setMovimientoTerrestre = (movimiento) => {
        this.setState({ plataformaTipo: "Terrestre" });
        this.setState({ currentMovimientoTerrestre: movimiento });
        this.setState({ movimientoToUpdateTerrestre: null });
        this.mensaje.data= null;
        this.setState({ metodo: "get" });
      }
      /**  Fin Current Movimientos */

  /**
   * Fin Movimientos
   */

  /**
   * Alertas
   */
      setAlerta = (alerta, vista) => {
        this.mensaje.accion= "delete";
        this.mensaje.vista= vista;
        this.mensaje.data= alerta;
        this.mensaje.mensaje = "Se atiende alerta("+ alerta["triggerName"] +")";
        this.props.updateStatusMensaje(true);
        this.setState({ metodo: "put" });

      }
      updateAlertas = (data) =>{
        this.setState({ alertasN: data });
        this.alertasNCount();
      }

      alertasN=(movimientos) =>{
        AlertasN(movimientos, this.props.mtipo).then((returnVal) => {
          if(this.state.alertasN !== returnVal){this.setState({ alertasN: returnVal }); }
          this.alertasNCount();
        }).catch(err =>{
          console.log("ErrorN: " + err);
        });
      }

      alertasNCount=() =>{
        this.totalAlert= 0;
        this.totalAlertCat=[];
        this.totalAlertCatSpan=[];
        let semaforo= 0; //0,1, 2
        Object.keys( this.state.alertasN ).forEach(
          (key, indice) => {
            Object.keys( this.state.alertasN[key] ).forEach((key2, indice2) => {
              this.totalAlert= this.totalAlert + this.state.alertasN[key][key2].length
              if(this.totalAlertCat[key2]){
                this.totalAlertCat[key2]= this.totalAlertCat[key2]+this.state.alertasN[key][key2].length;
              }else{this.totalAlertCat[key2]= this.state.alertasN[key][key2].length}
              if(this.state.alertasN[key][key2]){
                //console.log(this.state.alertasN[key][key2][0].alert);
                if(this.state.alertasN[key][key2][0]){
                  let timemil= timeToMilisegundos(this.state.alertasN[key][key2][0].tiempo);
                  if(timemil > 0){
                    for(let alertas of this.state.alertasN[key][key2] ){
                      if( (new Date(alertas.alert.fechaCreacion).getTime()+timemil) < new Date().getTime() ){
                        semaforo= 2;
                      }else{
                        let diferencia= new Date(alertas.alert.fechaCreacion).getTime() - new Date().getTime();
                        if( (timemil-diferencia) < 2 && diferencia > 0 && semaforo < 2){
                          semaforo= 1;
                        }
                      }
                    }
                  }
                }
                //console.log(timeToMilisegundos(this.state.alertasN[key][key2][0].tiempo) )
              }
            })
          });
          this.setState({ semaforo: semaforo });
          Object.keys( this.totalAlertCat ).forEach((key, indice) => {
            this.totalAlertCatSpan.push({ categoria: key, val: this.totalAlertCat[key] }); //timeToMilisegundos
          });
          if(this.state.totalAlertCatSpan !== this.totalAlertCatSpan ){this.setState({totalAlertCatSpan: this.totalAlertCatSpan}); }
      }
      alertasT=(movimientos) =>{
        AlertasT(movimientos).then((returnVal) => {
          if(this.state.alertasT !== returnVal){ this.setState({ alertasT: returnVal }); }
          this.alertasTCount();
        }).catch(err =>{
          console.log("ErrorT: " + err);
        });
        //setTimeout(this.alertasT, 1000*60*4 );
      }
      alertasTCount=() =>{
        let TertotalAlert= 0;
        let TertotalAlertCat=[];
        let semaforo= 0; //0,1, 2
        Object.keys( this.state.alertasT ).forEach(
          (key, indice) => {
            Object.keys( this.state.alertasT[key] ).forEach((key2, indice2) => {
              if(!parseInt(key2, 10) ){
                TertotalAlert= TertotalAlert + this.state.alertasT[key][key2].length
                /*
                if(TertotalAlertCat[key2]){
                  TertotalAlert[key2]= TertotalAlertCat[key2]+this.state.alertasT[key][key2].length;
                }else{TertotalAlertCat[key2]= this.state.alertasT[key][key2].length}
                */
               if(!TertotalAlertCat[key2]){
                 TertotalAlertCat[key2]= this.state.alertasT[key][key2].length
                }
                if(this.state.alertasT[key][key2]){
                  //console.log(this.state.alertasT[key]);

                  let timemil= timeToMilisegundos(this.state.alertasT[key][key2][0].tiempo);
                  if(!parseInt(key2, 10) > 0){
                    for(let alertas of this.state.alertasT[key][key2] ){
                      //console.log(alertas.alert)
                      if( (new Date(alertas.alert.fechaCreacion).getTime()+timemil) < new Date().getTime() ){
                        semaforo= 2;
                      }else{
                        let diferencia= new Date(alertas.alert.fechaCreacion).getTime() - new Date().getTime();
                        if( (timemil-diferencia) < 2 && diferencia > 0 && semaforo < 2){
                          semaforo= 1;
                        }
                      }
                    }
                  }
                  //console.log(timeToMilisegundos(this.state.alertasN[key][key2][0].tiempo) )
                }
              }

            });
          });
          /*
          this.setState({ semaforo: semaforo });
          Object.keys( this.totalAlertCat ).forEach((key, indice) => {
            this.totalAlertCatSpan.push({ categoria: key, val: this.totalAlertCat[key] }); //timeToMilisegundos
          });
          if(this.state.totalAlertCatSpan !== this.totalAlertCatSpan ){this.setState({totalAlertCatSpan: this.totalAlertCatSpan}); }
          */
      }
  /**
   * Fin Alertas
   */





  getfuntion = (metodo="get", obj=[], id="", catalogo="perfilesFuncionalidades", stateVar= "columnas", hiddenModl="") =>{
    return Crud_Catalogos(catalogo, "monitoreoPage", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        /*** filtro de variables  */
        switch (metodo) {
          case "get":
              if (stateVar === "columnas"){
                colsT= [];
                colsN= [];
                if(returnVal[0].id){
                  for(let item of returnVal.sort(function(a, b){return a.id-b.id}) ){//var nom= this.getFuncionalidadById(item.idFuncionalidad);
                    this.getFuncionalidadById(item.idFuncionalidad);
                  }//this.setState({ columnas:cols });
                  this.setState({ columnasN:colsN});
                  this.setState({ columnasT:colsT});
                }
              }
              if (stateVar === "usuariosCorredores"){ if(returnVal[0].idUsuario){ this.setState({ usuariosCorredores:returnVal }); } }
              if (stateVar === "usuarioOperacionCliente"){ if(returnVal[0].idUsuario){ this.setState({ usuariosCorredoresT: returnVal }); } }
              if (stateVar === "gpsPlataformaGPsgG"){
                if(returnVal[0]){
                  if(returnVal[0].idMovimiento){
                    this.getfuntion("get","", returnVal[0].idMovimiento, "unidades/"+returnVal[0].idUnidad, "unidad");
                  }
                }
              }
              if (stateVar === "unidad"){ if(returnVal.id){ this.getfuntion("get","", id, "gpsFijo/"+returnVal.idGpsFijo, "gpsFijo"); } }
              if (stateVar === "gpsFijo"){if(returnVal.id){ this.gpsPlataformaGPsgG[id].gps= returnVal.idGg; } }
              if (stateVar === "gpsFijos"){ if(returnVal[0]){ if(returnVal[0].id){ this.gpsFijos= returnVal; this.gpsTerrestres();} } }
              if (stateVar === "movPatio"){ return returnVal; }
              if (stateVar === "Monitoreo_Naviero"){ return returnVal; }
              if (stateVar === "Monitoreo_Terrestre"){ return returnVal; }
            break;
          default:
            break;
        }
      }).catch(err =>{
        if( err.response ){
          console.log(err.response);
        }else{
          /**
           * Error
           */
          console.log("Error desconocido ....");
          //console.log(err)
        }
    })
  }

  updateEtas = (data) => this.setState({ etasN: data });

  getFuncionalidadById=(id) =>{
    for(let item of this.props.funcionalidades_object){
      if (item.bloque.nombre==="perfilNaviera"){
        if(item.id === id){
          colsN.push({idFuncionalidad: item.id, "content": item.nombre, "columna": item.nombre, "nombre": item.nombre, "label": item.label });
        }
      }
      if(item.bloque.nombre==="terrestreMovimientos"){
        if(item.id === id){
          colsT.push({idFuncionalidad: item.id, "content": item.nombre, "columna": item.nombre, "nombre": item.nombre, "label": item.nombre  });
        }
      }
    }
  }
  hiddenNaviera   =() =>{console.log("hiden nav: "+ this.state.modulosMonitoreo.estatusN)
    let modulosMonitoreoTemp= {...this.state.modulosMonitoreo};
    modulosMonitoreoTemp.estatusN= !modulosMonitoreoTemp.estatusN;
    if( modulosMonitoreoTemp.estatusN ){
      modulosMonitoreoTemp.estatusT= false;
      this.setState({ terrestres: false });
    }console.log(modulosMonitoreoTemp);
    this.setState({ modulosMonitoreo: modulosMonitoreoTemp });
    localStorage.setItem('modulosMonitoreo', JSON.stringify(modulosMonitoreoTemp) );
    if(modulosMonitoreoTemp.estatusN){
      this.setState({ plataformaTipo: "Naviera" });
    }else{
      this.setState({ plataformaTipo: "" });
    }
  }
  hiddenTerrestre =() =>{
    let modulosMonitoreoTemp= {...this.state.modulosMonitoreo};
    modulosMonitoreoTemp.estatusT= !modulosMonitoreoTemp.estatusT;
    if( modulosMonitoreoTemp.estatusT ){
      modulosMonitoreoTemp.estatusN= false;
    }
    this.setState({ modulosMonitoreo: modulosMonitoreoTemp });
    localStorage.setItem('modulosMonitoreo', JSON.stringify(modulosMonitoreoTemp) );
    if(modulosMonitoreoTemp.estatusT){
      this.setState({ plataformaTipo: "Terrestre" });
    }else{
      this.setState({ plataformaTipo: "" });
    }
  }

  /**
   * Privilegio activo
   */
  estaPrivilegioActivo = ( clave)=> {
    var privilegios= this.props.privilegios_object; var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0;  i<privilegios.length; i++) {
      if(privilegios[i].clave === clave){ if(perfil.privilegios.filter(word => word.idPrivilegio === privilegios[i].id).length > 0 ){return true}else{ return false }
      }
    }
  }
  getFuncionalidad=(elemento)=>{
    var BreakException = {};
    var resultado= false;

    try {
      Object.keys( this.state.PlataformasPerfiles ).forEach((key, indice) => {
        if(this.state.PlataformasPerfiles[key].funcionalidades){
          for(let item of this.state.PlataformasPerfiles[key].funcionalidades){
            if(item.nombre === elemento ){ resultado= true; throw BreakException; }
          }
        }
      });
    } catch (e) {
      if (e !== BreakException) throw e;
      return resultado;
    }
    return false;
  }
  render() {
    return (
      <>
        {<LoadStore catalogos={["privilegios"]}/>}
        <SiteWrapper>
          <Grid.Col sm={12} lg={12}>
            <NotificationContainer/>
              <div style={{ height: "100vh", "font-size": "smaller"}}>
                <div style={{ position: "absolute", "z-index": "1", right: "25px" }} className="bg-primary">
                  <i
                    key="modnav" className={"fa fa-anchor"+(this.state.modulosMonitoreo.estatusN? " text-success":"")}
                    style={{ "fontSize": "2em" }}
                    hidden={!this.state.modulosMonitoreo.navieras}
                    onClick={()=> this.hiddenNaviera() }
                  />
                  <i className="ml-2"></i>
                  <i
                    key="modter" className={"fa fa-truck"+(this.state.modulosMonitoreo.estatusT? " text-success":"")}
                    style={{ "fontSize": "2em" }} hidden={!this.state.modulosMonitoreo.terrestres}
                    onClick={()=> this.hiddenTerrestre() }
                  />
                </div>
                {this.state.modulosMonitoreo.navieras || this.state.modulosMonitoreo.terrestres?
                  <SplitterLayout vertical= {true}
                    //percentage= {true}
                    //secondaryInitialSize= {50}
                  >
                    {this.state.modulosMonitoreo.estatusN && this.getFuncionalidad("Movimientos") ?
                      <div>
                          <div>
                            <div className="col-12 bg-primary text-light">
                              <div className="row">
                                <div className="col-4">
                                  <h4>
                                    Navieras
                                  </h4>
                                </div>
                                <div className="col" hidden={ !currentUser.filter(c=> c.plataforma.id === 1315).length }>
                                  { this.state.tableroIncidencias.map(i=> <span>{i.incidencia} : {i.count} | </span> )}
                                </div>
                                <div className="col-8">
                                  { this.getFuncionalidad("Alertas(Resumen)")?<div>
                                      <span>{this.state.semaforo===2?"Hay alertas que han excedido el tiempo de atención requerido":null}</span>
                                      <span className='fa fa fa-gears float-right' style={{width: "10vh", 'font-size':'120%'}}>: {this.totalAlert} </span>
                                      { this.state.totalAlertCatSpan.map((sp, index)=> <span className={'fa '+sp.categoria+' float-right'} style={{width: "10vh", 'font-size':'120%'}}>: {sp.val} </span> ) }</div>
                                  : <div></div>}
                                </div>
                              </div>
                            </div>

                            <div className="panel panel-default">
                              <EspecificoN
                                 movPatio={this.state.MovimientosFull}
                                  mtipo={ this.props.mtipo }
                                  Ctlogo="navieraMovimientosPerfil" setMovimiento={this.setMovimientoNaviero} movimientoToUpdate={this.state.movimientoToUpdate}
                                  tamanio="75%" FCtlogo={null} alertasN={ this.state.alertasN } etasN={ this.state.etasN }
                                  gpsNavieroReciente      = { this.state.gpsNavieroReciente }
                                  movsGpsEstatus={ this.state.movsGpsEstatus }

                                                              //{{ 0: "movimientosTipo", 1: "navieras", 2: "operacionesEstatus", 3: "operacionesTipo", 4: "otd", 5: "plataformas", 6: "rutasEstatus", 7: "corredores", 8: "usuariosLog", 9:"funcionalidades",10: "privilegios", 11: "navieraMovimientos" }}
                                  catalogos               = {{ 0: "funcionalidades", 1: "privilegios", 2:"operacionesEstatus", 3: "etiquetas", 4: "rutasEstatus", 5:"service_failure", 6: "navieraMovimientosPerfil" }}
                                  columnas                = {this.state.columnasN}
                                  //{[ {columna: "id", nombre: "id"}, {columna: "idPlataforma", nombre: "Plataforma"}, {columna: "idMovimientoTipo", nombre: "Tipo"}, {columna: "booking", nombre: "Booking"}, {columna: "contenedor", nombre: "Contenedor"}, {columna: "idCorredor", nombre: "Corredor"}, {columna: "idOperacionEstatus", nombre: "Estatus"} ]}
                                  usuariosCorredores      = { this.state.usuariosCorredores }
                                  setMovimientosNavieros  = { this.setMovimientosNavieros }
                                  PlataformasPerfiles     = { this.state.PlataformasPerfiles }
                                />
                            </div>
                          </div>

                      </div>
                    :null}
                    {this.estaPrivilegioActivo("monitoreo_terrestre") && this.getFuncionalidad("MovimientosT") && this.state.modulosMonitoreo.estatusT ?
                      <div>
                        <div>
                          <div className="col-12 bg-primary text-light"><h4>Terrestres<div className="float-right col-1 right" style={{"background-color": "rgba(6, 0, 0, 0.2)"}}></div></h4></div>
                            <div className="panel panel-default">
                                <EspecificoT
                                  Ctlogo                    = "terrestreMovimientos" setMovimiento={this.setMovimientoTerrestre} movimientoToUpdate={this.state.movimientoToUpdateTerrestre}
                                  tamanio                   = "75%" FCtlogo={ null } alertasT={ this.state.alertasT }
                                  gpsTerrestreReciente      = { this.state.gpsTerrestreReciente }
                                  catalogos                 = {{ 1: "operacionesEstatus", 11:"funcionalidades", 12:"terrestreMovimientos", }}
                                  columnas                  = {this.state.columnasT}
                                  usuariosCorredores        = {this.state.usuariosCorredoresT }
                                  setMovimientosTerrestres  = {this.setMovimientosTerrestres}
                                  PlataformasPerfiles       = { this.state.PlataformasPerfiles }
                                />
                          </div>
                        </div>

                      </div>
                    : null
                    }
                  </SplitterLayout>
                :null}
            </div>

          </Grid.Col>
        </SiteWrapper>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    movimientos_naviera_object: state.catalogos_reducer.movimientos_naviera_object,
    movimientos_terrestres_object: state.catalogos_reducer.movimientos_terrestres_object,
    movimientosTipo_object: state.catalogos_reducer.movimientosTipo_object,
    clientes_object: state.catalogos_reducer.clientes_object,
    navieras_object: state.catalogos_reducer.navieras_object,
    otd_object: state.catalogos_reducer.otd_object,
    plataformas_object: state.catalogos_reducer.plataformas_object,
    rutasEstatus_object: state.catalogos_reducer.rutasEstatus_object,
    transportistas_object: state.catalogos_reducer.transportistas_object,
    corredores_object: state.catalogos_reducer.corredores_object,
    funcionalidades_object: state.catalogos_reducer.funcionalidades_object,
    privilegios_object: state.catalogos_reducer.privilegios_object,
    operacionesEstatus_object: state.catalogos_reducer.operacionesEstatus_object,
    operacionesTipo_object: state.catalogos_reducer.operacionesTipo_object,
    operacionesClientes_object: state.catalogos_reducer.operacionesClientes_object,
    operadores_object: state.catalogos_reducer.operadores_object,
    updateStatusMensaje_Object:state.catalogos_reducer.update_mensaje,
  }
}
function mapDispachToProps(dispach) {
  return {
    movimientos_naviera_update: (elementos) => dispach(ACTIONS.elementos_movimientos_naviera_imput(elementos)),
    movimientos_terrestres_update: (elementos) => dispach(ACTIONS.elementos_movimientos_terrestres_imput(elementos)),
    movimientosTipo_update: (elementos) => dispach(ACTIONS.elementos_movimientosTipo_imput(elementos)),
    clientes_update: (elementos) => dispach(ACTIONS.elementos_clientes_imput(elementos)),
    navieras_update: (elementos) => dispach(ACTIONS.elementos_navieras_imput(elementos)),
    otd_update: (elementos) => dispach(ACTIONS.elementos_otd_imput(elementos)),
    plataformas_update: (elementos) => dispach(ACTIONS.elementos_plataformas_imput(elementos)),
    rutasEstatus_update: (elementos) => dispach(ACTIONS.elementos_rutasEstatus_imput(elementos)),
    transportistas_update: (elementos) => dispach(ACTIONS.elementos_transportistas_imput(elementos)),
    corredores_update: (elementos) => dispach(ACTIONS.elementos_corredores_imput(elementos)),
    funcionalidades_update: (elementos) => dispach(ACTIONS.elementos_funcionalidades_imput(elementos)),
    updateStatusMensaje: (status) => dispach(ACTIONS.update_mensaje(status)),
    operacionesEstatus_update: (elementos) => dispach(ACTIONS.elementos_operacionesEstatus_imput(elementos)),
    operacionesTipo_update: (elementos) => dispach(ACTIONS.elementos_operacionestipo_imput(elementos)),
    operacionesClientes_update: (elementos) => dispach(ACTIONS.elementos_operacionesClientes_imput(elementos)),
    operadores_update: (elementos) => dispach(ACTIONS.elementos_operadores_imput(elementos)),
  }
}
export default connect(mapStateToProps, mapDispachToProps)(Monitoreo);
