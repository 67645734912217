import React, { useState, useEffect } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import {  Button } from "tabler-react";
import { timeToDateNumbers } from "../herramientas/DateFormat";
/**
 * Lista de elementos json []
 * columnas (encabezados)
 * id (identificador de fila)
 * <TablePageable
      lista= { unidades }
      columnas={
        [
          { columna: "id", label:"ID" },
          { columna: "nombre", label:"NOMBRE" },
          { columna: "placa", label:"PLACA" },
          { columna: "estatus", label:"ACTIVO" },
          { columna: "idUnidadTipo.nombre", label:"TIPO" },
          { columna: "idUnidadMarca.nombre", label:"MARCA" },    
          { columna: "modelo", label:"MODELO" },
          { columna: "color", label:"COLOR" },
        ]
      }
      id= "id"
      selected={setUnidad}
    />
 * @param {*} param0 
 * @returns 
 */
const TablePageable =({lista, columnas, id, selected, titulo, csvname, pagination= false,elementosPorPagina = 10, resaltarFuncion, resaltarColor })=> {
  
  const [item, setItem] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalByPage, setTotalByPage] = useState(elementosPorPagina);
  const [search, setSearch] = useState("");
  const getDataToCsv=()=>{
    let csvDataTemp= []
    if(columnas.length){
      let columnasTemp= [];      
      for( let c of columnas ){
        if(c.columna)
          columnasTemp.push(c.label);
      }
      csvDataTemp.push(columnasTemp);
      for( let i of lista ){
        let columnasDataTemp= [];
        for( let c of columnas ){
          if(c.columna){
            if(c.valor){
              let valorTemp= c.valor(i);
              if(valorTemp && typeof valorTemp === "string"){
                valorTemp= valorTemp.replace('"', "'");
                valorTemp= valorTemp.replace(/(\r\n|\n|\r)/gm, " ");
                valorTemp= valorTemp.replace(/(")/gm, "'");// .replace(',', '","');
              }
              columnasDataTemp.push(valorTemp);
            }else{//&& getValor(i, c.columna).includes('"')              
              let valorTemp= getValor(i, c.columna);
              if(valorTemp && typeof valorTemp === "string"){
                valorTemp= valorTemp.replace('"', "'");
                valorTemp= valorTemp.replace(/(\r\n|\n|\r)/gm, " ");
                valorTemp= valorTemp.replace(/(")/gm, "'");
              }
              columnasDataTemp.push(valorTemp);
              //columnasDataTemp.push(typeof getValor(i, c.columna) === "string"? getValor(i, c.columna).replace('"', '""'): getValor(i, c.columna));
            }
          }
        }//console.log(i);
        csvDataTemp.push(columnasDataTemp);
      }
    }    
    return csvDataTemp;
  }
  const getValor=(valor, nombre)=>{               
    const nombreTemp= nombre? nombre.split("."): null;
    if( nombreTemp ){
      if(1 === nombreTemp.length)
        return valor[nombreTemp[0]];
      if(2 === nombreTemp.length){
        return valor[nombreTemp[0]]? valor[nombreTemp[0]][nombreTemp[1]]: ""; 
      }
      if(3 === nombreTemp.length){
        return valor[nombreTemp[0]] ?
          valor[nombreTemp[0]][nombreTemp[1]]
            ? valor[nombreTemp[0]][nombreTemp[1]][nombreTemp[2]]
          :""
        :"";
      }
    }
    return valor[nombre];
  }
  const getColumna=(registro, valor, id, splitBy, enClick, enClickDisabled, form)=>{
    let enClickDisabledTemp= enClickDisabled? enClickDisabled(registro):false;
    let formTemp= form? form(registro):null;
    //resaltarFuncion? resaltarFuncion(i)? resaltarColor: null: null
    switch (typeof valor) {
      case "string":    return (
      <td onClick={()=> { if(enClick && !enClickDisabledTemp) enClick(registro) }} className={enClick && !enClickDisabledTemp?"btn text-primary":""}>
        { splitBy?
          <ul>
            {valor.split(splitBy).map((v)=> <li>{v}</li>)}
          </ul>
          :form? formTemp: valor
        }
      </td> );
      case "number":    return ( <td onClick={()=> { if(enClick && !enClickDisabledTemp) enClick(registro) }} className={enClick && !enClickDisabledTemp?"btn text-primary":""}> { form? formTemp: valor } </td>);
      case "boolean":   return ( <td onClick={()=> { if(enClick && !enClickDisabledTemp) enClick(registro) }} className={enClick && !enClickDisabledTemp?"btn text-primary":""}> <div className="form-check form-switch">
      <input className="form-check-input" type="checkbox" id={"alive"+id} name={"alive"+id} checked={valor} /></div></td>);   
      default: return ( <td onClick={()=> { if(enClick && !enClickDisabledTemp) enClick(registro) }} className={enClick && !enClickDisabledTemp?"btn text-primary":""}> { form? formTemp: valor } </td>);
    }
  }
  const filtro =(palabras = [], )=>{
    let x= search;
    for (let c of columnas) {
      //let y= palabras[c.columna];
      let y= getValor(palabras, c.columna);
      //console.log("y "+typeof y+"["+c.columna+"] :"+ y);
      if( typeof y === "number" && y == Number(x) ){ return true }
      //if( typeof y === "boolean" && y && x ){ return true }
      if( typeof y === "string" && y.toUpperCase().includes(x.toUpperCase())){return true }
    }
    return false;
  }
  const getPaginas=()=>{
    let paginasTemp= [];
    const totalPaginas= Math.ceil((Math.ceil(lista.length)/totalByPage));
    for(let p=currentPage; p <= totalPaginas; p++){
      paginasTemp.push(
        <li className={"page-item"+(p===currentPage?" active":"")}>
          <a className="page-link"
          onClick={()=>{ if( currentPage< totalPaginas ){setCurrentPage(p)}}}>{p}</a>
        </li>);
      if(paginasTemp.length === 5)
        break;
    }
    return paginasTemp;
  }
  const getLista=()=>{
    let listaTemp= [...lista];
    return listaTemp.filter(palabras => filtro(palabras) ).sort().slice((currentPage*totalByPage)-(totalByPage), (currentPage*totalByPage)-1);
  }
  return (
    <div style={{ height: "100%", width:"100%"}}>
      <div style={{ height: "40px"}} className="otd-table">
        <span className="mr-2 ml-2 float-right ">
          <div className="container">
            <div className="row">
              <div className="col-1">
                <label><i className="fa fa-search"></i></label>
              </div>
              <div className="col">
                <input
                  type="text"
                  className="input-field form-control"
                  style={{ outline: "none" }}
                  id="search"
                  value={ search }
                  onChange={(e)=> {
                    setSearch(e.target.value);
                    setCurrentPage(1);
                    }}
                  />
              </div>
              { csvname &&
                <div className="col">
                  <CSVLink
                    data={getDataToCsv()}
                    filename = { (csvname? csvname:"data")+"-"+timeToDateNumbers(new Date().getTime())+".csv" }
                  >
                    <i className="fa fa-file-excel-o text-success" style={{"font-size": "24px"}}> csv</i>
                  </CSVLink>
                </div>
              }
            </div>
          </div>
        </span>
        <span className="mr-2 ml-2 float-left">{ titulo }</span>
      </div>
      <div className="panel panel-default">
        <div className="col-12 panel-resizable" style={{ height: "20%" }}>{/** height: "40vh" */}
          <table className="table table-sm">
            <thead>
              <tr>
                { columnas.map((c)=> <th scope="col" key={c[id]} >{c.label}</th> ) }
              </tr>
            </thead>
            <tbody>
              {lista?
                getLista().map((i) =>
                  <tr
                    style={
                      {
                        "background-color":
                        item && item[id] === i[id]
                            ? "rgba(29, 196, 247, 0.13)"
                            : resaltarFuncion? resaltarFuncion(i)? resaltarColor: null: null
                      }}
                    onClick={()=>{ if(selected){ selected(i); } setItem(i)}}
                  >
                    { columnas.map((c)=> <td> { getColumna(i, getValor(i, c.columna), item[id], c.splitBy, c.enClick, c.enClickDisabled, c.form) } </td> ) }
                  </tr>
                ):"Esperando contenido ..!"
              }
            </tbody>
          </table>
        </div>
        <div>
          <nav aria-label="Page navigation">
            <ul className="pagination pagination--custom">
              <li className={"page-item"+(currentPage <= 1? " disabled": "")}>
                <a className="page-link"
                  onClick={()=>{ if (currentPage > 1){ setCurrentPage(currentPage-1)} }}
                  aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </a>
              </li>
              {getPaginas()}              
              <li className={"page-item"+(currentPage >= Math.ceil((Math.ceil(lista.length)/totalByPage))? " disabled": "")}>
                <a className="page-link"
                  onClick={()=>{ if (currentPage < Math.ceil((Math.ceil(lista.length)/totalByPage))){ setCurrentPage(currentPage+1)} }}
                  aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only">Next</span>
                </a>
              </li>
              <li className="ml-3">
              </li>
              <li>
                <input
                  type="number"
                  className="page-link"
                  //className="form-control"
                  style={{width: "60px"}}
                  id="search"
                  value={ totalByPage }
                  onChange={(e)=> setTotalByPage(e.target.value)}
                  />                  
              </li>
              {pagination  &&
              <li className="ml-2">
                <h2>por pagina </h2>
              </li>
              }             
            </ul>
            <ul className="pagination">
              {pagination  &&
              <li>
                <h2 className="ml-3"> Pagina {currentPage}/{ Math.ceil((Math.ceil(lista.length)/totalByPage)) } </h2>
              </li>
              } 

              {pagination  &&
                <li>
                <h2 className="ml-3"> Total de elementos { Math.ceil(lista.length) } </h2>
              </li>   
              }
                           
            </ul>
          </nav>
          {/*
              <li class="page-item"><a class="page-link" href="#">1</a></li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>*/}
        </div>
      </div>
    </div>
  );
}
export default TablePageable;
