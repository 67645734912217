import { Crud_Catalogos } from '../../herramientas/Crud_Catalogos';
//import $ from "jquery";
var gpss= {};
var gpsReciente= {};
var movT={};
export function GpsEstatusTerrestre(movimientos, update, gpsFijos ){ 
    gpss= {};
    gpsReciente= {};
    for(let mov of movimientos){
      movT[mov.id]= mov;
    };
    gpsFijos.map((gp, index)=>{
      if(movT[gp.idMovimiento]){
        getfuntion("get", gp, gp.idMovimiento, "gpsestatus/plataforma/"+movT[gp.idMovimiento].plataforma.idGg+"/gps/"+gp.idGg, "gpsestatus").then((returnVal) => {
          if( returnVal ){
            update(returnVal); }
        });
      } return gp;
    });
    function getfuntion (metodo="get", obj=[], id="", catalogo="entregas", stateVar= "gpsestatus", hiddenModl="") {
      return Crud_Catalogos(catalogo, "gpsestatus", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        switch (metodo) {
            case "get":
              if (stateVar === "gpsestatus" && returnVal){
                if(gpss[id]){
                  if( !gpss[id][obj.idGg] ){  gpss[id][obj.idGg]= []; }
                  gpss[id][obj.idGg]= { nombre:             obj.nombre,
                                        idGg:               obj.idGg,
                                        idMovimiento:       obj.idMovimiento,
                                        imei:               obj.imei,
                                        marca:              obj.marca,
                                        sim:                obj.sim,
                                        direccion:          returnVal.direccion,
                                        latitud:            returnVal.latitud,
                                        longitud:           returnVal.longitud,
                                        nivel_bateria:      returnVal.nivel_bateria,
                                        ultimo_reporte:     returnVal.ultimo_reporte,
                                        ultimo_reporte_ms:  returnVal.ultimo_reporte_ms
                                      };
                  Object.keys( gpss[id] ).forEach((key, indice) =>{
                    if( gpss[id][key].ultimo_reporte_ms < gpsReciente[id].ultimo_reporte_ms ){
                      let iconBattery= "";
                      let iconTime= "";
                      if(returnVal.ultimo_reporte_ms <  (1000*60*10) && returnVal.ultimo_reporte_ms >= 0            ){ iconTime= {icono: "fa fa-eye",       color: "green" } }
                      if(returnVal.ultimo_reporte_ms >= (1000*60*10) && returnVal.ultimo_reporte_ms <= (1000*60*20) ){ iconTime= {icono: "fa fa-eye",       color: "yellow" } }
                      if(returnVal.ultimo_reporte_ms >  (1000*60*20)                                                ){ iconTime= {icono: "fa fa-eye-slash", color: "red" } }
                      
                      if(returnVal.nivel_bateria >= 0 && returnVal.nivel_bateria <= 5    ){ iconBattery= {icono: "fa fa-battery-0",       color: "red" }   }
                      if(returnVal.nivel_bateria > 5  && returnVal.nivel_bateria <= 15   ){ iconBattery= {icono: "fa fa-battery-1",       color: "red" }   }                  
                      if(returnVal.nivel_bateria > 15 && returnVal.nivel_bateria <= 30   ){ iconBattery= {icono: "fa fa-battery-1",       color: "yellow"} }
                      if(returnVal.nivel_bateria > 30 && returnVal.nivel_bateria <= 40   ){ iconBattery= {icono: "fa fa-battery-1",       color: "green"} }
                      if(returnVal.nivel_bateria > 40 && returnVal.nivel_bateria <= 65   ){ iconBattery= {icono: "fa fa-battery-2",       color: "green"} }
                      if(returnVal.nivel_bateria > 65 && returnVal.nivel_bateria <= 90   ){ iconBattery= {icono: "fa fa-battery-3",       color: "green"} }
                      if(returnVal.nivel_bateria > 90 && returnVal.nivel_bateria <= 100  ){ iconBattery= {icono: "fa fa-battery-4",       color: "green"} }

                      gpsReciente[id]={ nombre:             obj.nombre,
                                        idGg:               obj.idGg,
                                        nivel_bateria:      returnVal.nivel_bateria,
                                        ultimo_reporte:     returnVal.ultimo_reporte,
                                        ultimo_reporte_ms:  returnVal.ultimo_reporte_ms,
                                        iconBattery:        iconBattery,
                                        iconTime:           iconTime,
                                        latitud:            returnVal.latitud,
                                        longitud:           returnVal.longitud
                                      };
                    }
                  });
                  return   { gpsEstatus: gpss, gpsReciente: gpsReciente };
                }else{
                  gpss[id]= [];
                  if( gpss[id] ){
                    gpss[id][obj.idGg]= [];
                  }
                  gpss[id][obj.idGg]= { nombre:             obj.nombre,
                                        idGg:               obj.idGg,
                                        idMovimiento:       obj.idMovimiento,
                                        imei:               obj.imei,
                                        marca:              obj.marca,
                                        sim:                obj.sim,
                                        direccion:          returnVal.direccion,
                                        latitud:            returnVal.latitud,
                                        longitud:           returnVal.longitud,
                                        nivel_bateria:      returnVal.nivel_bateria,
                                        ultimo_reporte:     returnVal.ultimo_reporte,
                                        ultimo_reporte_ms:  returnVal.ultimo_reporte_ms
                                      };
                  let iconBattery= "";
                  let iconTime= "";
                  if(returnVal.ultimo_reporte_ms <  (1000*60*10) && returnVal.ultimo_reporte_ms >= 0            ){ iconTime= {icono: "fa fa-eye",       color: "green" } }
                  if(returnVal.ultimo_reporte_ms >= (1000*60*10) && returnVal.ultimo_reporte_ms <= (1000*60*20) ){ iconTime= {icono: "fa fa-eye",       color: "yellow" } }
                  if(returnVal.ultimo_reporte_ms >  (1000*60*20)                                                ){ iconTime= {icono: "fa fa-eye-slash", color: "red" } }
                  
                  if(returnVal.nivel_bateria >= 0 && returnVal.nivel_bateria <= 5    ){ iconBattery= {icono: "fa fa-battery-0",       color: "red" }   }
                  if(returnVal.nivel_bateria > 5  && returnVal.nivel_bateria <= 15   ){ iconBattery= {icono: "fa fa-battery-1",       color: "red" }   }                  
                  if(returnVal.nivel_bateria > 15 && returnVal.nivel_bateria <= 30   ){ iconBattery= {icono: "fa fa-battery-1",       color: "yellow"} }
                  if(returnVal.nivel_bateria > 30 && returnVal.nivel_bateria <= 40   ){ iconBattery= {icono: "fa fa-battery-1",       color: "green"} }
                  if(returnVal.nivel_bateria > 40 && returnVal.nivel_bateria <= 65   ){ iconBattery= {icono: "fa fa-battery-2",       color: "green"} }
                  if(returnVal.nivel_bateria > 65 && returnVal.nivel_bateria <= 90   ){ iconBattery= {icono: "fa fa-battery-3",       color: "green"} }
                  if(returnVal.nivel_bateria > 90 && returnVal.nivel_bateria <= 100  ){ iconBattery= {icono: "fa fa-battery-4",       color: "green"} }
                  

                  gpsReciente[id]={ nombre:             obj.nombre,
                                    idGg:               obj.idGg,
                                    nivel_bateria:      returnVal.nivel_bateria,
                                    ultimo_reporte:     returnVal.ultimo_reporte,
                                    ultimo_reporte_ms:  returnVal.ultimo_reporte_ms,
                                    iconBattery:        iconBattery,
                                    iconTime:           iconTime,
                                    latitud:            returnVal.latitud,
                                    longitud:           returnVal.longitud
                                  };      
                  return { gpsEstatus: gpss, gpsReciente: gpsReciente };
                }
              }
              break;
            default:
              break;
          }
        }).catch(err =>{
            if( err.response ){ console.log(err.response);  }else{  console.log("Error desconocido ....");  }
          });
    } 
}
  
  export default GpsEstatusTerrestre;