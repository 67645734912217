import React, { Component } from "react";
import * as conf from "../config/config";
import SockJsClient from 'react-stomp';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import axios from 'axios';
import Show from "./Show";
import { Crud_Catalogos, Crud_error } from '../herramientas/Crud_Catalogos';

let instance = axios.create();
const username= 'WN8z55WbAzF?bgy!BW5S9VB9$N#$fmUPx!fDf3=ZJft3mY3c@b';
const password= 'DzuJVh%k&Msvr@=k2a?zZz#4CuZ55awXSYkVm?_a#+a@xQ!pDn';
//Formato de Fecha
function pad(number) {
  if (number < 10) { return '0' + number; }
  return number;
}
function myFormato(date) {
  console.log(date);
  return date.getFullYear() + '-' + pad(date.getMonth() + 1) + '-' + pad(date.getDate()) + ' ' + pad(date.getHours()) + ':' + pad(date.getMinutes()) + ':' + pad(date.getSeconds());
}
var user = JSON.parse(sessionStorage.getItem("usuario"));

class Mensajeria extends Component {
  constructor(props) {
    super(props);
    this.state = {
        udateM: true
    }
    this.mensaje=[];
    this.postOrigin= false;
  }
  componentWillMount =() =>{
    instance.defaults.headers.common['Authorization'] = 'Basic '+window.btoa(username+":"+password);
    axios.get(conf.socket+"basicauth").then((response)=>{}).catch(err => {
      console.log("Error: " + err);
      axios.get(conf.socket+"basicauth").then((response)=>{}).catch(err => {
        console.log("Error: " + err);
      });
    });
  }
  componentDidMount =() => {

  }

  componentDidUpdate =() =>{
      if(this.props.metodo !== "get"  && this.props.stor.updateStatusMensaje_Object ){
        this.sendMessage(JSON.stringify(this.props.mensaje));
      }
  }
  sendMessage = (msg) => {
    this.postOrigin= true;
      this.clientRef.sendMessage(this.props.topic, msg);
      this.props.stor.updateStatusMensaje(false);
  }

  udate=(mensaje) => {
    /**
     * "sistema"
     */
    if(mensaje.vista == "sistema"){
      if(mensaje.data){
        if(mensaje.data.refresh){
          let randomN= Math.floor(Math.random() * (mensaje.data.refresh - 10)) + 10;
          setTimeout(function() {
            window.location.reload();
          }, (randomN*1000) );
          NotificationManager.warning(mensaje.mensaje+ randomN+" Seg.", "Sistemas" );
        } 
      }
    }
    if(mensaje.vista == "alertasunidades"){
      switch (mensaje.accion) {
        case "post":{ if(this.postOrigin === false){ this.props.elementos.push(mensaje.data) } }break;
        case "put" :{ this.props.elementos.map((elemento, index) =>{ if(elemento["id"] === mensaje.data.id){ this.props.elementos[index]= mensaje.data }  }); }
        default: break;
      }
      this.postOrigin= false;
      if( this.props.storeFunction !== "" ){ this.props.storeFunction(this.props.elementos); }
      if( this.props.update !== "" ){ this.props.update(mensaje);}
    }else{
      this.props.elementos.map((elemento, index) =>{
        if (mensaje.accion === "put"){
          if(elemento["id"] === mensaje.data.id){ this.props.elementos[index]= mensaje.data }
        }
        if(mensaje.accion === "delete"){
          if(elemento["id"] === mensaje.data.id){ this.props.elementos.splice(index, 1) }
        }
        if (mensaje.accion === "post" && this.postOrigin === false){
        }else{
          //if(elemento["id"] === mensaje.data.id){ this.props.elementos[index]= mensaje.data }
        }
      })
      if (mensaje.accion === "post" && this.postOrigin === false){
        this.props.elementos.push(mensaje.data)
      }else{
        this.postOrigin= false;
      }
      if( this.props.storeFunction !== "" ){ this.props.storeFunction(this.props.elementos); }
      if( this.props.update !== "" ){ this.props.update(mensaje); }
    }

  }

  eServidor = () => {
    var request = new XMLHttpRequest();
    request.open('GET', conf.socket+'suscribe', true);
    request.send();
    if (request.status == 0) {
      console.log("No Esta disponible el Servidor de Mensajes");
      return false;
    }else{
      return true;
    }
  }
  render() {
    return (
      <div>
        <SockJsClient url={ conf.socket+'suscribe' }
            topics={this.props.topic}
            onMessage={(msg) => {
              console.log(msg);
              this.udate(msg);
              if(msg.vista !== "sistema"){
                NotificationManager.info(msg.mensaje, msg.accion ==="put" ? "Actualización"
                                                                        : msg.accion ==="post" ? "Nuevo"
                                                                        : msg.accion ==="delete" ? "Eliminado": msg.accion );
              };
            }}
            ref={ (client) => { this.clientRef = client }}/>
    </div>
    );
  }
}
export default Mensajeria;
