import React, { Component } from "react";
import { Table} from "tabler-react";
import { Crud_Catalogos } from '../../../../herramientas/Crud_Catalogos';
import { ReactReduxContext } from "react-redux";
class EtaCalculada extends Component {
    constructor(props) {
        super(props);
        this.state = {
            etaCalculadas: [],
            etaCalculadasN: [],
            ultimoCalculo: true,
        };
    }

    componentDidMount = () => {
        this.getEtaNaviera();

    }

    getEtaNaviera = () => {
        Crud_Catalogos("etaCalculada/movimiento/"+this.props.idMovimento, "etaCalculada", "get", "", [], "", this.state.etaCalculadasN, "", []).then((returnVal) => {
            this.etaCalculadasN = returnVal;
            this.setState({ etaCalculadasN: returnVal && returnVal.length? returnVal.sort((a, b) => b.id - a.id ): [] });
        }).catch(err => {
            if (err.response) {
                console.log(err);
            } else {
                /**
                 * Error
                 */
            }
        })
    }
    filtroEtas=()=>{
        if(this.state.ultimoCalculo){
            return this.state.etaCalculadasN.length? this.state.etaCalculadasN.slice(0, 1): [];
        }else{
            return this.state.etaCalculadasN
        }
    }
    render() {
        return (

            <div className="table-responsive">
                <button
                    type="button" className="btn btn-primary btn-sm"
                    onClick={()=> this.setState({ ultimoCalculo: !this.state.ultimoCalculo}) }>
                    <i className="fa fa-code-fork" style={{"font-size": "24px"}}></i>
                    {this.state.ultimoCalculo? "Mostrar todos los cálculos": "Mostrar solo el último cálculo"}
                </button>
                <Table className="table table-striped">
                    <Table.Header>
                        <Table.ColHeader>ID</Table.ColHeader>
                        <Table.ColHeader>Movimiento</Table.ColHeader>
                        <Table.ColHeader>Origen Latitud</Table.ColHeader>
                        <Table.ColHeader>Origen Longitud</Table.ColHeader>
                        <Table.ColHeader>Origen Direccion</Table.ColHeader>
                        <Table.ColHeader>Destino Latitud</Table.ColHeader>
                        <Table.ColHeader>Destino Longitud</Table.ColHeader>
                        <Table.ColHeader>Destino Direccion</Table.ColHeader>
                        <Table.ColHeader>Fecha Calculo</Table.ColHeader>
                        <Table.ColHeader>Distancia</Table.ColHeader>
                        <Table.ColHeader>Tiempo</Table.ColHeader>
                        <Table.ColHeader>Eta</Table.ColHeader>
                        <Table.ColHeader>Tipo Calculo</Table.ColHeader>
                        <Table.ColHeader>Tipo Corredor</Table.ColHeader>
                
                    </Table.Header>
                    {
                        this.filtroEtas().map(etasCalculadas =>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Col>{etasCalculadas.id}</Table.Col>
                                    <Table.Col>{etasCalculadas.idMovimiento}</Table.Col>
                                    <Table.Col>{etasCalculadas.origenLatitud}</Table.Col>
                                    <Table.Col>{etasCalculadas.origenLongitud}</Table.Col>
                                    <Table.Col>{etasCalculadas.origenDireccion}</Table.Col>
                                    <Table.Col>{etasCalculadas.destinoLatitud}</Table.Col>
                                    <Table.Col>{etasCalculadas.destinoLongitud}</Table.Col>
                                    <Table.Col>{etasCalculadas.destinoDireccion}</Table.Col>
                                    <Table.Col>{etasCalculadas.fechaCalculo}</Table.Col>
                                    <Table.Col>{etasCalculadas.distancia}</Table.Col>
                                    <Table.Col>{etasCalculadas.tiempoString}</Table.Col>
                                    <Table.Col>{etasCalculadas.eta}</Table.Col>
                                    <Table.Col>{etasCalculadas.tipoCalculo}</Table.Col>
                                    <Table.Col>{etasCalculadas.tipoCorredor}</Table.Col>      
                                    <Table.Col></Table.Col>
                                </Table.Row>
                            </Table.Body>

                        )
                    }
                </Table>
            </div>
        );
    }
}
export default EtaCalculada;