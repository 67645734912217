import React, { useState, useEffect } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import { timeToDateNumbers } from "../../../herramientas/DateFormat";
import Timer from "../../../herramientas/Time/setTimer";
/**
 * Lista de elementos json []
 * columnas (encabezados)
 * id (identificador de fila)
 * @param {*} param0 
 * @returns 
 */
let totalFiltrados= 0;
const TablePageable =({lista, columnas, id, selected, titulo, csvname, resaltarFuncion, resaltarColor, paginacion, elementosPorPagina,update=false,temporizador=12,accion,bitacora })=> {

  if(!lista) return "Sin registros";
  const [item, setItem] = useState({});
  const [listaTemp, setListaTemp] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalByPage, setTotalByPage] = useState(elementosPorPagina);
  const [search, setSearch] = useState("");
  const time = new Date();
  const [bandera,setBandera]= useState(false);

 
  time.setHours(temporizador);
  time.setMinutes(0);
  time.setSeconds(0);


  const handleFieldChange = (bandera) => {
    setBandera(bandera)
  };

  if(!columnas) return "sin columnas";
  const getDataToCsv=()=>{
    let csvDataTemp= []
    if(columnas.length){
      let columnasTemp= [];      
      for( let c of columnas ){
        c.label = c.label.replace('"', "''");
          if(c.label !== 'Actualizar'){
            columnasTemp.push(c.label);
          }
          
      }
      csvDataTemp.push(columnasTemp);
      for( let i of lista ){
      
        let columnasDataTemp= [];
        for( let c of columnas ){
            if(c.valor){
              let valorTemp= c.valor(i);
              if(valorTemp && typeof valorTemp === "string"){
                valorTemp= valorTemp.replace('"', "'");
                valorTemp= valorTemp.replace(/(\r\n|\n|\r)/gm, " ");
                valorTemp= valorTemp.replace(/(")/gm, "'");// .replace(',', '","');
              }
              columnasDataTemp.push(valorTemp);
            }else{//&& getValor(i, c.columna).includes('"')              
              let valorTemp= getValor(i, c.columna,c.label);
              if(valorTemp && (typeof valorTemp === "string") ){
                valorTemp= valorTemp.replace('"', "'");
                valorTemp= valorTemp.replace(/(\r\n|\n|\r)/gm, " ");
                valorTemp= valorTemp.replace(/(")/gm, "'");
              }
              if(typeof valorTemp !== 'object') columnasDataTemp.push(valorTemp);
            }
         
        }//;
        csvDataTemp.push(columnasDataTemp);
      }
    }    
    return csvDataTemp;
  }
  const getValor=(valor, nombre,label)=>{    
    
    const nombreTemp= nombre ? nombre.split("."): null;    
    if( nombreTemp ){
      if(1 === nombreTemp.length){
        
        if(label === "Comentarios"){
          return valor.remolques[0] ? valor.remolques[0].comentarios : "";
        }
        if(label === "Hora"){
          return valor.remolques[0] ? valor.remolques[0].fechaCreacion : "";
        }

        if(label === "Agregar"){
          return valor.remolques[0] ? valor.remolques[0].fechaCreacion : "";
        }



        return typeof valor[nombreTemp[0]] !== "object"? valor[nombreTemp[0]] :"";
      }
      if(2 === nombreTemp.length){
        return valor[nombreTemp[0]]? typeof valor[nombreTemp[0]][nombreTemp[1]] !== "object"? valor[nombreTemp[0]][nombreTemp[1]]: "": ""; 
      }
      if(3 === nombreTemp.length){
        return valor[nombreTemp[0]] ?
          valor[nombreTemp[0]][nombreTemp[1]]
            ? valor[nombreTemp[0]][nombreTemp[1]][nombreTemp[2]] !== "object"?  valor[nombreTemp[0]][nombreTemp[1]][nombreTemp[2]]: ""
          :""
        :"";
      }
    }

    if(nombre === ""){
      let valorTemp = valor.remolques.filter((remolque)=> {
        return remolque.nombre == (label);

      })

      if(valorTemp && valorTemp.length > 0 ){
        //console.log(valorTemp)
         valorTemp = valorTemp[0].totalDisponible > 0 ? valorTemp[0].totalDisponible : valorTemp[0].totalRegistrado ;
      }else {
        valorTemp = 0;
      }
      return valorTemp;
    }

      if(label === "Agregar"  && valor.remolques.length <= 0 && !bandera){
        return nombre;
      }

      if(label === "Agregar" && valor.remolques.length > 0){
        return valor.remolques[0].fechaCreacion;
      }
    
    
    if(label === "Actualizar"){
      return nombre;
    }

    return valor[nombre];
  }
  const getColumna=(registro, valor, i, splitBy, enClick, enClickDisabled, form)=>{
    let enClickDisabledTemp= enClickDisabled? enClickDisabled(registro):false;
    let formTemp= form? form(registro):null;
    switch (typeof valor) {
      case "string":    return (
      <td onClick={()=> { if(enClick && !enClickDisabledTemp) enClick(registro) }} className={enClick && !enClickDisabledTemp?"btn text-primary":""}>
        { splitBy?
          <ul>
            {valor.split(splitBy).map((v)=> <li>{v}</li>)}
          </ul>
          :form ? registro.remolques[0] ?  valor: !bandera ? formTemp : "" : valor
        }
      </td> );
      case "number":    return ( <td onClick={()=> { if(enClick && !enClickDisabledTemp) enClick(registro) }} className={enClick && !enClickDisabledTemp?"btn text-primary":""}> { form? formTemp: valor } </td>);
      break; 
      case "boolean":   return ( <td onClick={()=> { if(enClick && !enClickDisabledTemp) enClick(registro) }} className={enClick && !enClickDisabledTemp?"btn text-primary":""}> <div class="form-check form-switch">
      <input class="form-check-input" type="checkbox" id={"alive"+i.id} name={"alive"+i.id} checked={valor} /></div></td>);   
      break;
      default: 
      return (
        <td onClick={()=> { if(enClick && !enClickDisabledTemp) enClick(registro) }} className={enClick && !enClickDisabledTemp?"btn text-primary":""}>
          { splitBy?
            <ul>
              {valor.split(splitBy).map((v)=> <li>{v}</li>)}
            </ul>
            :form ? formTemp : valor
          }
        </td> );
        
    }
  }
  const filtro =(palabras = [], )=>{
    let x= search;
    for (let c of columnas) {
      //let y= palabras[c.columna];
      if( !c.form || (c.form && c.valor) ){
        let y= getValor(palabras, c.columna,c.label);
        if( y === null )
          y= "";
        if(typeof y === "number")
          y= getValor(palabras, c.columna,c.label).toString();
        if(typeof y === "boolean")
          y= getValor(palabras, c.columna,c.label).toString();
        /*
        //console.log("y "+typeof y+"["+c.columna+"] :"+ y);
        if( typeof y === "number" && y === Number(x) ){ return true }
        //if( typeof y === "boolean" && y && x ){ return true }
        if( typeof y === "string" && y.toUpperCase().includes(x.toUpperCase())){return true }
        */
        if(typeof x === "boolean" && typeof y === "boolean" && y && x ){ return true }
        let isMatch = new RegExp('(?:^|\\s)'+search, 'i').test(y);
        if(typeof y === "string" &&  isMatch){return true }
      }
    }
    return false;
  }
  const getPaginas=()=>{
    let paginasTemp= [];
    const totalPaginas= Math.ceil((Math.ceil(totalFiltrados)/totalByPage));
    for(let p=currentPage; p <= totalPaginas; p++){
      paginasTemp.push(
        <li className={"page-item"+(p===currentPage?" active":"")}>
          <a className="page-link"
          onClick={()=>{ if( currentPage< totalPaginas ){setCurrentPage(p)}}}>{p}</a>
        </li>);
      if(paginasTemp.length === 5)
        break;
    }
    return paginasTemp;
  }
  const getLista=()=>{
    let listaTemp= [...lista];
    let  x = item;
    if(listaTemp.length){
      listaTemp= listaTemp.filter(palabras => filtro(palabras) ).sort()
      totalFiltrados= listaTemp.length;
      if(paginacion)
        listaTemp= listaTemp.slice((currentPage*totalByPage)-(totalByPage), (currentPage*totalByPage));    
    }
  //  console.log(listaTemp)    
    return listaTemp; 

  }
  return (
    <div >
    
      <div className="head-table">
        <span className="mr-2 ml-2">
          <div className="container">
            <div className="row">
            <div className="col-1">
               {bitacora}

              </div>
            </div>
          
            <div className="row">
              <div className="col-1">
                <label><i class="fa fa-search"></i></label>
              </div>

              <div className="col">
                <input
                  type="text"
                  className="input-field form-control"
                  style={{ outline: "none" }}
                  id="search"
                  value={ search }
                  onChange={(e)=> {
                    setSearch(e.target.value);
                    if( currentPage !== 1){
                      setCurrentPage(1);
                    }
                    
                    }}
                  />
              </div>
              { csvname &&
                <div className="col">
                  <CSVLink
                    data={getDataToCsv()}
                    filename = { (csvname? csvname:"data")+"-"+timeToDateNumbers(new Date().getTime())+".csv" }
                  >
                    <i className="fa fa-file-excel-o text-success" style={{"font-size": "24px"}}> csv</i>
                  </CSVLink>
                  {/*<CSVDownload data={getDataToCsv()} target="_blank" />*/}
                </div>
              }
            </div>
          </div>
        </span>
        <span className="mr-2 ml-2">{ titulo }</span>
      </div>
      <div className="panel panel-default">
        <div className="col-12 panel-resizable" style={{ height: "20%" }}>{/** height: "40vh" */}
          <table className="table table-sm table-unidades">
            <thead>
              <tr>
                { columnas.map((c)=> <th scope="col" key={c[id]} >{c.label}</th> ) }
              </tr>
            </thead>
            <tbody>
              {lista?
                getLista().map((i) =>
                  <tr
                    style={
                      {
                        "background-color":
                        item && item[id] === i[id]
                            ? "rgba(29, 196, 247, 0.13)"
                            : resaltarFuncion? resaltarFuncion(i)? (typeof resaltarColor === "function"? resaltarColor(i): resaltarColor): "": ""
                      }}
                    onClick={()=>{ if(selected){ selected(i); } setItem(i)}}
                  >
                    { columnas.map((c)=> <td className="columnas"> { (getColumna(i, getValor(i, c.columna,c.label), i, c.splitBy, c.enClick, c.enClickDisabled, c.form)) } </td> ) }
                  </tr>
                ):"Esperando contenido ..!"
              }
            </tbody>
          </table>
        </div>
       <div style={{display:"flex",alignItems:"center",flexDirection:"column-reverse",margin:"2rem 0 0 0"}}>
          <nav aria-label="Page navigation">
            <ul className="pagination">
              <li className={"page-item"+(currentPage <= 1? " disabled": "")}>
                <a class="page-link"
                  onClick={()=>{ if (currentPage > 1){ setCurrentPage(currentPage-1)} }}
                  aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>
              {getPaginas()}              
              <li className={"page-item"+(currentPage >= Math.ceil((Math.ceil(lista.length)/totalByPage))? " disabled": "")}>
                <a className="page-link"
                  onClick={()=>{ if (currentPage < Math.ceil((Math.ceil(lista.length)/totalByPage))){ setCurrentPage(currentPage+1)} }}
                  aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Next</span>
                </a>
              </li>
              <li className="ml-3">

              </li>
              <li>
                <input
                  type="number"
                  class="page-link"
                  //className="form-control"
                  style={{width: "60px"}}
                  id="search"
                  value={ totalByPage }
                  onChange={(e)=> setTotalByPage(e.target.value)}
                  />                  
              </li>
              {paginacion  &&
              <li className="ml-2">
                <h2>por pagina </h2>
              </li>
              }            
            </ul>
            <ul class="pagination">
            {paginacion  &&
              <li>
                <h2 className="ml-3"> Pagina {currentPage}/{ Math.ceil((Math.ceil(lista.length)/totalByPage)) } </h2>
              </li>
              } 

              {paginacion  &&
                <li>
                <h2 className="ml-3"> Total de elementos { Math.ceil(lista.length) } </h2>
              </li>   
              }            
            </ul>
          </nav>
          <div className="alert">
              <div className="body">
                {!update ?
                <div>
                  <span>El proceso de carga de remolques termina a las  { temporizador} hrs</span>
                   <Timer expiryTimestamp={time} bandera={handleFieldChange}/>
                
                </div>
               
                :null}
              </div>
        </div>
        </div>
      </div>
    </div>
  );
}
export default TablePageable;
