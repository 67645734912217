import React, { useState } from "react";
import SiteWrapper from "../../../../SiteWrapper.react";
import {LoadingScreen} from "../../../../components/LoadingScreen"
import ContentOtd from "./ContentOtd";
import { func } from "prop-types";


const DashboardOtd = () => {

	return (
		<SiteWrapper>
				<div className="container otd-container">
					<ContentOtd
					/>
				  </div>
			
		</SiteWrapper>
	);
};

export default DashboardOtd;
