import React, { useState, useEffect } from "react";
import Select from "react-select";
import Chart from "react-apexcharts";
import { TablePageable } from "../../../../Helpers";
import Call_Otds from "./Call_Otds";
import { DateRange, DateRangePicker } from "react-date-range";
import { es } from "date-fns/locale";
import { LoadingScreen } from "../../../../components/LoadingScreen";


const ContentOtd = () => {
  //Estatus, Plataformas, Corredores, Transportistas, Clientes, Pais
  const [otdList, setOtdList] = useState([]);
  const [flagUniversal,setFlagUniversal] = useState(false);
  const [otdListFiltrados, setotdListFiltrados] = useState([]);
  const [otdListFiltradosTemp, setotdListFiltradosTemp] = useState([]);
  const [otdEstatusOpciones, setOtdEstatusOpciones]= useState([]);
  const [otdEstatusOpcion, setOtdEstatusOpcion] = useState();
  const [corredoresOpciones, setCorredoresOpciones]= useState([]);
  const [corredoresOpcion, setCorredoresOpcion] = useState();
  const [clientesOpciones, setClientesOpciones]= useState([]);
  const [clientesOpcion, setClientesOpcion] = useState([]);
  const [tipoMovimientoOpciones, setTipoMovimientosOpciones]= useState([]);
  const [tipoMovimientoOpcion, setTipoMovimientoOpcion] = useState();
  const [lineaTransportistaOpciones, setLineaTransportistasOpciones]= useState([]);
  const [lineaTransportistaOpcion, setLineaTransportistaOpcion] = useState();
  const [arrayFilters,setArrayFilters] = useState([{}]);
  //Carga de pantalla de carga
  const [isloading, setisLoading] = useState(true);
  
  // Obtiene  mes, y dia  del año actual
  let dateNow = new Date();

  const month = dateNow.toLocaleString("default", { month: "2-digit" });

  let startDate = `${dateNow.getFullYear()}-${month}-01`;
  
  const year = dateNow.toLocaleString("default", { year: "numeric" });
 
  const day = dateNow.toLocaleString("default", { day: "2-digit" });

  // Genera yyyy-mm-dd date string
  const endDate = year + "-" + month + "-" + day;

  useEffect(() => {
    getfuntion("get", {"startDate":startDate,"endDate":endDate}, "", "movimientosOTD/otds");
  }, []);

  const [dates, setDates] = useState([
    {
      startDate: endDate,
      endDate: startDate,
      key: 'selection'
    }
  ]);


  const formatDate = (date)=>{

    const year = date.toLocaleString("default", { year: "numeric" });
    const month = date.toLocaleString("default", { month: "2-digit" });
    const day = date.toLocaleString("default", { day: "2-digit" });

    // Genera yyyy-mm-dd  string
    return year + "-" + month + "-" + day;

  }

  //Funcion de cambio en seleccion de fechas

  const handleDates = (range)=>{

  setisLoading(true);

  let startDate = formatDate(range.selection.startDate);

  let endDate = formatDate(range.selection.endDate)

  setDates([range.selection]);

  getfuntion("get",{"starDate":startDate,"endDate":endDate},"", "movimientosOTD/otds");
    

  }

  
 //Se realiza la consulta la primera vez.
  useEffect(() => {
    filtro();
  }, 
    [ otdEstatusOpcion,
      corredoresOpcion,
      clientesOpcion,
      tipoMovimientoOpcion,
      lineaTransportistaOpcion
    ]);

  //Definición de la función de consulta
  const getfuntion = (
    metodo = "get",
    params,
    id = "",
    endPoint,
    stateVar = "",
    hiddenModl = ""
  ) => {
    Call_Otds(
      endPoint,
      metodo,
      id,
      params,

    )
      .then((returnVal) => {
        setisLoading(false);
        switch (metodo) {
          case "get":
            {
              if (returnVal) {
                setOtdList(returnVal);
                filtroDeFiltros(returnVal);
                filtro(returnVal);
              }
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else {
          /**
           * Error
           */
          console.log("Error desconocido ...." + err);
        }
      });
  };
  const filtroDeFiltros=(dataOtdLisTemp)=>{
    let otdTemp= dataOtdLisTemp? [...dataOtdLisTemp]: [...otdList];
    let otdEstatus = {};
    let plataformas = {};
    let tipoMovimiento = {};
    let corredores = {};
    let clientes = {};
    let lineaTransportista={};
         
    
    otdTemp.map((otd, index) => {
  

      //|otd_estatus
      if (!otdEstatus[otd.status]) otdEstatus[otd.status] = [];
        otdEstatus[otd.status].push(otd);


      //tipoMovimiento
      if (!tipoMovimiento[otd["idTipoMovimiento"]]) tipoMovimiento[otd["idTipoMovimiento"]] = [];
      tipoMovimiento[otd["idTipoMovimiento"]].push(otd);

      // corredor
      if (!corredores[otd["corredor"]]) corredores[otd["corredor"]] = [];
      corredores[otd["corredor"]].push(otd);


      //cliente
      if (!clientes[otd["cliente"]]) clientes[otd["cliente"]] = [];
      clientes[otd["cliente"]].push(otd);

      //line transportista
      if (!lineaTransportista[otd["idTransportista"]]) lineaTransportista[otd["idTransportista"]] = [];
      lineaTransportista[otd["idTransportista"]].push(otd);
    });

    //creando headers
    let otdEstatusOpcionesTemp = [];
    let tipoMovimientoOpcionesTemp = [];
    let corredoresOpcionesTemp = [];
    let lineaTransportistasOpcionesTemp = [];
    let clientesOpcionesTemp = [];
    Object.keys(otdEstatus)         .forEach((key) => otdEstatusOpcionesTemp.push({ value: key, label: key}));
    Object.keys(tipoMovimiento)     .forEach((key) => tipoMovimientoOpcionesTemp.push({ value: key, label: key}));
    Object.keys(corredores)         .forEach((key) => corredoresOpcionesTemp.push({ value: key, label: key}));
    Object.keys(clientes)           .forEach((key) => clientesOpcionesTemp.push({ value: key, label: key}));
    Object.keys(lineaTransportista) .forEach((key) => lineaTransportistasOpcionesTemp.push({ value: key, label: key}));

    setOtdEstatusOpciones(otdEstatusOpcionesTemp);
    setTipoMovimientosOpciones(tipoMovimientoOpcionesTemp);
    setCorredoresOpciones(corredoresOpcionesTemp);
    setClientesOpciones(clientesOpcionesTemp);
    setLineaTransportistasOpciones(lineaTransportistasOpcionesTemp);
  }

const handleChangeOptions = (event=null,data=null)=>{

    let arrayFiltersTemp;
    let selectOption;


    if((event&& event.action === "select-option")){
      selectOption = event.name;
      arrayFiltersTemp = [...arrayFilters,{"name": selectOption,"value": event.option.value}];
    }

    if((event && event.action === "remove-value")){
      arrayFiltersTemp = arrayFilters;
      let index = arrayFilters.findIndex((item)=> item.value === event.removedValue.value)
      if(index > 0)arrayFiltersTemp.splice(index, 1);

    }

    if(!arrayFiltersTemp){
      arrayFiltersTemp = [...arrayFilters]
    }

  

    let otdTemp  = data ? data: [...otdList];
    

    arrayFiltersTemp.forEach((filter)=>{
   
    
      {switch(filter.name){
      
        case "cliente":{
            let otdTemp3 = otdTemp.filter((o)=> {
              let flag = false;
              arrayFiltersTemp.map((opcion)=>{
             
                if(opcion.value === o.cliente ){
                  flag = true;
                  return;
                } 
              });
            return flag;
            });
            otdTemp = otdTemp3;
           break; 
        }
        case "estatus":{
            let otdTemp3 = otdTemp.filter((o)=> {
              let flag = false;
              arrayFiltersTemp.map((opcion)=>{
                if(opcion.value === o.status ){
                  flag = true;
                  return;
                } 
              });
            return flag;
            });
            otdTemp = otdTemp3;
         
          break;
        }
          
        case "corredor":{
         
          let otdTemp3 = otdTemp.filter((o)=> {
            let flag = false;
            arrayFiltersTemp.map((opcion)=>{
              if(opcion.value === o.corredor ){
                flag = true;
                return;
              } 
            });
          return flag;
          });

          otdTemp = otdTemp3;
          break;
        }
                
        case "movimiento":{
         
          let otdTemp3 = otdTemp.filter((o)=> {
            let flag = false;
            arrayFiltersTemp.map((opcion)=>{
              if(opcion.value === o.idTipoMovimiento ){
                flag = true;
                return;
              } 
            });
          return flag;
          });

          otdTemp = otdTemp3;
         break;
        }

        case "idTransportista":{
          let otdTemp3 = otdTemp.filter((o)=> {
            let flag = false;
            arrayFiltersTemp.map((opcion)=>{
              if(opcion.value === o.idTransportista ){
                flag = true;
                return;
              } 
            });
          return flag;
          });

          otdTemp = otdTemp3;
         break;
        }
      }
    }

    })
    

    setArrayFilters(arrayFiltersTemp)
    setotdListFiltrados(otdTemp)
  

}

  const filtro=(dataOtdLisTemp)=>{
    let otdTemp= dataOtdLisTemp? [...dataOtdLisTemp]: [...otdList];

    if(arrayFilters && arrayFilters.length > 1){
      handleChangeOptions(null,dataOtdLisTemp);
      return;
    }
    otdTemp.forEach(element => {
      if(element.tipoMovimiento == 1){

        let terminal = new Date(element.salidaTerminalLleno);
        let cliente = new Date(element.arriboClienteLLeno);
        element.transito =  convertMS(cliente - terminal);
     
      }else{
        let cliente = new Date(element.salidaClienteLleno);
        let terminal = new Date(element.arriboTerminalCargado);
        element.transito = convertMS(cliente - terminal);
      }
    });
    
    setotdListFiltrados(otdTemp);
    //return otdTemp;
  }


  const convertMS = (ms) => {
    var d, h, m, s;
    s = Math.floor(ms / 1000);
    m = Math.floor(s / 60);
    s = s % 60;
    h = Math.floor(m / 60);
    m = m % 60;
    d = Math.floor(h / 24);
    h = h % 24;
   // h += d * 24;
    return d + ' dias ' + h + ' horas ' + m + ' minutos';
}

  const random_rgba = (opacidad = 1) => {
    var o = Math.round, r = Math.random, s = 255;
    //return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + r().toFixed(1) + ','+opacidad+ ')';
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + opacidad + ')';
  }
  const getGraficaOtdEstatus = () => {
    let otdTemp= [...otdListFiltrados];
    let labels = [];
    let data = [];
    let backgroundColor = [];
    let hoverBackgroundColor = [];//["dias"][g.dias]
    for( let gEstatus of otdEstatusOpciones ){
      labels.push(gEstatus.label);
      data.push(otdTemp.filter( g => g.status === gEstatus.label).length);
      backgroundColor.push(random_rgba(0.5));
      hoverBackgroundColor.push(random_rgba());
    }
    return { labels, data };
  }
  const getGraficaTipoMovimiento = () => {
    let otdTemp= [...otdListFiltrados];
    let labels = [];
    let data = [];
    let backgroundColor = [];
    let hoverBackgroundColor = [];//["dias"][g.dias]
    for( let p of tipoMovimientoOpciones ){
      labels.push(p.label);
      data.push(otdTemp.filter( g => g.idTipoMovimiento === p.label).length);
      backgroundColor.push(random_rgba(0.5));
      hoverBackgroundColor.push(random_rgba());
    }
    return { labels, data };
  }
  const getGraficaCorredores = () => {
    let otdTemp= [...otdListFiltrados];
    let labels = [];
    let data = [];
    let backgroundColor = [];
    let hoverBackgroundColor = [];//["dias"][g.dias]
    for( let p of corredoresOpciones ){
      labels.push(p.label);
      data.push(otdTemp.filter( g => g.corredor === p.label).length);
      backgroundColor.push(random_rgba(0.5));
      hoverBackgroundColor.push(random_rgba());
    }
    return { labels, data };
  }
  const getGraficaClientes = () => {
    let otdTemp= [...otdListFiltrados];
    let labels = [];
    let data = [];
    let backgroundColor = [];
    let hoverBackgroundColor = [];//["dias"][g.dias]
    for( let p of clientesOpciones ){
      labels.push(p.label);
      data.push(otdTemp.filter( g => g.cliente === p.label).length);
      backgroundColor.push(random_rgba(0.5));
      hoverBackgroundColor.push(random_rgba());
    }
    return { labels, data };
  }
  
  const reset =()=>{
    setOtdEstatusOpcion([]);
    setCorredoresOpcion([]);
    setClientesOpcion([]);
    setTipoMovimientoOpcion([]);
    setArrayFilters([{}])
  }

  return (
    <div className="container-fluit">
      <div className="row justify-content-center" style={{ margin: "16px" }}>
        <h1>Dashboard OTD </h1>
      </div>
      <div className="row row--filters" >
        <div class="filter col-12">
          <div className="filter--form col-6">
            <div className="col-9 justify-content-start">
              <div className="form-group">
                <label for="estatus">Estatus</label>
                <Select name="estatus" isMulti value={otdEstatusOpcion} onChange={(e,a)=>{ setOtdEstatusOpcion(e); handleChangeOptions(a); }} options={otdEstatusOpciones} />
              </div>
            </div>
            <div className="col-9 justify-content-start">
              <div className="form-group">
                <label for="estatus">Corredor</label>
                <Select isMulti name="corredor" value={corredoresOpcion} onChange={(e,a)=>{ setCorredoresOpcion(e); handleChangeOptions(a); }} options={corredoresOpciones} />
              </div>
            </div>
            <div className="col-9 justify-content-start">
              <div className="form-group">
                <label for="estatus">Cliente</label>
                <Select isMulti name="cliente" value={clientesOpcion} onChange={(e,a)=>{ setClientesOpcion(e);  handleChangeOptions(a) }} options={clientesOpciones} />
              </div>
            </div>
            <div className="col-9 justify-content-start">
              <div className="form-group">
                <label for="estatus">Tipo de Movimiento</label>
                <Select  isMulti name="movimiento" value={tipoMovimientoOpcion} onChange={(e,a)=>{ setTipoMovimientoOpcion(e); handleChangeOptions(a); }} options={tipoMovimientoOpciones} />
              </div>
            </div>
            <div className="col-9 justify-content-start">
              <div className="form-group">
                <label for="estatus">Linea Transportista</label>
                <Select  isMulti name="idTransportista" value={lineaTransportistaOpcion} onChange={(e,a)=>{ setLineaTransportistaOpcion(e); handleChangeOptions(a); }} options={lineaTransportistaOpciones} />
              </div>
            </div>
            <div className="col-9 justify-content-start">
              <div className="form-group">
                <label for="reset">Restablecer valores</label>
                <button onClick={()=> reset()}  className="btn-primary btn d-block"><i class="fe fe-refresh-ccw"></i></button>
              </div>
            </div>

          </div>
          <div className="col-6 filter--calendar">
            <div className="row">
              <div className="col-12 d-c">
              <DateRange
                startDatePlaceholder={startDate}
                endDatePlaceholder={endDate}
                dateDisplayFormat={"yyyy-MM-dd"}
                editableDateInputs={true}
                dragSelectionEnabled={true}
                showDateDisplay={true}
                locale={es}
                showSelectionPreview={true}
                onChange={handleDates}
                months={1}
                ranges={dates}
                direction="horizontal"
              />
              </div>
            </div>
          </div>
         </div>
         <div className="col-12">
                <span>
                  <h3 className="text-center">Total {otdListFiltrados.length}</h3>
                </span>
         </div>        
        </div>

      
      { otdListFiltrados.length &&
        <div className="row row--grafs">
          <div className="col-6 otd-heigth">
            <div className="card border-primary mb-3">
              <div className="card-header" style={{ backgroundColor: "rgba(57, 121, 249, 0.30)" }}>
                <h4>
                  Estatus
                </h4>
              </div>
              <div className="card-body text-success">
                { otdList.length ?
                  <Chart
                    series= {getGraficaOtdEstatus().data}
                  options= {{
                    chart: {
                      width: 3000,
                      type: 'donut',
                      animations:{
                        enabled:false
                      },
                    },
                    labels:getGraficaOtdEstatus().labels,
                    plotOptions: {
                      pie: {
                        startAngle: -90,
                        endAngle: 270
                      }
                    },  
                    animations:{
                      enabled:false
                    },            
                    dataLabels: {
                      enabled: true
                    },
                    fill: {
                      type: 'gradient',
                    },
                    legend: {
                      formatter: function(val, opts) {
                        return getGraficaOtdEstatus().labels[opts.seriesIndex]+" - "+ opts.w.globals.seriesTotals[opts.seriesIndex]
                      }
                    },
                    title: {
                      text: 'Estatus actual'
                    }
                  }}
                    type="donut" width={"100%"} height={"auto"}/>
                :null}
              </div>
            </div>
          </div>
          <div className="col-6 otd-heigth">
            <div className="card border-primary ">
              <div className="card-header" style={{ backgroundColor: "rgba(57, 121, 249, 0.30)" }}>
                <h4>
                Clientes
                </h4>
              </div>
              <div className="card-body text-success">
                { otdList.length ?
                <Chart
                  options={{
                    chart: {
                      id: 'apexchart-example',
                      events: {
                        click: function (event, chartContext, config) {
                          //alert(config.labels[config.dataPointIndex]);
                        }
                      },
                      animations:{
                        enabled:false
                      }
                    },
                    xaxis: {
                      categories: getGraficaClientes().labels,
                    },
                    //colors:["#F44336", "#E91E63", "#9C27B0"]               
                  }}
                  series={
                    [
                      { name: "Otd", data: getGraficaClientes().data },
                    ]
                  }
                  type="bar"
                  width={"100%"}
                  height={"auto"}
                />
                :null}
              </div>
            </div>
          </div>
          <div className="col-6 otd-heigth">
            <div className="card border-primary mb-3">
              <div className="card-header" style={{ backgroundColor: "rgba(57, 121, 249, 0.30)" }}>
                <h4>
                Corredores
                </h4>
              </div>
              <div className="card-body text-success">
                { otdList.length ?
                <Chart
                  options={{
                    chart: {

                      id: 'apexchart-example',
                      events: {
                        click: function (event, chartContext, config) {
                          //alert(config.labels[config.dataPointIndex]);
                        }
                      },
                      animations:{
                        enabled:false
                      }
                    },
                    xaxis: {
                      categories: getGraficaCorredores().labels,
                    },
                    //colors:["#F44336", "#E91E63", "#9C27B0"]               
                  }}
                  series={
                    [
                      { name: "Otd", data: getGraficaCorredores().data },
                    ]
                  }
                  type="bar"
                  width={"100%"}
                  height={"auto"}
                />
                :null}
              </div>
            </div>
          </div>
          <div className="col-6 otd-heigth">
            <div className="card border-primary mb-3">
              <div className="card-header" style={{ backgroundColor: "rgba(57, 121, 249, 0.30)" }}>
                <h4>
                  Tipo de Movimientos
                </h4>
              </div>
              <div className="card-body text-success">
                { otdList.length ?
                  <Chart
                    series= {getGraficaTipoMovimiento().data}
                    options= {{
                      chart: {
                        width: 380,
                        type: 'pie',
                        animations:{
                          enabled:false
                        },
                      },
                      labels:getGraficaTipoMovimiento().labels,
                      plotOptions: {
                        pie: {
                          startAngle: -90,
                          endAngle: 270
                        }
                      }
                    }}
                    type="pie" width={"90%"} height={"auto"} />
                :null}
              </div>
            </div>
          </div>
        </div>
      }
      <hr />
      <div className="row" hidden={!otdListFiltrados.length}>
        <div className="col-12 otd--col">
          <TablePageable
            lista={otdListFiltrados}
            columnas={
              [
                { columna: "mes", label: "mes" },
                { columna: "semana", label: "semana" },
                { columna: "corredor", label: "Corredor" },
                { columna: "tipoMovimiento", label: "Tipo de Movimiento" },
                { columna: "booking", label: "Booking" },
                { columna: "po", label: "Wo" },
                { columna: "contenedorSize", label: "ContenedorSize" },
                { columna: "contenedor", label: "Contenedor" },
                { columna: "cliente", label: "Cliente" },
                { columna: "direccion", label: "Direcciòn Cliente" },
                { columna: "idTransportista", label: "Transportista" },
                { columna: "citaProgramada", label: "Cita Programada" },
                { columna: "fechaArribo", label: "Fecha de Arribo" },
                { columna: "salidaCLienteLleno", label: "Salida Cliente Lleno" },
                { columna: "status", label: "Estatus" },
                { columna: "transito",label: "Tiempo en Transito"}

              ]
            }
            id="idMovimiento"
            selected={() => { }}
            titulo={<h3> Listado de Movimientos </h3>}
            csvname="Movimientos"
            pagination={false}
            elementosPorPagina={20}

          />
        </div>
      </div>
      {isloading ? (
				<LoadingScreen
					loading={isloading}
				/>
			):null}
    </div>

    
  );
};

export default ContentOtd;