//Author F.R. Betancourt
import React, { useState, useEffect } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";
import Select from "react-select";
import { element } from "prop-types";

const LocalidadesForm = ({
  stor,
  accion,
  elementos,
  getfuntion,
  cerrarModal,
}) => {

  console.log(elementos)
  
  const [formulario, setFormulario] = useState([]);
  const [crudbutonEnable, setCrudbutonEnable] = useState(true); // Se desabilita cuando se hace click
  const [selectLocalidades, setSelectLocalidades] = useState(true);
  const [localidadesOpcion, setLocalidadesOpcion] = useState();
  const [localidadesOpciones, setLocalidadesOpciones] = useState();
  const [estatus, setEstatus] = useState();
  var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

  useEffect(() => {
    
    console.log(elementos)
    

    if (accion === "nuevo") {
      let localidades = [];

      stor.localidades_object.forEach((localidad,index) => {

        elementos.forEach(element => {
          if(localidad.id == element.idLocalidad.id ){
            localidades.push(index)
          }
        });
      })

      localidades.forEach(element => {
        delete stor.localidades_object[element]
      });

      let localidadesOpcionesTemp = [];
      Object.keys(stor.localidades_object).forEach((key) => localidadesOpcionesTemp.push({ value: stor.localidades_object[key].id, label: stor.localidades_object[key].nombre}));

      setLocalidadesOpciones(localidadesOpcionesTemp)
    } else {
      setFormulario(elementos);
    }
  }, []);


  const handleChangeOptions = (event=null,data=null)=>{

    let arrayFiltersTemp;
    let selectOption;

    console.log(data ? data.length : null)

    if((event&& event.action === "select-option")){
      selectOption = event.name;
      arrayFiltersTemp = [{"name": selectOption,"value": event.option.value}];

      setSelectLocalidades(arrayFiltersTemp);
    }

    
  }

  const isEnable = (Accion) => {
    let borrar = "corredores_borrar";
    let actualizar = "corredores_editar";
    let agregar = "corredores_agregar";
    var privilegios = stor.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (Accion === "borrar") {
        if (privilegios[i].clave == borrar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "actualizar") {
        if (privilegios[i].clave == actualizar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregar") {
        if (privilegios[i].clave == agregar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const enableSave = () => {
    if (localidadesOpcion ? (localidadesOpcion.length > 0  ? true : false) : false) {
      return false;
    } else {
      return true;
    }
  };

  const guardarLocalidades = () => {
    if (accion === "modificar") crud_put();
    else crud_post();
    cerrarModal();
  };

  const crud_delete = () => {
    setCrudbutonEnable(false);
    getfuntion("delete", "", elementos.id);
  };

  const crud_put = () => {
    setCrudbutonEnable(false);
    getfuntion("put", formulario);
  };
  const crud_post = () => {
    localidadesOpcion.map((localidad) =>{
      formulario.push({ idTransportista: 0,idUsuario: currentUser[0].usuario.id , idLocalidad: Number(localidad.value) , estatus: estatus? estatus:false})
    })

    setCrudbutonEnable(false);
    getfuntion("post", formulario);
  };
  return (
    <div>
      {accion === "modificar" ? <h4> {elementos.idLocalidad.nombre} </h4> : null}
      <Grid.Row>
      {accion === "modificar" ?  
        null
        : 
        <Grid.Col md={6}>
          <Select  isMulti name="localidades" value={localidadesOpcion} onChange={(e,a)=>{ setLocalidadesOpcion(e); handleChangeOptions(a); }} options={localidadesOpciones} />
        </Grid.Col>
       }
        <Grid.Col md={3}>
          <Form.Switch
            name="estatus"
            label="Activo"
            checked={formulario.estatus}
            onChange={(e) => {
              setEstatus( e.target.checked );
            }}
          />
        </Grid.Col>
     
      </Grid.Row>

      <div className="float-right">
        {isEnable("actualizar") || isEnable("agregar") ? (
          <span class="badge">
            <Button
              target="_blank"
              size="sm"
              RootComponent="a"
              color="primary"
              disabled={enableSave() || !crudbutonEnable}
              onClick={guardarLocalidades}
            >
              <span class="badge">
                <Icon link={true} name="save" />
                Guardar
              </span>
            </Button>
          </span>
        ) : null}
        {accion === "modificar" && isEnable("borrar") ? (
          <span class="badge">
            <Button
              target="_blank"
              size="sm"
              RootComponent="a"
              color="primary"
              onClick={() => crud_delete()}
              disabled={!crudbutonEnable}
            >
              <span class="badge">
                <Icon link={true} name="trash" />
                Borrar
              </span>
            </Button>
          </span>
        ) : null}
      </div>
    </div>
  );
};
export default LocalidadesForm;
